import React from "react";
import casino from "../../config";

const vip = {
    "en": {
        "sections": [{
            "title": "Vip rewards program",
            "content": ["There are many online casinos out there, but not all of them give you the personal and fair treatment you deserve a club for our most elite and loyal players. Our dedicated VIP team are here to support you and ensure the best gaming experience possible, all tailored to your specific needs. At "+casino.name+" we appreciate our loyal players, and we show it.", "Once your account is upgraded to VIP status, the fun really begins. VIP club members get to enjoy faster withdrawals, you’ll enjoy exclusive promotions tailored to you, and be kept up to date with exciting Tournaments, Raffles, more significant match bonuses, and higher cashback offers.", "We'll help you celebrate those special moments; Birthdays, Anniversaries, Yearly Casino anniversary bonus–Yet another big thank you for your continuing loyalty. Christmas–or simply because you deserve to be treated with Hi-tech gadgets, TVs, laptops, weekend getaways and more! Sent straight and discreetly to your door.", "Being a member of the VIP club ensures you receive the top level of service combined with a personal touch from our friendly VIP account managers. They’ll be responsible for providing you with the highest level of service and the best VIP experience available.", "The hottest club in town is waiting.", "So keep an eye out for your personal invitation to the VIP club.", "Gaming has never been so fun!"]
        }]
    }, "fr": {
        "sections": [{
            "title": "Club vip",
            "content": ["Il existe beaucoup de casinos en ligne, mais pas tous vous donnent le traitement personnel et juste que vous méritez. Sur "+casino.name+", nous sommes fiers de rendre nos joueurs heureux et satisfaits. Nous savons apprécier à leur juste valeur nos joueurs fidèles et nous le démontrons par notre programme Vip de fidélité.", "Une fois membre actif du club VIP, vous profiterez des meilleurs avantages en ligne tels que: traitement des retraits ultra rapides, de plus grands Bonus avec ou sans exigences sur vos dépôts ainsi que des Assurances pertes cashback quotidiennes, hebdomadaires ou mensuelles. Ce ne sont que quelques-uns des avantages offerts à nos joueurs VIP.", "De plus, en tant membre actif du club VIP vous profiterez de véritables cadeaux envoyés directement et discrètement à votre porte, tels que: colis gourmets, champagne ou autre alcool, iPads, AirPods etc...", "Être membre du club VIP vous assure de recevoir le plus haut niveau de suivi personnalisé par un de nos gestionnaires de comptes VIP. Ils répondent à votre style de jeu et à tous vos besoins."]
        }]
    }, "de": {
        "sections": [{
            "title": "Programa de recompensas vip",
            "content": ["Es gibt viele Online Spielbanken da draußen, aber nur die wenigsten bieten Ihnen die persönliche und faire Behandlung, die Sie verdienen. Bei "+casino.name+" sind wir stolz darauf, unsere Spieler zufrieden und glücklich zu machen. Wir wissen unsere treuen Spieler sehr zu schätzen und wissen sie auch dementsprechend zu belohnen.", "Sobald Ihr Spielbank Konto den VIP Status erhalten hat, beginnt das Entertainment erst richtig. Mitglieder unseres VIP Clubs genießen schnellere Auszahlungen, höhere Bonusse und größere Cashbackangebote. Und das sind nur einige der vielen Vorteile, die VIP Spieler bei uns erhalten.", "Zudem erhalten aktive VIP Club Mitglieder echte Geschenke direkt und diskret nach Hause geschickt. Wir sind bekannt dafür, dass wir unsere VIPs mit tollen Geschenken verwöhnen, wie beispielsweise iPads, GoPros, iPhones und vielen weiteren attraktiven Geschenken.", "Als VIP Club Mitglied ist Ihnen ein Service auf höchstem Standard mit persönlicher Betreuung von unseren freundlichen VIP Kundenmanagern garantiert. Diese stellen sich voll und ganz auf Ihre persönlichen Spielvorlieben und Bedürfnisse ein, so können sie Ihnen sogar helfen, Ihre Wettstrategien zu verbessern, um optimalere Spielergebnisse zu erzielen.", "Halten Sie also Ausschau nach einer persönlichen Einladung zum VIP Club. Noch nie hat Spielen mehr Spaß gemacht!"]
        }]
    }, "es": {
        "sections": [{
            "title": "Programa de recompensas vip",
            "content": ["Hay muchos casinos en línea, pero no todos te brindan el trato personal y justo que te mereces, un club para nuestros jugadores más elitistas y leales. Nuestro dedicado equipo VIP está aquí para apoyarlo y garantizar la mejor experiencia de juego posible, todo adaptado a sus necesidades específicas. En "+casino.name+" apreciamos a nuestros jugadores leales, y lo demostramos.", "Cuando su cuenta se actualiza al estado VIP, es cuando la diversión realmente comienza. Los miembros del club VIP pueden disfrutar de retiros más rápidos, promociones exclusivas adaptadas a su gusto; participación en emocionantes torneos, rifas, bonificaciones de depósito más significativas y mayores ofertas de reembolso de efectivo.", "Estaremos aquí para celebrar esos momentos especiales; Cumpleaños, aniversarios, bono anual de aniversario del casino -otro gran agradecimiento por su continua lealtad. Navidad -o simplemente porque mereces ser agasajado con regalos de artículos de alta tecnología, televisores, computadoras portátiles, escapadas de fin de semana y más! Enviado directa y discretamente a domicilio.", "Ser miembro del club VIP garantiza que reciba el nivel más alto de servicio combinado con un toque personal de nuestros amigables gerentes de cuentas VIP. Serán responsables de brindarle el más alto nivel de servicio y la mejor experiencia VIP disponible.", "El mejor club de la ciudad está esperando.", "Esté atento a su invitación personal al club VIP. Jugar nunca ha sido tan divertido."]
        }]
    }, "it": {
        "sections": [{
            "title": "Vip",
            "content": ["Ci sono molti Casinò online là fuori, ma non tutti ti danno il trattamento personale e di livello che meriti in un club per i giocatori più elitari e fedeli. Il nostro team VIP dedicato è qui per supportarti e garantire la migliore esperienza di gioco possibile, tutto su misura per le tue esigenze specifiche. A "+casino.name+" apprezziamo i nostri giocatori fedeli e lo dimostriamo.", "Una volta che il tuo account viene aggiornato allo stato VIP, il divertimento inizia davvero. I membri del club VIP potranno usufruire di prelievi più veloci, godrai di promozioni esclusive su misura per te e ti terremo aggiornato con tornei emozionanti, lotterie, bonus sulle partite più significativi e offerte di cashback più elevate.", "Ti aiuteremo a celebrare quei momenti speciali; Compleanni, anniversari, bonus per l'anniversario del casinò annuale - Ancora un altro grande grazie per la tua fedeltà costante. Natale o semplicemente perché meriti di essere viziato con gadget hi-tech, TV, laptop, weekend e molto altro! Inviato direttamente e con discrezione a casa tua!", "Essere un membro del club VIP ti garantisce di ricevere il massimo livello di servizio combinato con un tocco personale dai nostri amichevoli gestori di account VIP. Saranno responsabili di offrirti il massimo livello di servizio e la migliore esperienza VIP disponibile.", "Il club più alla moda della città ti sta aspettando.", "Quindi tieni d'occhio il tuo invito personale al club VIP. Il gioco non è mai stato così divertente."]
        }]
    }
}
export default vip;