import React from "react";
import { useContext } from "react";
import { langContext } from "../../helper/langcontext";
import InnerHTML from "dangerously-set-html-content";
/* Importing the config file from the config folder. */
import casino from "../../config";
let sortName = casino.rival.sortName

let raw_chat = `<div class="chat_wrapper">

<div>
  <h3>Live Chat</h3>
</div>
  <div id="lcv2-alerts" class="alert-content"><div id="lcv2-alert-container" class=""><p>Please Wait</p>
  <div class="lcv2-alert-option-container">
    <input class="submit-sm" type="submit" id="lcv2-acknowledge" value="Ok"/>
      </div>
      </div>
      </div>

  <div id="chatWindowMessages" class="chat-history">

  </div>
                  
  <div id="chatWindowForm" class="chat-form" onsubmit="return false;">
      <div class="input-container">
         
                  <div class="input-content">
                      <input id="chatWindowInput"/>
                  </div>
                </div>
      <input type="button" name="chatWindowBtnSend" id="chatWindowBtnSend" class="submit" value="" />
  </div>
  </div>
  </div>
  <script type="text/javascript">
  var request = {}
  request.chatCredentialUrl = 'https://casinocontroller.com/${sortName}/engine/Chat/LiveChatService.php';
  cs = new ChatSetup();
  cs.setupChatStub(request,'cw');
  </script>

`;

export default function LiveChat({ callback }) {
  const lang = useContext(langContext);
  // console.log(lang.language);
  return (
    <div id="live_chat" className="live_chat">
      <div className="chat_cancel" onClick={()=>{callback(false)}}></div>
      <InnerHTML html={raw_chat} />
    </div>
  );
}

{
  /*   <script type="text/javascript">
  var request = {}
  request.chatCredentialUrl = 'https://casinocontroller.com/avantgarde/engine/Chat/LiveChatService.php';
  cs = new ChatSetup();
  cs.setupChatStub(request,'cw');
  </script> */
}
