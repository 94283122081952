import React from "react";
import casino from "../../config";

const faq = {
    en: {
        title: "FREQUENTLY ASKED QUESTIONS",
        content: [<>For your convenience, we have assembled some of the most Frequently Asked Questions (FAQs) in one
            place. If you do not find the answer you need, or if you require a more specific information, please do not
            hesitate to contact our <a href="/support"> Support Team. </a></>],
        sections: [{
            title: "Registration", items: [{
                title: "How do i register at the casino?",
                content: ["Registration is fast and easy. Connect to the internet and run the casino software. The first time you run the casino you will have to choose whether to play for fun (for free) or for real money. the registration screen is then displayed. Fill in the required information and click \"Register\". After registration you will be directed to the login screen. The casino remembers your username and optionally remembers your password so you don't have to enter them every time. Click \"Login\" to start playing.", "Registration is fast and easy. Connect to the internet and run the casino software. The first time you run the casino you will have to choose whether to play for fun (for free) or for real money. the registration screen is then displayed. Fill in the required information and click \"Register\". After registration you will be directed to the login screen. The casino remembers your username and optionally remembers your password so you don't have to enter them every time. Click \"Login\" to start playing."]
            }, {
                title: "What is the minimum amount of gaming credits i need to purchase?",
                content: ["Before you can play for real money you must make a deposit. The minimum amount is 25 worth of casino chips."]
            }, {
                title: "Do i have to pay to try out the games?",
                content: ["No! You may play the games in FREE money mode for as long as you like. If you are unfamiliar with any of our games, we encourage you try them out and become familiar with them by playing with \"fun money\" before you decide to with real money."]
            }, {
                title: "Can i purchase more fun money casino chips?",
                content: ["Yes. You may do so by clicking on the \"Cashier\" button in the Lobby or from within any game. Click the \"set balance\" button and enter the balance you would like and click \"set balance\" In fun money mode, you may set your balance to whatever you like."]
            }]
        }, {
            title: "Connection", items: [{
                title: "What is my username and/or password?",
                content: ["Your password is a case-sensitive name to identify you and must be entered the same way each time you log in. If you have forgotten your password or account number, contact a customer service representative for assistance. Remember to have your registration details handy. In order to comply with eCogra's strict policies on account information, we are only able to provide players account details after positively identifying a player over the phone."]
            }, {
                title: "I forgot my password, what should i do?",
                content: ["On the login screen, there is a link titled \"Click here if you have forgotten your password . \" Click there and a new password will be e-mailed to you."]
            }, {
                title: "What if i try logging in and it does not connect?",
                content: ["This may be due to a bad or slow connection with your internet service provider. Disconnect and reconnect to the Internet and try again. If you still have problems connecting, please contact customer support."]
            }, {
                title: "How do i open an account?",
                content: ["Opening an account at  " + casino.name + " is simple. Just follow these 5 easy steps:", <ul>
                    <li>Click on any FREE DOWNLOAD button.</li>
                    <li>Save our free software to your computer.</li>
                    <li>Install the software on your PC. Make sure you're connected to the internet. Run the software
                        and click on CONNECT.
                    </li>
                    <li>You will then be prompted to register online. You can register to play for FREE or with REAL
                        MONEY.
                    </li>
                    <li>Once registration is complete, you will be given a player account number. Make sure you remember
                        your account number and password, as you will need them to access the casino each time you wish
                        to play.
                    </li>
                </ul>]
            }]
        }, {
            title: "Deposits And Withdrawals", items: [{
                title: "How do i purchase credits?",
                content: ["We offer several easy options for deposits at  " + casino.name + ": MasterCard, Cashlib, Bitcoin. For more details please see our cashier page. If you have any queries with regards to banking, please contact us."]
            }, {
                title: "How do i cash-in my winnings?",
                content: ["Please note that it is not necessary for you to cash in your winnings at the end of each session. You can always leave a balance in your account and it will be waiting for you the next time you login. And you don't need to worry about forgetting you have a balance left in your account -- we will send you an e-mail reminder if you leave a balance in your account and haven't played in a while. You however may, of course, withdraw your money at any time.  " + casino.name + " offers several methods for withdrawals and the details are listed on our cashier page."]
            }, {
                title: "Reversal of a withdrawal",
                content: ["If you request a withdrawal and change your mind before it is processed, you may reverse the withdrawal and continue playing with those credits. If you have withdrawals that can be reversed they will show up in the cashier when you go to make a deposit."]
            }]
        }, {
            title: "Play Games", items: [
                {
                title: "What are my chances of winning?",
                content: ["The payouts of our games vary. All games results are determined by Rival's cryptographically secure random number generator which has been independently tested and proven to provide suitably random sequences of numbers. Independent auditors audit payouts from time to time. Depending on the game, the system may use a single deck or multiple decks that are shuffled before every wager."]
            }, {
                title: "Why do the slot wheels just keep spinning and don't stop? why does the roulette ball just keep spinning and doesn't stop?",
                content: ["This is caused by poor internet connection. The results from the server are not reaching your computer so the slot wheels just keep spinning because they have not received the message telling them when and where to stop. If you close the program and run it again you will be refreshed to your last known position."]
            }, {
                title: "Why do the cards not flip over during a hand?",
                content: ["This is caused by a bad or slow internet connection. The gaming server will hold the wager until it can resolve the bet."]
            }, {
                title: "How can i view exactly what cards have been dealt?",
                content: ["You may view your history in the Cashier."]
            }, {
                title: "Exciting competitions and prizes!",
                content: [" " + casino.name + " members enjoy FREE participation in all the competitions run by the casino. The Dealer will be in contact via e-mail to inform players of upcoming competitions and promotions."]
            }]
        }, {
            title: "Security", items: [{
                title: "Is my credit card number safe in your hands?",
                content: ["Yes, your personal Real Account number and password must be supplied prior to any credit card transaction. Specific details are also requested in order to purchase credits at the casinos.  " + casino.name + " guarantees secure data transfer during any transaction and undertakes never to disclose any credit card information or other information to any other company."]
            }, {
                title: "What fraud prevention and security measures are in place?",
                content: ["NEVER give your password out to anyone.  " + casino.name + " will never ask you for your password in an e-mail or over the phone. If anyone ever asks you for your password, please notify customer support immediately.  " + casino.name + " plays an active role in fraud prevention and detection. We do everything possible to ensure the integrity of customer accounts as well as payments made to customers.  " + casino.name + " employs a variety of appropriate security and fraud controls that monitor every transaction. To prevent fraud and abuse, from time to time customers may be required to supply proof of identity, copies of their credit cards, a signed history of their transactions or other documentation for verification and security purposes. If you are asked to verify your identity, you will only be asked once."]
            }, {
                title: "How safe is it to give my credit card details over the internet? are my account number and password safe?",
                content: ["Yes. The casino software uses 128-bit encryption when data is transferred over the Internet. Each player is issued with a unique account number and players choose their own password, which they alone can access. Moreover, we use state-of-the-art encryption technologies which ensure that your personal and card information is protected at all times."]
            }, {
                title: "Can i register a credit card at the casino that is not in my name?",
                content: ["The Casino does not allow players to register credit cards of which they are not signatories. These measures are taken to protect you, the player, and your credit cards and account. If another person would like to play in our casino via your PC, we recommend they open their own Real Account."]
            }, {
                title: "Is my information secure?",
                content: ["Yes! One of  " + casino.name + "'s highest priorities is security. To be able to provide the highest privacy available we use the SSL4 protocol with 128-bit encryption. The system allows the highest security and integrity for all the information that passes to and from our server. These precautions prevent access by unauthorized third parties. Apart from regular reports, which are sent to our customers via email, we strongly recommend all our players to make the full use of the Transfer and Wagering histories available in the cashier."]
            }]
        }, {
            title: "Customer Support", items: [{
                title: "What do i do if i need technical assistance?",
                content: [" " + casino.name + " provides 24/7 support to all casino players. Choose between phone, chat or email. Please contact us regarding any enquiries you may have. Our agents are standing by."]
            }]
        }, {
            title: "Legal Aspect", items: [{
                title: "What is the minimum age required to play?",
                content: ["This depends on where you live. You are required to be of the minimum age to gamble in your jurisdiction."]
            }, {
                title: "Is  " + casino.name + " licensed?",
                content: ["Yes,  " + casino.name + " operates under the licensing jurisdiction of the Government of Curacao."]
            }]
        }, {
            title: "Promotion", items: [{
                title: "What are promotions?",
                content: ["Promotions are a way for  " + casino.name + " to create value for its players. They are offered as incentives to deposit and play, as rewards for loyal players and even as gifts on special occasions. Promotions are typically offered as bonus money but can also be in the form of bonus points."]
            }, {
                title: "What are wagering requirements?",
                content: ["Promotions typically have wagering requirements associated with them. That means that there is a minimum amount of playing time required after claiming a promotion before you can withdraw your winnings. The amount you must play to meet the wagering requirements is a lot less than the average player would ordinarily play. Wagering requirements are typically completed in a couple hours of play (often much less) and the average player plays longer than that."]
            }, {
                title: "Can i tell my friends?",
                content: ["Absolutely! If you enjoyed your playing experience at  " + casino.name + " we encourage and thank you for passing on the word on to your friends. In fact, if you refer a friend using the Refer a Friend (clickable) we will add a $25 bonus to your account after your friend has deposited at least $25 and makes a few wagers. Your Refer A Friend bonus will appear in the list of promotions in the cashier. You will have to click the \"Claim\" button for the bonus money to be added to your account."]
            }]
        }]
    },
    fr: {
        title: "FAQ - FOIRE AUX QUESTIONS",
        content: ["Afin de vous aider, nous avons rassemblé certaines des questions qui sont le plus fréquemment posées par les consommateurs. Si vous veniez à ne pas trouver la réponse à votre question ici, ou si vous avez besoin d’une information plus spécifique, n’hésitez pas à contacter notre <a href=\"/support\"> support. </a>"],
        sections: [{
            title: "Inscription Sur Le Casino  " + casino.name + "", items: [{
                title: "Comment s'inscrire sur le casino  " + casino.name + " ?",
                content: ["L'inscription sur le casino se fait de manière très rapide, en l'espace de seulement quelques minutes. Il faut, dans un premier temps, vous connecter à Internet et démarrer le logiciel du casino. Puisqu'il s'agit de votre première fois sur  " + casino.name + ", vous devrez choisir si vous voulez jouer en mode démo, c'est à dire gratuitement, ou en mode réel, c'est à dire avec votre propre argent. Une fois cette étape franchie, l'écran d'inscription apparaît. Vous devez, dans un deuxième temps, seulement remplir les informations requises et ensuite cliquer sur M'inscrire. Ensuite, apparaîtra l'écran de connexion. Vous pouvez faire le choix d'enregistrer automatiquement votre nom d'utilisateur et votre mot de passe, ce qui vous évite de vous reconnecter systématiquement. Maintenant, vous pouvez commencer à jouer et à gagner!"]
            }, {
                title: "A partir de combien puis-je commencer à jouer en réel ?",
                content: ["Pour commencer à jouer et miser en réel, vous devez acheter pour un minimum de 25 euros en jetons de casino  " + casino.name + "."]
            }, {
                title: "Dois-je payer pour avoir un aperçu des jeux ?",
                content: ["En aucune façon. Vous pouvez commencer à jouer sur le casino via le mode GRATUIT illimité ce qui vous permet notamment de tester les différents jeux de notre plateforme gratuitement."]
            }, {
                title: "Est-il possible d'acheter plus de jetons de casino en mode GRATUIT ?",
                content: ["Absolument. En mode GRATUIT, vous pouvez définir le solde que vous voulez. Il suffit d'aller sur le bouton Caissier dans le Menu et de cliquer sur Définir le solde."]
            }]
        }, {
            title: "Connection Sur Le Casino  " + casino.name + "", items: [{
                title: "Quel est mon nom d'utilisateur et/ou mon mot de passe sur  " + casino.name + " ?",
                content: ["Vous devez à chaque connexion entrer le mot de passe de la même façon. En cas d'oubli de votre mot de passe ou de votre nom d'utilisateur, vous pouvez recourir à notre service clientèle afin d'avoir de l'aide. Munissez-vous alors de vos informations d'inscription à des fins de vérification d'identité. Nous suivons en effet la procédure très stricte établie par eCOGRA sur les informations de compte."]
            }, {
                title: "Comment récupérer mon mot de passe oublié ?",
                content: ["Sur l'écran de connexion, sachez qu'il y a un lien appelé \"Cliquez ici\" si vous avez oublié votre mot de passe. Si vous cliquez sur ce lien, un nouveau mot de passe vous sera envoyé par courriel."]
            }, {
                title: "Comment faire si j'essaie de me connecter et que ça ne fonctionne pas ?",
                content: ["Il se peut que vos problèmes de connexion soient directement liés à votre fournisseur d'accès Internet. Réessayez plusieurs fois et si vous rencontrez toujours des difficultés, n'hésitez pas à contacter notre service clientèle pour de l'aide, soit par chat en direct, téléphone ou email."]
            }]
        }, {
            title: "Dépôts Et Retraits", items: [{
                title: "Comment puis-je acheter des crédits ?",
                content: ["Sur  " + casino.name + ", vous avez à peu près une dizaine de moyens pour pouvoir acheter facilement, rapidement et en toute sécurité des crédits. Parmi ces moyens, MasterCard, Cashlib, Bitcoin. Pour avoir plus d'informations, nous vous invitons à visiter notre page intitulée CAISSE. Vous pouvez également contacter à tout moment notre service clientèle pour avoir de plus amples renseignements à ce sujet."]
            }, {
                title: "Comment puis-je retirer mes gains ?",
                content: ["Vous pouvez retirer vos gains à tout moment. Sachez qu' " + casino.name + " vous enverra un email de rappel si vous n'avez pas joué depuis un momenet et que vous avez toujours un solde. Ainsi, vous pourrez retirer votre argent ou jouer, selon votre bon vouloir.  " + casino.name + " Casino propose plusieurs méthodes de retrait. Pour y avoir accès, veuillez vous diriger sur la page CAISSE du site."]
            }, {
                title: "Comment puis-je annuler un retrait ?",
                content: ["Très simple. Il suffit de vous reporter à votre compte joueur si vous demander l'annulation d'un retrait avant qu'il ne soit effectué."]
            }]
        }, {
            title: "Commencer À Jouer", items: [{
                title: "Quelles sont mes chances de gagner ?",
                content: ["Sachez que le casino en ligne  " + casino.name + " est fier de ne proposer que des jeux qui respectent l'équité et les critères de sérieux minimums. Tous les résultats des jeux sont déterminés crypto-graphiquement par un générateur sécurisé de numéros au hasard qui a été testé indépendamment. Des auditeurs indépendants analysent nos rapports régulièrement. Vous pouvez consulter à tout moment notre service clientèle pour connaître nos taux de redistribution."]
            }, {
                title: "Pourquoi les roues de la machine à sous ou la bille de la roulette tournent sans jamais s'arrêter ?",
                content: ["Votre connexion Internet est certainement responsable de cet état de fait. Les résultats du serveur n'atteignent pas votre ordinateur alors que les roues/la bille continuent de tourner car le message d'arrêt n'a pas été reçu. Si vous décidez de fermer le programme et de recommencer, vous reviendrez à la dernière position connue."]
            }, {
                title: "Pourquoi ne puis-je pas voir les cartes durant une main ?",
                content: ["Votre connexion Internet est certainement responsable de cet inconvénient. Le serveur de jeu maintiendra la distribution tant que le pari ne sera pas finalisé."]
            }, {
                title: "Comment savoir quelles cartes ont été distribuées ?",
                content: ["Votre historique de distribution est disponible dans la partie CAISSE. Un historique remontant jusqu'à votre premier jour d'inscription est alors consultable."]
            }, {
                title: "Comment participer aux compétitions organisées par le casino ?",
                content: ["Sur  " + casino.name + " Casino, les compétitions sont ouvertes gratuitement à tous nos joueurs. Il n'est pas nécessaire, sauf conditions contraires précisées, d'avoir à son actif un certain nombre de mises. Le casino organise très régulièrement des compétitions qui rencontrent un fort succès et au cours desquels des jackpots très intéressants sont mis en jeu."]
            }]
        }, {
            title: "Sécurité", items: [{
                title: "Est-ce que mes transactions avec le casino sont sécurisées ?",
                content: ["Oui. La direction du casino prend très au sérieux la sécurité des transactions et des données de ses clients. Nous utilisons un cryptage 256-bit SSL, notamment utilisé par les banques et institutions financières, pour garantir une complète sécurité des transactions. Avec nous, votre argent est entre de bonnes mains. Grâce au protocle que nous avons mis en place et aux strictes règles de sécurité qui s'appliquent à nos employés, vous ne courrez pas plus de risque que de payer avec votre carte bancaire dans un restaurant ou un magasin. Afin de vous permettre de récupérer vos gains, nous avons besoin des détails de votre carte bancaire. Si vous ne souhaitez pas les communiquer par écrit, via email, vous pouvez à tout moment contacter notre service clientèle par téléphone. Vous pourrez au passage vous renseigner plus en détail sur nos procédures de sécurité."]
            }, {
                title: "Quelles mesures le casino a-t-il mis en place pour éviter la fraude ?",
                content: ["Votre vigilance est d'abord très importante. En aucun cas, ne communiquez à quelqu'un votre mot de passe ou votre numéro de compte. La direction du casino ne vous demandera strictement jamais votre mot de passe qui reste personnel et confidentiel. Si quelqu'un vous le demande, merci de le signaler à notre service clientèle.  " + casino.name + " Casino prend très au sérieux les fraudes éventuelles et a mis en place de nombreuses procédures pour les éviter afin d'assurer l'intégrité des comptes clients et des transactions. Afin d'éviter des fraudes ou des abus, il arrive parfois que la direction du casino vous demande de fournir une preuve de votre identité, via par exemple la photocopie de votre permis de conduire etc...S'il vous est demandé de prouver votre identité, cette procédure n'aura lieu qu'une fois. Elle intervient souvent lors de l'ouverture de votre compte ou au moment de retirer vos gains afin que le casino s'assure bien qu'il n'y a pas tentative de fraude par quelqu'un se faisant passer pour un de nos clients. Notre mot d'ordre est la sécurité de nos clients."]
            }, {
                title: "Y a-t-il des mesures de sécurité concernant mon numéro de compte et mon mot de passe ?",
                content: ["Oui. Nous avons mis en place un cryptage 128-bit lorsque les données sont transférées sur internet. Sachez également que les joueurs du casino choisissent leur propre mot de passe qu'ils sont les seuls à posséder."]
            }, {
                title: "Puis-je m'inscire sur le casino avec une carte bancaire qui n'est pas à mon nom ?",
                content: ["En aucune façon. Si vous n'êtes pas titulaire de la carte bancaire, vous ne pouvez pas vous inscrire avec celle-ci sur le casino. Cette mesure vise tout simplement à protéger nos clients contre des vols éventuels. Si une autre personne veut jouer sur le casino à partir de votre ordinateur, celle-ci doit ouvrir son propre compte REEL avec sa propre carte bancaire."]
            }, {
                title: "Qu'a mis en oeuvre  " + casino.name + " pour assurer la sécurité des données ?",
                content: ["La sécurité des données des clients est l'une des priorités majeures du casino. Afin d'assurer une sécurité et une confidentialité optimales, le casino a recours au protocole SSL4 avec un cryptage 128-bit. Ce système est notamment utilisé par les institutions bancaires. Il assure une sécurité totale à nos membres et évitent notamment que des tierces personne entrent en possession frauduleusement des données que nous avons collectées."]
            }]
        }, {
            title: "Service Clientèle", items: [{
                title: "J'ai besoin d'une aide technique. Que faire ?",
                content: ["Le casino  " + casino.name + " vous offre un soutien technique gratuit par email 24h/24, 7j/7 en cas de problème quelconque ou de question. Notre service clientèle vous attend ! Envoyez nous un courriel à partir de notre page Support."]
            }]
        }, {
            title: "Aspects Légaux", items: [{
                title: "Quel est l'âge légal minimal requis pour jouer sur  " + casino.name + " ?",
                content: ["Pour jouer, il faut que vous ayez l'âge mininal légal dans votre pays de résidence. En France, il faut avoir plus de 18 ans."]
            }, {
                title: " " + casino.name + " est-il un casino régulé ?",
                content: ["Oui. Le casino  " + casino.name + " possède une licence délivrée par le gouvernement des Curacao"]
            }]
        }, {
            title: "Promotions Et Bonus", items: [{
                title: "Que pouvez-vous me dire au sujet des promotions ?",
                content: ["Sur  " + casino.name + ", les promotions sont très régulières. Il peut s'agir, comme c'est souvent le cas, de bonus en argent mais également, parfois de point de compétition bonus. C'est un moyen pour nous de récompenser aussi nos joueurs les plus fidèles en leur fournissant à l'occasion d'évènements particuliers des cadeaux. Les promotions et bonus font partie intégrante de notre politique commerciale et constituent l'un des atouts majeurs du casino."]
            }, {
                title: "Quelles sont les conditions qui s'appliquent pour les bonus et promotions ?",
                content: ["Comme dans tous les casinos en ligne, sur  " + casino.name + ", une fois que vous avez réclamé une promotion, il existe un requis de jeu minimum nécessaire pour pouvoir retirer vos gains éventuels. Ce requis de jeu diffère d'une promotion à l'autre c'est pourquoi il est bien conseillé de lire attentivement les conditions qui s'appliquent à chaque promotion. De manière générale, sachez toutefois que le requis de jeu est atteint rapidement, en l'espace en moyenne de deux heures de jeu."]
            }, {
                title: "Puis-je faire connaître les promotions du casino à mes ami(e)s ?",
                content: ["Evidemment ! Si vous appréciez votre expérience de jeu avec  " + casino.name + ", nous vous encourageons, bien-sûr, à faire connaître le casino à vos ami(e)s. Vous pouvez le faire simplement en utilisant le lien \"Parrainage\" dans la Caisse de votre Casino. Afin de réclamer votre bonus de parrainage, allez dans la liste des promotions disponibles dans la Caisse de votre casino."]
            }]
        }]
    },
    de: {
        title: "HÄUFIG GESTELLTE FRAGEN",
        content: ["Um Ihren Aufenthalt in der Online-Spielbank so angenehm wie möglich zu gestalten, haben wir nachfolgend eine Reihe häufiger Fragen und Antworten zusammengestellt. Sollte Ihr Anliegen auf diesem Wege nicht beantwortet werden können, so zögern Sie bitte nicht, sich an unseren <a href=\"/support\"> Kundensupport zu wenden. </a>"],
        sections: [{
            title: "Registrierung", items: [{
                title: "Wie registriere ich mich in der Spielbank?",
                content: ["Die Registrierung verläuft schnell und einfach. Verbinde Dich mit dem Internet und starte die Spielbanksoftware. Beim ersten Starten der Spielbank haben Sie die Wahl zwischen dem Spiel aus Spaß oder für echtes Geld. Danach wird der Registrierungsbildschirm angezeigt. Füllen Sie die Informationsfelder aus und klicken Sie anschließend auf „Registrieren“. Danach werden Sie auf den Anmeldebildschirm weitergeleitet. Die Spielbanksoftware speichert Ihren Nutzernamen und optional auch Ihr Passwort, sodass Sie diese nicht jedes Mal neu eingeben müssen. Klicken Sie auf „Einloggen“ um loszuspielen."]
            }, {
                title: "Wie viele Spielcredits muss ich mindestens erwerben?",
                content: ["Um mit Echtgeld spielen zu können, müssen Sie zuerst eine Einzahlung vornehmen. Der Mindestbetrag hierfür liegt bei $25."]
            }, {
                title: "Muss ich bezahlen, um die Spiele zu testen?",
                content: ["Nein! Sie können im kostenlosen Modus so lange Spaß haben wie Sie wollen. Wenn Ihnen eines unserer Spiele unbekannt ist, so empfehlen wir, dieses erst einmal im „Spielgeldmodus“ zu versuchen und sich daran zu gewöhnen, bevor Sie um echtes Geld spielen."]
            }, {
                title: "Kann ich im „Spielgeldmodus“ mehr Chips bekommen?",
                content: ["Ja. Klicke dafür auf die Schaltfläche „Kasse“ in der Lobby oder in den Spielen. Klicke danach im Spielgeldmodus auf „Kontostand festlegen“. Hier kannst Du dann Deinen Kontostand selbst definieren."]
            }]
        }, {
            title: "Verbindung", items: [{
                title: "Wie lautet mein Nutzername und/oder Passwort?",
                content: ["Ihr Passwort ist ein Wort, in welchem Groß- und Kleinschreibung unterschieden wird, mit dem Sie sich identifizieren. Dieses muss bei jedem Einloggen gleich sein. Sollten Sie Ihr Passwort oder Ihre Kontonummer vergessen haben, so kontaktieren Sie bitte den Kundenservice. Denken Sie daran, Ihre Anmeldeinformationen zur Hand zu haben. Um den strengen eCogra-Vorgaben für Kontoinformationen gerecht zu werden, können wir Kontodetails des Spielers erst dann herausgeben, wenn wir diese telefonisch identifiziert haben."]
            }, {
                title: "Ich habe mein Passwort vergessen. Was kann ich tun?",
                content: ["Auf dem Login-Bildschirm gibt es einen Link: „Klicke hier, wenn Du Dein Passwort vergessen hast“. Klicke darauf und ein neues Passwort wird Dir per E-Mail zugesandt."]
            }, {
                title: "Was tue ich, wenn ich versuche mich einzuloggen und keine Verbindung hergestellt werden kann?",
                content: ["Dies kann aus einer schlechten oder langsamen Verbindung über Deinen Internetanbieter resultieren. Trenne die Verbindung zum Internet, verbinde Dich dann erneut und versuche es noch einmal. Solltest Du weiterhin Probleme bei der Verbindung haben, so kontaktiere bitte den Kundendienst."]
            }]
        }, {
            title: "Ein- Und Auszahlungen", items: [{
                title: "Wie erhalte ich Spielcredits?",
                content: ["Wir bieten verschiedene Möglichkeiten für Einzahlung in der  " + casino.name + " Spielbank an. Für weitere Informationen besuche bitte unseren Kassenbereich. Sollten Sie irgendwelche Fragen bezüglich Zahlungen haben, kontaktieren Sie uns bitte."]
            }, {
                title: "Wie kann ich meine Gewinne auszahlen lassen?",
                content: ["Sie sollten wissen, dass es nicht notwendig ist, nach jeder Session Ihre Gewinne auszahlen zu lassen. Sie können Gewinne immer auf Ihrem Spielerkonto belassen, ohne sie zu verlieren, wenn Sie sich ausloggen. Mache Sie sich bitte keine Sorgen, dass Sie vergessen könnten, wie viel Geld Sie noch auf dem Konto haben. Sollten Sie längere Zeit nicht gespielt und noch Geld auf Ihrem Konto haben, so werden wir Sie per E-Mail darüber informieren. Dennoch steht es Ihnen natürlich jederzeit frei, Ihr Geld auszahlen zu lassen. Die  " + casino.name + " Spielbank bietet mehrere Möglichkeiten für Auszahlungen. Weitere Informationen dazu finden Sie im Kassenbereich."]
            }, {
                title: "Stornierung einer Auszahlung",
                content: ["Sollten Sie eine Auszahlung beantragt haben, diese wurde aber noch nicht getätigt, so steht es Ihnen frei, die Auszahlung zu stornieren und mit Ihren Spielcredits weiterzuspielen. Sollten Sie Auszahlungen beantragt haben, die noch storniert werden können, so werden diese im Kassenbereich angezeigt, falls Sie eine Einzahlung tätigen möchtest."]
            }]
        }, {
            title: "Spiele Spielen", items: [{
                title: "Wie hoch sind meine Gewinnchancen?",
                content: ["Die Ausschüttung unserer Spiele variiert. Alle Spielergebnisse werden über Rivals sicheren Zufallsgenerator, der von unabhängigen Stellen getestet wurden und beliebige Zahlenreihen ausgibt. Unabhängige Revisoren überprüfen die Auszahlungen von Zeit zu Zeit. Je nach Spielen werden ein oder mehrere Decks vor jeder Wette gemischt."]
            }, {
                title: "Warum drehen sich die Walzen der Spielautomaten weiter ohne anzuhalten? Wieso hört das Rad/die Kugel nicht auf sich zu bewegen?",
                content: ["Dies kann bei einer schlechten Internetverbindung vorkommen. Die Ergebnisse von unserem Server können in diesem Fall Ihren PC nicht erreichen, also drehen sich die Walzen bzw. das Rad weiter, da sie nicht das Signal zum Anhalten empfangen. Wenn Sie das Programm schließen und neu starten, dann werden Sie an die letzte bekannte Stelle gebracht."]
            }, {
                title: "Warum werden die Karten nicht umgedreht?",
                content: ["Dies geschieht durch eine schlechte oder langsame Internetverbindung. Der Spielserver wird den Wetteinsatz speichern, bis die Wette aufgelöst werden kann."]
            }, {
                title: "Wie kann ich mir anzeigen lassen, welche Karten ausgegeben wurden?",
                content: ["Du kannst eine Historie beim Kassierer einsehen."]
            }, {
                title: "Aufregende Wettbewerbe und Preise!",
                content: ["Die Mitglieder der  " + casino.name + " Spielbank können KOSTENLOS an Wettbewerben teilnehmen, die durch die Spielbank gestartet werden. Die Spieler werden per E-Mail über anstehende Wettbewerbe und Aktionen informiert."]
            }]
        }, {
            title: "Sicherheit", items: [{
                title: "Ist meine Kreditkartennummer bei Euch sicher?",
                content: ["Ja, Ihre Spielkontonummer sowie Ihr Passwort müssen bei jeder Transaktion mit Ihren Kreditkarten angegeben werden. Des weiteren werden bestimmte Informationen abgefragt, um Spielcredits erwerben zu können. Die  " + casino.name + " Spielbank garantiert für die sichere Datenübertragung während des Geldtransfers und gibt keine Kreditkarteninformationen an Dritte weiter."]
            }, {
                title: "Welche Maßnahmen werden ergriffen, um die Sicherheit zu gewährleisten und Täuschungen zu verhindern?",
                content: ["Geben Sie NIEMALS Ihr Passwort an jemand anderen weiter. Die  " + casino.name + " Spielbank wird Sie niemals per Mail oder Telefon dazu auffordern Ihr Passwort zu nennen. Sollten Sie nach Ihrem Passwort gefragt werden, so informieren Sie bitte umgehend den Kundenservice.  " + casino.name + " ist stets darum bemüht, Betrug vorzubeugen und Betrüger aufzuspüren. Wir tun alles Mögliche, um die Sicherheit der Kundenkonten sowie der geleisteten Zahlungen zu garantieren.  " + casino.name + " setzt verschiedene Werkzeuge ein, um jede Transaktion zu überwachen und somit Sicherheit zu gewähren. Um dem Betrug vorzubeugen werden Kunden hin und wieder darum gebeten ihre Identität nachzuweisen. Dies kann durch eine Kopie der Kreditkarte, eine unterschriebene Auflistung bisheriger Geldtransfers oder anderer Dokumente zur Verifizierung vorgenommen und dient Sicherheitsgründen. Sollten Sie sich identifizieren müssen, so werden Sie nur einmal danach gefragt."]
            }, {
                title: "Wie sicher ist die Weitergabe von Kreditkartendetails über das Internet? Sind meine Kontonummer und mein Passwort sicher?",
                content: ["Ja. Die Spielbank-Software nutzt eine 128-bit-Verschlüsselung bei der Datenübertragung über das Internet. Jeder Spieler bekommt eine eigene, einzigartige Kontonummer zugeteilt und kann sein eigenes Passwort festlegen. Darüber hinaus verwenden wir die aktuellste Verschlüsselungstechnologie, welche sicherstellt, dass Ihre Kreditkartendaten sowie Ihre persönlichen Informationen stets geschützt sind."]
            }, {
                title: "Kann ich eine Kreditkarte in der Spielbank registrieren, die nicht auf meinen Namen läuft?",
                content: ["Die Spielbank erlaubt den Einsatz von Kreditkarten unter fremdem Namen nicht. Diese Maßnahmen werden ergriffen um Sie, den Spieler, Ihre Kreditkartendaten sowie Ihr Konto zu schützen. Sollte jemand anders über Ihren PC in unserer Spielbank spielen wollen, so empfehlen wir, dass diese Person sich ein eigenes Echtgeld-Konto anlegt."]
            }, {
                title: "Sind meine Angaben sicher?",
                content: ["Ja! Eine der höchsten Prioritäten bei  " + casino.name + " ist die Sicherheit. Wir nutzen das SSL4-Protokoll mit 128-bit-Verschlüsselung, um den bestmöglichen Schutz zu gewährleisten. Dieses System schützt alle Informationen, die von unserem Server ausgehen oder an diesen gesendet werden. Diese Vorkehrungen schützen vor dem unbefugten Zugriff Dritter. Abgesehen von den regelmäßigen Berichten, welche wir den Spielern zusenden, empfehlen wir die regelmäßige Überprüfung der Transfer-und Wetthistorie über den Kassenbereich."]
            }]
        }, {
            title: "Kundendienst", items: [{
                title: "Was kann ich tun, wenn ich technische Hilfe benötige?",
                content: [" " + casino.name + " bietet allen Mitgliedern einen 24-Stunden-Service. Sie haben dabei die Wahl zwischen Telefon, Chat und E-Mail. Bitte kontaktieren Sie uns bei jeglichen Fragen, die aufkommen könnten. Unsere Angestellten sind gerne bereit zu helfen."]
            }]
        }, {
            title: "Rechtslage", items: [{
                title: "Was ist das Mindestalter, um in der Spielbank spielen zu dürfen?",
                content: ["Das kommt darauf an, wo Sie leben. Sie müssen das Mindestalter in Ihrem Land erreicht haben, ab dem Glücksspiele gespielt werden dürfen."]
            }, {
                title: "Ist die  " + casino.name + " Spielbank lizensiert?",
                content: ["Ja, die  " + casino.name + " Spielbank ist offiziell zugelassen und hat seinen Gerichtsstand im Vereinigten Curacao."]
            }]
        }, {
            title: "Aktionen", items: [{
                title: "Was sind Aktionen?",
                content: ["Aktionen sind eine Art, auf welche die  " + casino.name + " Spielbank versucht seinen Spielern einen Mehrwert zu bieten. Sie werden bei Einzahlungen oder während des Spiels, als Belohnung für wiederkehrende Spieler und zu bestimmten Anlässen angeboten. Aktionen werden normalerweise als Bonusgelder ausgegeben, können aber z. B. auch Bonuspunkte sein."]
            }, {
                title: "Was sind Wettvoraussetzungen",
                content: ["Aktionen setzten normalerweise bestimmte Wettbedingungen voraus. Das bedeutet, dass Du eine bestimmte Zeit lang spielen musst, bevor Du Dir Gewinne auszahlen lassen kannst. Die benötigte Zeit, die als Wettvoraussetzung festgelegt ist, ist deutlich kürzer, als ein normaler Spieler spielen würde. Die Wettvoraussetzungen werden meist in wenigen Stunden erreicht (oft ist der Zeitraum sogar sehr viel kürzer) und normale Spieler spielen deutlich länger."]
            }, {
                title: "Darf ich Euch meinen Freunden empfehlen?",
                content: ["Selbstverständlich! Solltest Sie Spaß an der Erfahrung mit der  " + casino.name + " Spielbank haben, so sind wir Ihnen sogar sehr dankbar, wenn Sie uns Ihren Freunden empfehlen. Wenn Sie dafür den „Freunde werben“-Link (anklickbar) nutzen, werden wir Ihrem Konto $25 gutschreiben, sobald dieser mindestens $25 eingezahlt und ein paar Wetten getätigt hat. Dieser Bonus wird im Kassenbereich angezeigt. Sie werden auf die Schaltfläche „In Anspruch nehmen“ klicken müssen, damit das zusätzlich Geld Ihrem Konto gutgeschrieben wird."]
            }]
        }]
    },
    es: {
        title: "PREGUNTAS MÁS FRECUENTES",
        content: ["Para su comodidad, hemos recopilado algunas de las preguntas más frecuentes (pf) en un solo lugar. Si no encuentra la respuesta que usted necesita, o si necesita una información más específica, por favor, no dude en ponerse en contacto con nuestro equipo de soporte al cliente."],
        sections: [{
            title: "Registro", items: [{
                title: "¿Cómo me registro en el Casino?",
                content: ["El registro es rápido y fácil. Conéctese a Internet y ejecute el software del casino. La primera vez que ejecute el casino, tendrá que elegir si desea jugar por diversión (gratis) o por dinero real. la pantalla de registro se muestra a continuación. Llene la información requerida y haga clic en \"Registro\". Tras el registro se le dirigirá a la pantalla de inicio de sesión. El casino recuerda su nombre de usuario y, opcionalmente, recuerda su contraseña para que no tenga que escribirla cada vez. Haga clic en \"Inicio de sesión\" para comenzar a jugar."]
            }, {
                title: "¿Cuál es la cantidad mínima de créditos de juego que necesita comprar?",
                content: ["Antes de poder jugar con dinero real, usted debe hacer un depósito. El importe mínimo es de $ 25 dólares en fichas de casino."]
            }, {
                title: "¿Tengo que pagar para probar los juegos?",
                content: ["¡No! Usted puede jugar a los juegos en modo de dinero gratuito para todo el tiempo que usted quiera. Si no está familiarizado con alguno de nuestros juegos, te animamos a probar y familiarizarse con ellos, jugando con \"dinero virtual\" antes de decidir con dinero real."]
            }, {
                title: "¿Puedo comprar más divertidas fichas de casino?",
                content: ["Sí. Puede hacerlo haciendo clic en el \"Cajero\" en el lobby o desde dentro de cualquier juego. Haga clic en el \"balanceo del sistema\" e introduzca el balanceo que desea y haga clic en \"el balanceo del sistema\" En el modo de dinero virtual, usted puede configurar el balance de lo que quiera."]
            }]
        }, {
            title: "Conexión", items: [{
                title: "¿Cuál es mi nombre de usuario y / o contraseña?",
                content: ["Su contraseña es un nombre entre mayúsculas y minúsculas para identificarlo y se debe introducir la misma manera cada vez que inicie la sesión Si has olvidado tu contraseña o número de cuenta, póngase en contacto con un representante de servicio al cliente para obtener ayuda. Recuerde que debe tener sus detalles de registro a mano. Con el fin de cumplir con las políticas estrictas eCogra sobre información de la cuenta, que sólo son capaces de dar cuenta de los jugadores después de los detalles de manera positiva la identificación de un jugador a través del teléfono."]
            }, {
                title: "He olvidado mi contraseña, ¿qué debo hacer?",
                content: ["En la pantalla de inicio de sesión, hay un enlace titulado \"Haga clic aquí si ha olvidado su contraseña.\" Haga clic allí y una nueva contraseña se le enviara por e-mail."]
            }, {
                title: "¿Qué pasa si intento entrar y no se conecta?",
                content: ["Esto puede ser debido a una mala conexión lenta o con su proveedor de servicios de Internet. Desconecte y vuelva a conectar Internet y vuelva a intentarlo. Si todavía tiene problemas para conectarse, por favor póngase en contacto con atención al cliente."]
            }]
        }, {
            title: "Depósitos Y Retiros", items: [{
                title: "¿Cómo puedo comprar créditos?",
                content: ["Ofrecemos varias opciones fáciles para los depósitos en  " + casino.name + ", MasterCard, Cashlib, Bitcoin. Para más detalles, vea nuestra página de cajero. Si usted tiene alguna pregunta con respecto a la banca, por favor póngase en contacto con nosotros."]
            }, {
                title: "¿Cómo puedo cobrar mis ganancias?",
                content: ["Tenga en cuenta que no es necesario para que usted pueda sacar provecho de sus ganancias al final de cada sesión. Siempre se puede dejar un saldo en su cuenta y se espera que la próxima vez que inicie sesión. Y usted no tiene que preocuparse de olvidarse de que tiene un saldo restante en su cuenta - le enviaremos un recordatorio por correo electrónico si usted deja un saldo en su cuenta y no ha jugado en mucho tiempo. Sin embargo, puede, por supuesto, retirar su dinero en cualquier momento. Casino  " + casino.name + " ofrece varios métodos para la retirada y los detalles aparecen en nuestra página de cajero."]
            }, {
                title: "La inversión de una retirada",
                content: ["Si usted solicita un retiro y cambia de opinión antes de ser procesada, usted podrá revocar la retirada y seguir jugando con esos créditos. Si usted tiene retiros que se pueden invertir que se mostrará en la caja cuando se va a hacer un depósito."]
            }]
        }, {
            title: "Juegos", items: [{
                title: "¿Cuáles son mis posibilidades de ganar?",
                content: ["Los pagos varían en nuestros juegos. Todos los resultados de las partidas se determinan por el generador del rival número aleatorio criptográficamente seguro que ha sido probado independientemente y probado para proporcionar secuencias aleatorias de números de forma adecuada. Los auditores hacen una auditoría tiempo a tiempo. Dependiendo del juego, el sistema puede utilizar una sola cubierta o varias cubiertas que se barajan antes de cada apuesta."]
            }, {
                title: "¿Por qué en slot wheels sólo sigue girando y no se detiene? ¿Por qué la bola de la ruleta sólo sigue girando y no se detiene?",
                content: ["Esto es causado por la mala conexión de Internet. Los resultados desde el servidor no están llegando a su computadora para que el slot wheels siga girando porque no han recibido el mensaje de decirles cuándo y dónde parar. Si cierra el programa y ejecutarlo de nuevo que se actualizará a su última posición conocida."]
            }, {
                title: "¿Por qué las cartas no dan la vuelta durante una mano?",
                content: ["Esto es causado por una mala conexión de internet o lenta. El servidor de juego mantendrá la apuesta hasta que pueda resolver su apuesta."]
            }, {
                title: "¿Cómo puedo ver exactamente que cartas se han tratado?",
                content: ["Usted puede ver su historial en el Cajero."]
            }, {
                title: "¡Competiciones emocionantes y premios!",
                content: ["Miembros del casino  " + casino.name + " pueden disfrutar de la libre participación en todas las competiciones organizadas por el casino. El dealer se pondrá en contacto vía e-mail para informar a los jugadores de las próximas competiciones y promociones."]
            }]
        }, {
            title: "Seguridad", items: [{
                title: "¿Es mi número de tarjeta de crédito seguro en sus manos?",
                content: ["Sí, su número de cuenta personal real y la contraseña debe ser suministrada antes de cualquier transacción de tarjeta de crédito. Los detalles específicos también se solicita para la compra de créditos en los casinos. Casino  " + casino.name + " garantiza la transferencia segura de datos en cualquier transacción y se compromete a no divulgar ninguna información de tarjeta de crédito u otra información a ninguna otra empresa."]
            }, {
                title: "¿Lo que la prevención del fraude y las medidas de seguridad están en su lugar?",
                content: ["Nunca des tu contraseña a nadie. Casino  " + casino.name + " nunca le pedirá su contraseña en un e-mail o por teléfono. Si alguien le pide su contraseña, por favor notifique a immediately. " + casino.name + " de atención al cliente juega un papel activo en la prevención y detección del fraude. Hacemos todo lo posible para garantizar la integridad de las cuentas de los clientes, así como los pagos realizados a los clientes.  " + casino.name + " emplea una variedad de controles de seguridad apropiados y el fraude que monitorean todas las transacciones. Para evitar el fraude y el abuso, de vez en vez que los clientes pueden ser obligados a suministrar una prueba de identidad, copias de sus tarjetas de crédito, una historia firmada de sus transacciones u otra documentación para la verificación y fines de seguridad. Si se le pide para verificar su identidad, sólo se le preguntó una vez."]
            }, {
                title: "¿Qué tan seguro es dar mi tarjeta de crédito a través de Internet? Es mi número de cuenta y contraseña segura?",
                content: ["Sí. El software del casino utiliza cifrado de 128 bits cuando se transfieren datos a través de Internet. Cada jugador se le entrega un número de cuenta único y los jugadores puede elegir su propia contraseña, que sólo ellos pueden acceder. Por otra parte, se utiliza el estado de la más moderno en tecnología de encriptación que garantiza que su información personal y tarjeta está protegida en todo momento."]
            }, {
                title: "¿Puedo registrar una tarjeta de crédito en el casino que no está a mi nombre?",
                content: ["El Casino no permite a los jugadores para registrar las tarjetas de crédito de las que no son signatarios. Estas medidas se toman para proteger a usted, el jugador y sus tarjetas de crédito y de cuentas. Si otra persona le gustaría jugar en nuestro casino a través de su ordenador, le recomendamos que abra su propia cuenta real."]
            }, {
                title: "¿Está mi información segura?",
                content: ["¡Sí! Una de las principales prioridades es la seguridad  " + casino.name + ". Para poder ofrecer el más alto de privacidad disponible se utiliza el protocolo de SSL4 con cifrado de 128 bits. El sistema permite la máxima seguridad y la integridad de toda la información que pasa hacia y desde nuestro servidor. Estas precauciones impiden el acceso de terceros no autorizados. Aparte de los informes periódicos, que son enviadas a nuestros clientes a través de correo electrónico, se recomienda encarecidamente a todos nuestros jugadores para hacer el uso completo de la transferencia y las historias de apuestas disponibles en el cajero."]
            }]
        }, {
            title: "Servicio Al Cliente", items: [{
                title: "¿Qué hago si necesito asistencia técnica?",
                content: [" " + casino.name + " ofrece soporte 24/7 a todos los jugadores de casino. Elija entre el teléfono, chat o correo electrónico. Por favor, póngase en contacto con nosotros ante cualquier consulta que pueda tener. Nuestros agentes están listos."]
            }]
        }, {
            title: "Aspectos Legales", items: [{
                title: "¿Cuál es la edad mínima para jugar?",
                content: ["Esto depende de donde usted vive. Usted está obligado a ser de la edad mínima para jugar en su jurisdicción."]
            }, {
                title: "¿Esta en Casino  " + casino.name + " licenciado?",
                content: ["Sí, Casino  " + casino.name + " opera bajo la jurisdicción de licencias del Gobierno del gobierno de curazao"]
            }]
        }, {
            title: "Promoción", items: [{
                title: "¿Cuáles son las promociones?",
                content: ["Qué aPromotions son una forma de Casino  " + casino.name + " para crear valor para sus jugadores. Se ofrecen como incentivos para depositar y jugar, como recompensa para los jugadores leales, e incluso como regalos en ocasiones especiales. Las promociones se ofrecen como bono de dinero, pero también puede ser en forma de puntos de bonificación."]
            }, {
                title: "¿Cuáles son los requisitos de apuesta?",
                content: ["Promociones suelen tener requisitos de apuesta asociados con ellos. Eso significa que hay una cantidad mínima de tiempo de juego se requiere después de reclamar una promoción antes de poder retirar sus ganancias. La cantidad que usted debe jugar para cumplir con los requisitos de apuesta es mucho menor que el promedio de los jugadores normalmente juegan. Los requisitos de apuesta suelen ser completada en un par de horas de juego (a menudo mucho menos) y el jugador medio juega más que eso."]
            }, {
                title: "¿Puedo decirle a mis amigos?",
                content: ["¡Por supuesto! Si ha disfrutado de su experiencia de juego en el Casino  " + casino.name + " animamos y gracias por la transmisión de la palabra a sus amigos. De hecho, si te refieres a un amigo con el Referir a un Amigo (hacer clic) vamos a agregar un bono de $ 25 a su cuenta después de que su amigo ha depositado al menos 25 dólares y hace algunas apuestas. Su invitado a un amigo aparecerá en la lista de las promociones en el cajero. Usted tendrá que hacer clic en el \"reclamo\" durante el dinero del bono que se añade a su cuenta."]
            }]
        }]
    },
    it: {
        title: "FAQ", content: [], sections: [{
            title: "Registrazione", items: [{
                title: "Come faccio a registrarmi al casinò?",
                content: ["Registrarsi è facile e veloce. Connetti ad internet ed avvia il software del casinò. La prima volta che avvierai il casinò dovrai scegliere se vuoi giocare per divertirti (gratis) o con soldi reali. A questo punto verrà visualizzata la schermata di registrazione. Inserisci i dati richiesti e clicca su \"Registra\". Dopo aver completato la registrazione verrai rediretto alla schermata di login. Il casinò memorizza il nome utente e, ove selezionato, anche la password, pertanto non dovrai inserirle ogni volta. Clicca su \"Login\" per iniziare a giocare."]
            }, {
                title: "Qual è l'importo minimo di crediti di gioco che devo acquistare?",
                content: ["Prima di poter giocare con soldi reali devi effettuare un deposito. L'importo minimo è pari a $25 in fiche del casinò."]
            }, {
                title: "Devo pagare per provare i giochi?",
                content: ["No! Puoi giocare in modalità GRATUITA finché lo vorrai. Se non conosci alcuni giochi offerti su  " + casino.name + " ti invitiamo a provarli e imparare a giocare con \"moneta virtuale\" prima di iniziare ad utilizzare soldi reali."]
            }, {
                title: "Posso acquistare altre fiche di moneta virtuale?",
                content: ["Sì. Puoi farlo cliccando sul pulsante \"Cassa\" nella Lobby o da qualsiasi gioco proposto. Clicca sul pulsante \"Imposta saldo\" e inserisci l'importo desiderato, quindi clicca \"Imposta saldo\". In modalità soldi virtuali puoi definire il saldo con la cifra desiderata."]
            }]
        }, {
            title: "Connessione", items: [{
                title: "Qual è il mio nome utente e/o la mia password?",
                content: ["La password è sensibile alle maiuscole e minuscole e deve essere scritta nella stessa maniera ogni volta che si tenta l'accesso. Se hai dimenticato la password o il numero di account, contatta il servizio clienti per ricevere assistenza. Ricordati di avere i dati della registrazione a portata di mano. Nel rispetto delle stringenti politiche di eCongra sui dati del conto, saremo in grado di fornire i dati del conto del giocatore solo dopo aver identificato correttamente il giocatore al telefono."]
            }, {
                title: "Ho dimenticato la password, cosa devo fare?",
                content: ["Sulla schermata di login, è presente un link denominato \"Clicca qui se hai dimenticato la password.\" Clicca sul link indicato e ti verrà inviata una nuova password tramite email."]
            }, {
                title: "Cosa succede se provo ad effettuare il login e non si connette?",
                content: ["Il problema potrebbe essere dovuto ad un problema con la connessione al tuo internet provider. Disconnettiti e riconnetti ad internet e prova nuovamente. Se continui ad avere problemi con la connessione, contatta il supporto clienti."]
            }]
        }, {
            title: "Depositi E Prelievi", items: [{
                title: "Come compro crediti?",
                content: ["Offriamo diverse opzioni di deposito su  " + casino.name + ": Carte di credito, Cashlib o Bitcoin. Per maggiori informazioni consulta la pagina Cassa. Se hai domande riguardo alla sezione banking, non esitare a contattarci."]
            }, {
                title: "Come faccio a riscuotere le vincite?",
                content: ["Ti facciamo presente che non è necessario convertire in denaro le vincite al termine di ogni sessione. È possibile infatti lasciare un qualsiasi importo sul proprio conto che rimarrà disponibile al prossimo login. Non devi neanche preoccuparti di dimenticarti di avere dei soldi inutilizzati sul conto, in quanto ti invieremo via email un promemoria nel caso in cui siano presenti dei soldi e ci accorgiamo che non hai giocato per qualche tempo. Naturalmente, è egualmente possibile decidere di prelevare i soldi in qualsiasi momento.  " + casino.name + " Casino offre diversi metodi di prelievo. Un elenco completo è presente nella sezione Cassa."]
            }, {
                title: "Annullamento del prelievo",
                content: ["Nel caso in cui venga effettuata una richiesta di prelievo ma si cambi idea prima che l'operazione venga elaborata è possibile annullare tale richiesta continuando a giocare con i crediti disponibili. Nel caso in cui siano presenti delle richieste di prelievo che possono ancora essere annullate, tale opzione verrà visualizzata nella sezione Cassa quando si andrà ad effettuare un versamento."]
            }]
        }, {
            title: "Il Gioco", items: [{
                title: "Quali sono le probabilità di vincita?",
                content: ["I payout dei nostri giochi variano. I risultati dei giochi vengono determinati dal generatore numerico randomico protetto con sistema di crittografazione di Rival, che è stato testato da società indipendenti e dichiarato idoneo a fornire sequenze casuali di numeri. Degli ispettori indipendenti verificano di tanto in tanto i payout. A seconda del gioco il sistema può usare un mazzo singolo o mazzi multipli che vengono mischiati prima di ogni puntata."]
            }, {
                title: "Perchè i rulli delle slot continuano a girare e non si fermano? Perchè la pallina della roulette continua a girare e non si ferma?",
                content: ["Questo problema è causato da problemi con la connessione internet. In particolare, il server non riesce a connettersi al computer e pertanto i rulli delle slot continuano a girare in quanto non hanno ricevuto un messaggio che gli dice dove e quando fermarsi. Se chiudi il programma e lo riavvi verrà ricaricata l'ultima posizione conosciuta."]
            }, {
                title: "Perché le carte non si girano durante una mano?",
                content: ["Il problema è dovuto ad una connessione lenta o interrotta. Il server di gioco manterrà la puntata intanto che il problema non sarà risolto."]
            }, {
                title: "Come faccio a sapere quali carte esattamente sono state distribuite?",
                content: ["Puoi vedere la cronologia nella Cassa."]
            }, {
                title: "Concorsi e premi",
                content: ["I membri del " + casino.name + " Casino possono partecipare GRATUITAMENTE a tutti i concorsi del casinò. Il dealer contatterà via email i giocatori per informarli sui prossimi tornei e promozioni."]
            }]
        }, {
            title: "Sicurezza", items: [{
                title: "I dati della mia carta di credito sono protetti?",
                content: ["Sì, il numero personale del tuo account reale e la password devono essere forniti ogni volta prima di una transazione con carta di credito. Inoltre verranno richiesti dati specifici per completare l'acquisto di crediti al casinò.  " + casino.name + " Casino garantisce il trasferimento sicuro dei dati durante le transazioni finanziarie e si impegna a non condividere i dati della carta di credito o altre informazioni a terzi."]
            }, {
                title: "Quali sono le misure di sicurezza e antifrode adottate?",
                content: ["NON FORNIRE mai la tua password a terzi.  " + casino.name + " Casino non chiederà mai la tua password tramite e-mail o via telefono. Se qualcuno dovesse richiederti la tua password, ti invitiamo a contattare immediatamente il servizio di supporto.  " + casino.name + " è parte attiva nella prevenzione e nel rilevamento delle frodi. Facciamo tutto il possibile per garantire l'integrità degli account dei nostri clienti e dei pagamenti effettuati ai nostri clienti.  " + casino.name + " adotta una serie di strumenti di sicurezza e controlli antifrode sulle transazioni effettuate. Per prevenire atti di frode o abuso, di tanto in tanto richiediamo l'invio di documenti di identità, copie della carta di credito, storici delle transazioni o altri documenti atti alla verifica delle transazioni eseguite. Sarà necessario, ove richiesto, presentare tali documenti una sola volta."]
            }, {
                title: "I miei dati della carta di credito sono sicuri su internet? E i miei dati del conto e la mia password?",
                content: ["Sì. Il software del casinò usa un sistema di crittografia a 128 bit quando i dati sono trasferiti tramite internet. A ciascun giocatore verrà fornito un numero univoco del conto e il giocatore potrà decidere la propria password. Inoltre, usiamo tecnologie all'avanguardia di crittografazione che assicurano che i dati personali e della carta di credito siano protetti in ogni momento."]
            }, {
                title: "Posso registrare al casinò una carta di credito non a mio nome?",
                content: ["Il Casinò non offre ai giocatori la possibilitàˆ di registrare carte di credito intestate a terzi. Questa misura è stata adottata a protezione del giocatore, del suo account e delle sue carte di credito. Se un'altra persona vuole giocare nel nostro casinò˜ tramite il tuo PC, invitiamo ad aprire un nuovo account intestato a tale persona."]
            }, {
                title: "I miei dati sono sicuri?",
                content: ["Sì! Una delle priorità più importanti di  " + casino.name + " è la sicurezza. Per essere in grado di fornire i massimi livelli di privacy, abbiamo adottato il protocollo SSL4 con crittografia a 128 bit. Il sistema permette di garantire così la massima sicurezza ed integritˆ delle informazioni trasferite sui nostri server. Queste precauzioni prevengono l'accesso non autorizzato di terze parti. Fatta eccezione per i riepiloghi periodici che inviamo al cliente via email, invitiamo i giocatori a consultare lo storico di gioco e dei trasferimenti direttamente dalla sezione Cassa del sito."]
            }]
        }, {
            title: "Servizio Clienti", items: [{
                title: "Cosa devo fare se ho bisogno di assistenza tecnica?",
                content: [" " + casino.name + " fornisce un supporto 24 ore su 24, 7 giorni su 7, a tutti i giocatori del casinò. Scegli tra telefono, chat o email e contattaci per qualsiasi domanda o dubbio. I nostri consulenti sono pronti ad aiutarti."]
            }]
        }, {
            title: "Aspetti Legali", items: [{
                title: "Qual è l'età minima per giocare?",
                content: ["L'età minima per giocare dipende dal tuo paese di residenza. Per poter giocare devi soddisfare le imposizioni di legge nella tua giurisdizione in materia di gioco d'azzardo."]
            }, {
                title: " " + casino.name + " Casino è dotato di regolare licenza?",
                content: ["Sì,  " + casino.name + " Casino opera sotto la giurisdizione e relativa licenza del Governo del governo di Curaçao."]
            }]
        }, {
            title: "Promozioni", items: [{
                title: "Cosa sono le promozioni?",
                content: ["Le promozioni sono un modo di valorizzare i giocatori del " + casino.name + " Casino. Le promozioni sono offerte come incentivo per effettuare un deposito e giocare, come premio per i giocatori abituali o come regalo per occasioni speciali. Le promozioni sono generalmente offerte sotto forma di bonus di denaro ma possono essere anche punti bonus."]
            }, {
                title: "A quali termini sono soggette le promozioni del " + casino.name + " Casino?",
                content: ["Le promozioni hanno generalmente associati dei termini e condizioni. In genere è presente un tempo minimo di gioco prima di poter prelevare le vincite in caso di utilizzo della promozione. Il tempo richiesto è nettamente inferiore al tempo medio generalmente giocato da un giocatore normale. Tali requisiti vengono tipicamente soddisfatti dopo un paio d'ore di gioco (spesso molto meno), quando un giocatore medio gioca mediamente più a lungo."]
            }, {
                title: "Posso dirlo ai miei amici?",
                content: ["Certamente. Se l'esperienza di gioco al " + casino.name + " Casino è stata di tuo gradimento, ti invitiamo a fare passaparola e ti ricompenseremo per questo. Se inviti un tuo amico aggiungeremo un bonus di $25 sul tuo conto dopo che il tuo amico avràˆ depositato almeno $25 ed effettuato qualche puntata."]
            }]
        }]
    }
}

export default faq