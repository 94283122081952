import React from "react";
import casino from "../../config";

const terms = {
    "en": {
        title: "Terms and conditions",
        content: ["Playing at a casino, whether for fun play or real play, constitutes an agreement between the player and the Online Casino and its management, in that the player be bound by the following terms and conditions contained herein. The player accepts that this is a binding agreement between the player and the casino and is the sole agreement governing the player's relationship with the casino, to the exclusion of all other agreements, statements, and representations.",
            "Each clause contained in these terms and conditions shall be separate and severable from each of the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect."],

        sections: [{
            title: "Definitions",
            content: [<>The Player: <br/> Any user of the software and / or games and / or promotions offered by the
                Casino.</>, <>The Casino:<br/> {casino.name} Online Casino, including all software and content and
                services rendered on {casino.name} and associated URLs.</>, <>Service:<br/>Services, support,
                offers and assistance offered by The Casino to its users.</>, <>Credits:<br/>Amount of money on the
                user's account balance; Amount of game chips/money available to use. 1 Credit = 1€, 1£, 1$, 1AUD, 10ZAR
                (approximately).</>, <>We/Us/Our:<br/>The operators of the Casino Website and/or its group
                companies;</>, <>Player/s:<br/>Player and/or a Guest Player and/or a Dormant Player and/or a Closed
                Account Player;</>, <>Real Player:<br/>A Player who utilizes his own money to place wagers at the
                Casino;</>, <>Wagering Requirement:<br/>The number of times a sum of credits has to be wagered, staked
                or bet before the balance is eligible for withdrawal. Also known as Rollover requirement.</>]
        }, {
            title: "Disclaimer",
            content: ["Playing at the casino, whether for fun play or real play constitutes an agreement between the player and the Casino that the player be bound by the following terms and conditions contained herein. By registering, opening an account and / or playing at " + casino.name + " Online Casino, the player hereby agrees that they have:",
                "1. Fully read and understood the terms and conditions written below and",
                "2. Have fully accepted the terms and conditions and will abide by them in all dealings with the Casino and / or the Casino software.",
                "If any clause is found to be void, invalid, or unenforceable for any reason, the remaining terms and conditions shall remain of full force and effect. The Terms and Conditions outlined herein supersede and overwrite any other terms offered by the Casino.",
                "The Casino is registered, licensed and regulated by the Government of Curacao for the purpose of operating an online casino."]
        }, {
            title: "General promotion terms and conditions",
            content: ["1.1. Playing at " + casino.name + " Online Casino is restricted to adults only. Players must be 25 years of age or older to play. Age restriction may change according to local national law, and it is the responsibility of the player to check for the minimum age allowed in their country. The Casino reserves the right to require age verification from the player at any time.",
                "1.2. The player accepts that the Casino may change, add or subtract from the terms and conditions at any time without previous warning, and it is the responsibility of the player to periodically check the terms and conditions written here for changes that may affect them. Lack of knowledge of said terms and conditions does not constitute a valid reason to any violation of the rules and conditions set here, from the moment of opening a new account in casino.",
                "1.3. All decisions made by the Casino are final, and will comply with the terms and conditions set out in this document. By registering in the Casino, the player agrees to accept any and all decisions made by the Casino as final and deciding.",
                "1.4. The Casino will bear no liability or responsibility, either directly or indirectly, as regarding the player's legal right to play at an online casino with real money. This includes any employees, licensees, distributors, wholesalers, affiliates, subsidiaries, advertising, promotion or other agencies, media partners, agents or retailers claim or been seen to claim authorization to make any such representations or warranties. It is the sole responsibility of the player to check the local law and abide by it responsibly. The Casino will carry no liability for any infringement of the law by the player.",
                "1.5. Any games, services or promotions offered by the Casino are purely meant for entertainment purposes, and not as a financial system. An option exists to play with fake credits that do not equal real money. Playing for real money is the sole decision of the player, and in doing so, the player fully accepts any risks involved by playing with real credits.",
                "1.6. Any fees, taxes, transfer fees or other financial responsibilities or consequences related to using the games and / or services offered by the Casino are the responsibility of the player and the player alone.",
                "1.7. The player will not hold the Casino nor any agency, employee, partner or media related to the Casino liable for any damages, costs, expenses or liabilities incurred by using and / or accepting services from the Casino nor for any activity of the player related to the Casino.",
                "1.8. The Casino will not bear any responsibility or liability for any technical errors occurring in the software which are related to the player's internet connection, personal computer software or personal computer hardware. Any interruptions in internet service are the responsibility of the player and the Casino will not compensate nor bear any liability for interruptions caused by a technical issue on the player's side, including but not limited to internet disconnections. Any other technical faults must be submitted in writing and include a screenshot so that the Casino may verify its authenticity.",
                "1.9. The player hereby agrees that the registered identity to their casino account will be the only person logging into said account and using and/or playing with the Casino software or website. Any infringement of said agreement (i.e. use of the account by a different person) will be considered a breach of terms and conditions and grounds for termination of the account and forfeiture of any winnings. The account is not transferable in any way nor can the user be changed for the account. Any new users must open a new account or be in violation of terms and conditions.",
                "1.10. The Casino reserves the right to change, add or subtract from the games and / or services it offers, at any time, without prior announcement or update. Terms and conditions may be changed or altered and the player accepts this as part of the prerogative of the website to do so when and if necessary.",
                "1.11. The player further indisputably agrees to indemnify " + casino.name + " Online Casino, its employees, officers, directors and any and all associated or affiliated persons or organizations against any and all costs, expenses, liabilities and damages arising from any legal or other action taken either by or against the Player arising from any and all action within the Casino and/or any of its employees, officers, directors and any and all associated or affiliated persons or organizations. This occurrence shall include (but not limited to) any legal implication that might arise as a result of the criminalization of online/Internet wagering within the Players jurisdiction, territory or country.",
                "1.12. The player accepts any and all game results shown by the Casino software or website as true. Any disputes regarding the outcome of games played will be decided by results from the game server, and these will determine the final decision of the Casino. If for any reason the player is not totally satisfied with the terms and conditions laid out before them, then the player is within their rights to discontinue playing and remove the software from his/her computer. Furthermore, a player who is unsatisfied with his/her personal gaming experience at the Casino and wishes to close their account, bears responsibly for manually closing or freezing said account from within the Casino software and to remove any software associated with the casino from their computer or mobile devices. Legal age restrictions and strict adherences to the policies of age as agreed by the jurisdiction of the said territories to comply with the said law of that territory are full enforced by the Casino. This is clearly defined by no player under the age of 25 years of age is permitted to play at the casino regardless of gender.",
                "1.13. In the event of a complaint or grievance or technical complaint or dispute in the Casino, the complaint must be submitted in writing within five (5) days of the event. If the complaint is received after this time, the casino reserves the right not to address the matter. However, if there are extenuating circumstances; for example: A Player is incapacitated or otherwise unavailable for comment, we will address these issues on a case by case basis but reserve the right to invoke the five (5) day rule above.",
                "1.14. The player accepts all decisions made by the Casino as final.",
                "1.15. Any funds remaining in the balance of an account at the time of closure cannot be withdrawn. This included all and any pending withdrawals."]
        }, {
            title: "Violations of terms and conditions",
            content: ["2.1. The following actions by the player will be considered a violation of the terms and conditions of the Casino, but not limited to this list. Consequences may include termination of the player's casino account, confiscation of winnings and existing balances, denial of services, promotions and any other offers by the Casino.",
                <ul>
                    <li>More than one account at {casino.name} Online Casino and/or more than one account per
                        household and/or more than one account per device, and/or more than one account from the same IP
                        address, and/ or more than one account on an affiliated sister casino under the same management
                        as {casino.name} Online Casino.
                    </li>
                    <li>Should at any time, the casino discover a linkage or connection to a fraudulent, suspended or
                        refunded or previously closed account on any brand within the Rival network or any other online
                        casino.
                    </li>
                    <li>Should at any time the casino discover an active or past complaint/dispute/criticism on a public
                        forum or blog or any other type of website.
                    </li>
                    <li>Should at any time the player abuse, harass or speak inappropriately to any of the Casino staff,
                        including verbal and written threats or intimidation.
                    </li>
                    <li>A mismatch on the Player's Casino account not matching the name on the credit card(s) or payment
                        method(s) used to make deposits on the account.
                    </li>
                    <li>Incorrect or false registration information.</li>
                    <li>The player is playing from a territory or region not fully authorized to allow online gaming.
                    </li>
                    <li>The player is not of the required legal age according to the Casino Terms and Conditions.</li>
                    <li>If the Player has allowed or permitted (intentionally or unintentionally) another player to play
                        on their own Casino account.
                    </li>
                    <li>If an individual has not played in the Casino purely as an entertainment and has only
                        participated in a professional capacity or in association with another player from another club
                        or group or part thereof.
                    </li>
                    <li>If the Player has been found deliberately making a 'chargeback' or 'dispute' of funds deposited
                        from their credit card, or any other payment method, into their Casino account; or have been
                        found to have made a 'chargeback' or threatened to do so in any other capacity on a sister
                        casino on the Rival platform or any other online casino or website. This includes verbal and
                        written threats to dispute, chargeback or stop transaction.
                    </li>
                    <li>If the Player is discovered to be cheating or has been found making irregular betting patterns,
                        or suspicious or irregular betting strategies and or the Player has instigated a system (i.e.
                        Martingale, machines, computers, software or any other form of automation) to proliferate a
                        cheat designed for the Casino to lose revenue due to this operation.
                    </li>
                    <li>The player is linked to any employee, ex-employee, company, third party or agency connected to
                        the Casino currently or in the past.
                    </li>
                    <li>The player has made any attempts to 'hack' the Casino software or website, or made any changes
                        to it in any way not specifically allowed by the Casino.
                    </li>
                </ul>,
                "2.2. Should the casino learn any of the above rulings listed be affected by the player at any other web-based casino, " + casino.name + " Online Casino will act accordingly.",
                "2.3. In acceptance of said rulings and any other conditions outlined herein, the player agrees to any promotional or advertising events upon acceptance of any prize and/or winnings that have been accrued from " + casino.name + " Online Casino and thereby endorses the use of that players username, country or real first name for the said practice without any compensation or payment to the player. Example: Should a Player win a large Jackpot and the Casino wishes to advertise the win, the Casino withholds the right to use the player's username, country and/or real first name and post the win on the Casino site, a magazine, another website or any other media of the Casino's choosing."]
        }, {
            title: "General terms and conditions",
            content: ["3.1. The Casino is committed to offering fair and honest games and confirms the software and all games offered by " + casino.name + " Online Casino rely on an audited and validated Random Number Generator (RNG) by a third party ensuring that all games are fair and honest.",
                "3.2. The Casino reserves the right to modify the balance on a user's account to redeem sums owed to the Casino.",
                "3.3. The user agrees to pay all sums due to " + casino.name + " Online Casino and to never try to re-attribute, deny, revoke, dispute, chargeback, block, hold or cancel any of these payments.",
                "3.4. If the user has deposited or withdrawn funds using an E-Wallet such as MoneyBookers, Neteller or EcoCard, the Casino may request copies of other transactions showing that specific E-Wallet in use.",
                "3.5. Any illegal or criminal activity made by the player will result in immediate termination of the Casino account and the Casino reserves the right to inform the proper authorities and other online casinos and merchants of these actions with full disclosure.",
                "3.6. The Casino reserves the right to change any of its offered services at any time, including a temporary unavailability of customer support, technical support or any other services given to the player.",
                "3.7. In the event of technical failure or computer glitch, all bets in play may be considered null and void. The Casino cannot be held responsible for losses, wins or other issues generated by a technical failure or glitch in any way or form.",
                "3.8. Should a player abstain from logging into their Casino account for a period of 90 days or more or the player 90 days without making a single bet or wager, the Casino will be entitled to any balance in that players account and the sum forfeit. Should the player wish to withdraw this amount after this period, we will examine each situation on a case by case basis but reserve the right to invoke the above 3 month rule."]
        }, {
            title: "Promotions and bonus terms and conditions",
            content: ["4.1. All and any free bonuses and deposit bonuses cannot be withdrawn from the Casino, and are meant to enhance game time only. Upon withdrawal, the bonus amounts will be deducted by the Casino before the withdrawal is sent.",
                "4.2. All bonus money will be removed at the point of cash out, bonus money is money that can be used to help generate winnings but cannot be withdrawn as part of the winnings. The bonus will be treated as 'Non-Withdrawable' and will not be reissued to your account but deducted from the winnings by the casinos withdrawal manager during the payout process. Cashback money is also 'Non-Withdrawable' and the casino reserves the right to deducted cashback money from a players' pending withdrawal.",
                "4.3. Bonuses, unless specifically specified by the Casino beforehand, carry a wagering requirement that must be completed before a player can withdraw.",
                "4.4. All cashback promotions carry a wagering requirement of 1X. Players cannot withdraw a cashback bonus until the sum of the cashback is wagered in full. All cashback money is restricted to a maximum withdrawal limit of 10X the cashback amount. This includes cashback awarded as 'gifts', 'comps' or 'free chips'. The Casino reserves the right to deduct Cashback money from a pending withdrawal.",
                "4.5. All cashback money won from a Tournament prize, Raffle prize or Special Promotion have a maximum withdrawal limit of 2X the cashback bonus and carry a wagering requirement of 1X. All bonuses of 200% or more, cashback insurance of 75% or more will be considered a Special Promotions. The Casino reserves the right to deduct Cashback money from a pending withdrawal. Players who have a pending withdrawal or balance of over 100 $/€/£/ do not qualify to receive Tournament prizes or to receive cash from a Raffle or cashback from any ongoing Cashback promotion or any other special promotion offered by the Promotions Team.",
                "4.5.1 Special Bonus - all bonuses of 200% or more, cashback bonuses of 100% or more will be considered Special Bonuses",
                "4.6. It is the responsibility of the player to be aware of the details of the bonus they request or is given to them, including the wagering requirement, restricted games and any other details, terms and requirements associated with said bonus.",
                "4.7. Players choosing not to play with bonuses, must state so before starting to play with a bonus. If a player does not want the bonus that has been deposited into their account by the casino, then they are entitled to request a casino customer support representative reverse the bonus out of their casino account, by way of email, telephone call or live Chat. If a bonus is received and a wager is made (of any amount), the bonus will have to be played all the way through, until the balance is zero or the wagering requirement is complete. If the player has not begun to play, support will remove the bonus should they ask for it.",
                "4.8. Free bonuses (Comps, Free Chips, converted Comp Points or any other non-deposit bonuses) are playable bonuses awarded to player so they may play free of charge. Free bonuses have a different wagering requirement than other bonuses, and have a fixed withdrawal limit of no more than 100 Credits (100 $,€,£,AUD, or 1000 ZAR). Any remaining or extra balance is 'Non-Withdrawable' and is to be cleared from a player's balance. Each player is allowed to withdraw winnings from a free bonus only once during his/her gaming lifetime unless specifically approved by the casino manager.",
                "4.9. A player who has never made a deposit and wishes to withdraw a free chip win, is required to first make one successful minimum deposit of 25.00 ($,€,£,AUD, or ZAR). This first deposit amount is not allowed to be withdrawn or added to an existing pending withdrawal. If added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. A players who has previously made a successful deposit and is now wishing to withdraw a free chip win, is required to have deposited within the last 30 days in order for the withdrawal to be approved and the deposit cannot be added to an existing pending withdrawal. If added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. Free Bonuses (Free Chips) have a wagering requirement of 20X to 60X unless otherwise specified.",
                "4.10. All promotions offered by the Casino are only valid for the period specified in the promotional offer itself which is posted on the Casino website and/or any other communication provided by the Casino itself.",
                "4.11. A bonus equal to or greater than 200% that has been claimed with any deposit, will be subject to a max cashout of 10x the deposit amount.",
                "4.12. A free bonus player (a player who plays only free bonuses, and didn't make a deposit yet) can withdraw winnings when playing his first five free bonuses on his account.After five bonuses played, the funds received from a free bonus will be considered not cashable and will be deducted from the balance or from the withdrawal."]
        }, {
            title: "Withdrawal terms and conditions",
            content: ["5.1. All Deposits of 249 USD/EURO/GBP/AUS or less have a max withdrawal of 10X the deposit for that specific play session (all other currencies pegged to USD). Once a withdrawal is approved, the casino applies the 10X rule and corrects the withdrawal to accurately reflect the 10X rule. The remaining funds are deemed 'Non-Withdrawable' and are cleared from the player's balance. Deposit bonuses used during a play session that results in a pending withdrawal request, are also 'Non-Withdrawable' and can be deducted from that particular pending withdrawal.",
                "5.2. Customers can submit their withdrawal request at anytime. The approval time may take fifteen - eighteen (15 - 18) working days starting the date authorization process was completed successfully. Processing a pending withdrawal requires authorization from the Fraud department as well.",
                "5.3. Any and all bonuses redeemed by the player cannot be withdrawn or redeemed from the Casino, and will be removed from the player's winnings upon processing of the withdrawal.",

                "5.4. For a withdrawal to be approved, the player will be asked to submit certain documentation and information in order to verify the account. Failure to comply or failure to submit said documents and information 30 days from the time the withdrawal was requested, will result in the withdrawal being cancelled. It is the responsibility of the individual player to send the required documentation. Withdrawal approval depends on all conditions outlined and the verification of all required documents.",
                "5.5. A player may not withdraw winnings as long as there is still a wagering requirement attached to their balance. They must complete the wagering requirements before making a withdrawal request, or risk having the withdrawal denied.",
                "5.6. The casino has the right to refuse or withhold a bonus or winnings in the event of a deposit being cancelled, returned or denied by any payment service or processor at anytime.",
                "5.7. If the player has wagered on restricted games with a bonus that does not allow said games, the Casino reserves the right to cancel any withdrawal and confiscate any winnings. Before starting to play with a deposit bonus or free chip, it is the responsibility of the player to check and understand what are the restrictions of that particular bonus or chip.",
                "5.8. The minimum amount for a withdrawal is 100 €/$/£/AUD or 1000 ZAR.",
                "5.9. Any and all deposits must be wagered at least once (1X) the sum of the deposit in order to qualify for a withdrawal. A withdrawal cannot be approved unless a minimum wager of 1X the deposit is first met. Deposits made as part of promotions are subject to that specific promotion's Terms and Conditions and wagering requirement.",
                "5.10. By depositing funds into your account, you direct us and we agree to hold them, along with any winnings, for the sole and specific purpose of using them (i) to place any gaming wagers; and (ii) to settle any fees or charges that you might incur in connection with the use of our services. We reserve the right to charge a fee of 3% - 6.5% for the processing of Credit Card deposits.",
                "5.11. A player that didn't add funds to his account for three months or more (90 days), will be considered a free bonus player and will not be eligible to withdraw winnings from free bonuses or cash granted to him by the casino. The possibility of withdrawing winnings from free bonuses is reserved for our active players only."]
        }, {
            title: "Privacy agreement",
            content: [<>Disclaimer:<br/>6.1. The Casino is obligated to keep all personal information given by the
                player safe and secure. Any details submitted by the player to the Casino at any point, will remain
                solely with the Casino and will not be given out to any third party or
                agency.</>, "6.2. It is the sole responsibility of the player to keep all user names and passwords related to their casino account safe and secure. The Casino will not carry liability for any use of the account details by a person who is not the originally registered player.",
                "6.3. The Casino retains the right to use the player's name as part of promotional events (e.g. publish a big win by said player), without any compensation or payment made to said player and will not be required to seek preapproval by the player.",
                "6.4. The Terms and Conditions outlined define a final and exclusive understanding and agreement between You (the Player) and the Casino and complete all previous agreements, representations and understandings between You and " + casino.name + " Online Casino with regards to your participation in the Casino.",
                "6.5. The Casino reserves the right to withhold depositing or withdraw any credits or bonuses at our discretion. The Casino reserves the right to withdraw or amend the terms of this program at any given time or without prior notice",
                "6.6. This offer is not applicable for employees, partners, suppliers or any other party with a business and/or professional relation to " + casino.name + " Online Casino. Intellectual property",
                "6.7. Via this contract, The Casino grants the user a revocable, nonexclusive and non transferable right, to use its website, its software and/or its service as well as all linked services. This right also extends to the royalties and all the rights of intellectual ownership being attached to it.",
                "6.8. The user recognizes and accepts that the copyright, the mark and all other rights of intellectual ownership being exerted on any material and/or provided contents and belonging to the website and/or the software of casino, remain our strict property. The user can use the above mentioned material only in agreement with these terms and conditions.",
                "6.9. " + casino.name + " Online Casino is the trade name of the Casino, and the Player has no rights to use or employ such a term, or any other terms, graphics, text, concepts or methodologies, by participating in the Web Site and the material contained therein.",
                "6.10. The user recognizes and accepts the fact that the material and the contents reproduced on the website of the Casino are placed at their disposal only as non commercial titles. This material and these contents should be downloaded on the hard disk of a computer only to serve this goal. If any of the materials of the Casino games are interfered with mutilated, forged or damaged they become void and unusable. Regardless of how they are obtained whether physically or electronically. Material liability of the Casino games that contains any error is only limited to a replacement of the same material.",
                "6.11. Any other use is strictly prohibited as well as the copy, falsification, the reproduction, the publication, the transmission and the distribution of work derived from this material and these contents.",
                "6.12. Possible criminal or suspicious actions may be reported to the appropriate authority. When called upon to do so Players may need to produce certain verification documents when transacting with the casino in withdrawals or deposits. Proof of such could be clear photo identification, proof of address, credit card copies and purchase declaration forms. This requirement is to validate the security of our players and in compliance with Gaming regulations.",
                "6.13. This contract is regarded as duly signed and accepted by the two parties: The Casino and the User.",
                "6.14. The Terms and Conditions outlined define a final and exclusive understanding and agreement between You (the Player) and " + casino.name + " Online Casino and complete all previous agreements, representations and understandings between You and " + casino.name + " Online Casino with regards to your participation in the Casino."]
        }, {
            title: "Refer-a-friend terms and conditions",
            content: ["7.1. The general terms and conditions apply to the refer-a-friend program and all other programs offered by " + casino.name + " Online Casino. By submitting all required information into the refer-a-friend program, both you and the referred party (person being referred) consent to all terms of this program and the referred party has consented to their data being supplied by you the referring party. By submitting this information, the referred party consents to having an email sent to him/her by " + casino.name + " Online Casino requesting him/her to sign up for a real player account. By submitting information into the refer-a-friend program, the referred party is fully aware that the referring party has submitted their details in good faith.",
                "7.2. Persons utilizing the same computer (registering from the same IP address) will not be permitted to partake in the refer-a-friend bonus for security purposes. Referred players must not be existing members of " + casino.name + " Online Casino. The email address provided by the referring party must not be associated with any current player of " + casino.name + " Online Casino nor shall it be the same email as the referring member.",
                "7.3. The referring member will be required to fill out specific information about his/her referred friend; this information includes, but is not limited to their given name, surname and email address. After all required information has been provided, submitted and then verified by " + casino.name + " Online Casino, then and only then shall the bonus be supplied to both the referring and referred members.",
                "7.4. In order to qualify for the refer-a-friend bonus, the referred party must first sign up for a real money account and deposit a minimum of 25 $,€,£ into that account. Both the referring friend and the referred friend will receive their bonus only after the referred friend has deposited 25 $,€,£ into their real player account and must allow for 48 hours before receiving the bonus in their real player accounts."]
        }, {
            title: "Refund policy",
            content: ["Thanks for opening an account with us and depositing funds into your account at our websites.",
                "We offer the option of a refund on all l deposits made on our websites. However, certain conditions have to be met. We invite you to read the conditions explained in more detail below.",
                "Our returns policy is simple, straight forward, and we will be processed immediately.",
                "However, there is a limited window of time as indicated:",
                <ul>
                    <li>Within 24 hours of the original deposit.</li>
                    <li>As required at the time of opening the account, the compliance must be completed and approved by
                        our compliance department.
                    </li>
                    <li>Games malfunction - Screenshot required.</li>
                    <li>Unable to play credits - Balance unplayed.</li>
                    <li>Wrong charges - statement required.</li>
                </ul>,
                <>Processing Fee<br/>There is a fee involved in processing and issuing your refund. The different fees (at your currency) are as follows:</>,
                <ul>
                    <li>Paper Check - 55.00</li>
                    <li>SEPA wire - 20.00</li>
                    <li>SWIFT wire - 75.00</li>
                </ul>,
                "Late or missing refunds (if applicable):",
                <ul>
                    <li>If you haven’t received your refund yet, first check your bank account again.</li>
                    <li>Then contact your credit card company, it may take some time before your refund is officially
                        posted.
                    </li>
                    <li>If you’ve done all this and you still have not received your refund yet, please contact us
                        at {casino.email}</li>
                </ul>]
        }]
    }, "fr": {

            title: "Termes et conditions",
            content: ["Jouer dans un casino, que ce soit pour vous amuser ou pour le jeu réel, constitue un accord entre le joueur et le casino en ligne, le joueur est lié par les termes et conditions suivants le contenu dans les termes et conditions. Le joueur accepte qu'il s'agit d'un accord entre le joueur et le casino et que c'est le seul accord régissant la relation du joueur avec le casino, à l'exclusion de tous les autres accords, déclarations et représentations.",
                "Chaque clause contenue dans ces termes et conditions sera séparée et séparable de chacune des autres. Si une clause est jugée nulle, invalide ou inapplicable pour quelque raison que ce soit, les autres termes et conditions resteront pleinement en vigueur."],
            sections: [ {
            title: "Définitions",
            content: [
                <>Joueur:<br/>Tout utilisateur du logiciel et / ou des jeux et / ou des promotions offertes par le
                    Casino.</>,
                <>Casino:<br/>Casino{casino.name}, inclus tous les logiciels, le contenu et les services rendus
                    sur www.truefortune.com et les URL associés</>,
                <>Services:<br/>Services, support, offres et assistance offerte par le casino pour ses utilisateurs.</>,
                <>Crédits:<br/>Montant de l'argent sur le solde du compte de l'utilisateur; Montant des jetons / argent
                    disponible à utiliser. 1 crédit = 1 €, 1 £, 1 $, 1AUD, 10ZAR (environ).</>,
                <>Nous:<br/>Les opérateurs du site Web du Casino et / ou des sociétés de son groupe;</>,
                <>Joueurs:<br/>Un Joueur et / ou un joueur invité et / ou un Joueur inactif et / ou un joueur dont le
                    Compte est fermé;</>,
                <>Joueur réel:<br/>Un joueur qui utilise son propre argent pour placer des paris au Casino;</>,
                <>Exigences de Mises:<br/>Le nombre de fois qu'une somme de crédits doit être misée, jalonnée ou pariée
                    avant que le solde soit admissible pour le retrait. Aussi connu comme exigence de roulement.</>]
        }, {
            title: "Décharge",
            content: ["Jouer au casino, que ce soit en mode fun ou en mode réel, constitue un accord entre le joueur et le casino que le joueur sera lié par les termes et conditions suivants contenus dans ce document. En s'inscrivant, en ouvrant un compte et / ou en jouant sur Casino " + casino.name + ", le joueur accepte qu'il a:",
                <ol>
                    <li>entièrement lu et compris les termes et conditions écrits ci-dessous;</li>
                    <li>2. pleinement accepté les termes et conditions et qu'il s'engage à les respecter dans toutes les
                        relations avec le casino et / ou le logiciel du casino.
                    </li>
                </ol>,
                "Si une clause est jugée nulle, invalide ou inapplicable pour une raison quelconque, les modalités et les conditions restantes demeureront de plein effet. Les termes et conditions décrits annulent et remplacent toutes les autres conditions offertes par le Casino.",
                "Le Casino est inscrit, autorisé et réglementé par le gouvernement de Curaçao à des fins d'exploitation d'un casino en ligne."]
        }, {
            title: "Termes & conditions généraux - promotions",
            content: ["1.1. Jouer sur Casino " + casino.name + " est limité aux adultes seulement. Les joueurs doivent avoir 25 ans ou plus pour jouer. La restriction d'âge peut changer selon le droit national local, et il relève de la responsabilité du joueur de vérifier l'âge minimum autorisé dans son pays. Le Casino se réserve le droit d'exiger la vérification de l'âge du joueur à tout moment.",
                "1.2. Le joueur accepte que le Casino puisse modifier, ajouter ou soustraire des termes et conditions à tout moment et sans avertissement préalable, et il est de la responsabilité du joueur de vérifier périodiquement les conditions écrites concernant des changements susceptibles de les affecter. Le manque de connaissance desdits termes et conditions ne constitue pas une raison valable pour toute violation des règles et conditions énoncées ici, dès le moment de l'ouverture d'un nouveau compte dans le casino.",
                "1.3. Toutes les décisions prises par le Casino sont définitives et seront conformes aux termes et conditions énoncées dans le présent document. En s'inscrivant sur le Casino, le joueur s'engage à accepter toutes les décisions prises par le Casino comme définitives et décisives.",
                "1.4. Le Casino ne portera aucune responsabilité, qu'elle soit directe ou indirecte, en matière du droit du joueur à jouer sur un casino en ligne avec de l'argent réel. Ceci inclut tout les employés, licenciés, distributeurs, grossistes, sociétés affiliées, filiales, publicité, promotion ou autres agences, partenaires médias, agents ou détaillants prétendant ou ayant prétention à avoir l'autorisation de faire une quelconque déclaration ou garantie. Il est de la seule responsabilité du joueur de vérifier la législation locale et de se conformer de façon responsable. Le Casino ne portera aucune responsabilité pour toute violation de la loi commise par le joueur.",
                "1.5. Les jeux, les services ou les promotions offertes par le Casino sont purement destinés à des fins de divertissement, et non pas comme un système financier. Il existe une option pour jouer avec de faux crédits n'ayant aucune équivalence avec de l'argent réel. Jouer avec de l'argent réel relève de la seule décision du joueur, et, ce faisant, le joueur accepte pleinement les risques encourus en jouant de vrais crédits.",
                "1.6. Les impôts, taxes, frais de transfert ou autres responsabilités financières ou conséquences liées à l'utilisation des jeux et / ou services offerts par le Casino sont à la charge du joueur et du joueur seul.",
                "1.7. Le joueur ne tiendra pas le Casino, ni aucun organisme, employé, partenaire ou médias liés au Casino pour responsables des dommages, coûts, dépenses ou passifs encourus par l'utilisation et / ou l'acceptation des services du Casino ni pour toute activité du joueur liés au Casino.",
                "1.8. Le Casino n'assume aucune responsabilité pour les erreurs techniques survenues dans le logiciel liées à la connexion Internet du joueur, le logiciel de l'ordinateur personnel ou le matériel de l'ordinateur personnel. Les interruptions de service Internet sont la responsabilité du joueur et le Casino ne compensera pas, ni ne supportera aucune responsabilité pour les interruptions causées par un problème technique du côté du joueur, comprises mais non limitées à des déconnexions Internet. Les autres défauts techniques doivent être soumis par écrit et comprennent une capture d'écran de sorte que le Casino peut vérifier son authenticité.",
                "1.9. Le joueur accepte que l'identité enregistrée sur son compte de casino sera la seule personne qui se connecte sur ledit compte et l'utilise et / ou joue avec le logiciel ou site web Casino. Toute violation de ladite convention (c'est à dire l'utilisation du compte par une autre personne) sera considérée comme une violation des termes et conditions et donc comme motif de résiliation du compte et de la confiscation de tous les gains. Le compte n'est transférable en aucune façon, et le nom d'utilisateur ne peut être changé pour le compte. Les nouveaux utilisateurs doivent ouvrir un nouveau compte ou seront considérés comme violant les termes et conditions.",
                "1.10. Le Casino se réserve le droit de modifier, ajouter ou soustraire des jeux et / ou services qu'elle offre, à tout moment, sans préavis ou mise à jour antérieure. Les termes et conditions peuvent être modifiés et le joueur accepte cela comme faisant partie de la prérogative du site de le faire quand et si nécessaire.",
                "1.11. Le joueur s'engage en outre à indemniser sans contestation Casino " + casino.name + ", ses employés, dirigeants, administrateurs et tout associé ou personnes ou organisations contre tous les coûts, dépenses, dettes et dommages résultant de toute action juridique ou autre prises par ou contre le Joueur découlant de toute action sur le Casino et / ou un de ses employés, dirigeants, administrateurs et toute personne ou organisation associée ou affiliée. Cet événement doit inclure (sans s'y limiter) les conséquences juridiques qui pourraient survenir à la suite de la criminalisation des paris en ligne / sur Internet de la juridiction du pays ou territoire dont le joueur est issu.",
                "1.12. Le joueur accepte tout résultat de jeux présenté par le logiciel du Casino ou site web comme vrai. Tout litige concernant la résultante des jeux joués sera décidé par les résultats à partir du serveur de jeu, et ceux-ci détermineront la décision finale du Casino. Si pour une raison quelconque le joueur n'est pas totalement satisfait des termes et conditions inscrits, alors le joueur est en droit de cesser de jouer et supprimer le logiciel de son ordinateur. En outre, un joueur n'étant pas satisfait par son expérience de jeu personnel sur le Casino et souhaitant fermer son compte, sera porté responsable pour la fermeture ou le gel manuel dudit compte à partir du logiciel du Casino ainsi que de la suppression de tout logiciel associé au casino à partir de son ordinateur ou des appareils mobiles. Les restrictions juridiques d'âge et les règles strictes d'adhésions relatives a l'âge, comme convenu par la juridiction de ces dits territoires de se conformer à ladite loi de ce territoire sont pleinement appliquées par le Casino. Il est clairement défini qu'aucun joueur de moins de 25 ans n'est autorisé à jouer sur le casino sans distinction de sexe.",
                "1.13. Dans le cas d'une plainte, d'un grief, d'une réclamation ou d'un différend technique sur le Casino, la plainte doit être soumise par écrit dans les cinq (5) jours suivants l'événement. Si la plainte est reçue après ce moment, le casino se réserve le droit de ne pas aborder la question. Cependant, s'il existe des circonstances atténuantes; Par exemple: un joueur se retrouve dans l'incapacité ou indisponible pour commenter, les questions seront abordées au cas par cas, mais la direction se réserve le droit d'invoquer les cinq (5) jours de la règle ci-dessus.",
                "1.14. Le joueur accepte toutes les décisions prises par le Casino comme définitives.",
                "1.15. Tous les fonds restants dans le solde d'un compte au moment de la fermeture ne pourront être retirés. Cela comprend également les demandes de retraits en attente."]
        }, {
            title: "Violations des conditions générales d'utilisation",
            content: ["2.1. Les actions suivantes commises par le joueur seront considérées comme des violations des termes et conditions du Casino, (mais les violations ne se limitent pas à cette liste). Les conséquences peuvent inclure la résiliation du compte casino du joueur, la confiscation des gains et des équilibres existants, le déni de services, promotions et autres offres par le casino.",
                <ul>
                    <li>Plus d'un compte sur {casino.name} Online Casino et / ou plus d'un compte par ménage et /
                        ou plus d'un compte par dispositif et / ou plus d'un compte sur la même adresse IP et / ou plus
                        d'un compte sur un Casino étant sous la même gestion que {casino.name} Online Casino.
                    </li>
                    <li>Si à tout moment, le casino découvre un lien ou une connexion a un compte frauduleux, suspendu,
                        remboursé ou précédemment fermé sur n'importe quel casino relatif au réseau Rival ou tout autre
                        casino en ligne.
                    </li>
                    <li>Si à tout moment le casino découvre une plainte / litige / critique active ou passée sur un
                        forum public ou blog ou tout autre type de site Web.
                    </li>
                    <li>Si à tout moment le joueur abuse, harcèle ou parle de façon inappropriée à un membre du
                        personnel du Casino, incluant les menaces ou l'intimidation verbale et/ou écrite.
                    </li>
                    <li>Une discordance entre le nom inscrit sur le compte Casino du joueur et celui inscrit sur la/es
                        carte (s) de crédit ou méthodes de paiement utilisées pour effectuer des dépôts sur le compte.
                    </li>
                    <li>Des informations d'enregistrement incorrectes ou fausses.</li>
                    <li>Si le joueur joue d'un territoire ou d'une région n'étant pas entièrement autorisée pour le jeu
                        en ligne.
                    </li>
                    <li>Si le joueur n'a pas l'âge légal requis selon les Termes et Conditions du Casino.</li>
                    <li>Si le joueur a autorisé ou permis (intentionnellement ou non) a un autre joueur de jouer pour
                        son propre compte de Casino.
                    </li>
                    <li>Si une personne n'a pas joué sur le Casino dans un but de pur divertissement et a seulement
                        participé à titre professionnel ou en association avec un autre joueur d'un autre club ou d'un
                        groupe ou d'une partie de celui-ci.
                    </li>
                    <li>Si le joueur est accusé d'avoir délibérément fait un «chargeback» ou une «contestation» des
                        fonds déposés de leur carte de crédit, ou toute autre méthode de paiement, sur leur compte
                        Casino; ou accusé d'avoir fait un «chargeback» ou menacé de le faire à un autre titre sur un
                        autre casino de la plateforme Rival ou tout autre casino en ligne ou site web. Cela inclut les
                        menaces verbales et écrites pour contester, refacturation ou arrêt d'une transaction.
                    </li>
                    <li>Si le joueur est découvert en train de tricher ou accusé de paris irréguliers, ou de stratégies
                        de paris suspects ou irréguliers et ou si le joueur a été l'instigateur d'un système (telle que
                        la Martingale, l'utilisation de machines, ordinateurs, logiciels ou toute autre forme
                        d'automatisation) ou s'il est accusé de proliférer des méthodes de triches conçues pour que le
                        Casino perde des revenus en raison de cette opération.
                    </li>
                    <li>Si le joueur est lié à tout employé, ex-employé, entreprise, tiers ou organisme relié au casino
                        actuellement ou dans le passé.
                    </li>
                    <li>Si le joueur a fait toutes tentatives de hacking du logiciel de Casino ou site web, ou apporté
                        des modifications en aucune façon expressément autorisées par le Casino.
                    </li>
                </ul>,
                "2.2. Si le casino devait apprendre qu'une des décisions ci-dessus énumérées ait été affectée par le joueur à n'importe quel autre casino en ligne, Casino " + casino.name + " agira en conséquence.",
                "2.3. Dans l'acceptation desdites décisions et toutes autres conditions décrites ici, le joueur accepte tous les événements promotionnels ou publicitaires lors de l'acceptation d'un prix et / ou des gains mis en place sur Casino " + casino.name + " et ainsi souscrit à l'utilisation de l'identifiant du joueur, pays ou prénom réel pour ladite pratique sans aucune compensation ou de paiement pour le joueur. Exemple: Si un joueur gagne un grand Jackpot et si le Casino souhaite annoncer la victoire, le Casino se réserve le droit d'utiliser et de poster le nom d'utilisateur, le pays du joueur et / ou vrai prénom sur le site de Casino, magazine, autre site ou tout autre moyen choisi par le Casino."]
        }, {
            title: "Conditions et termes généraux",
            content: ["Nous pouvons imposer des limites ou des conditions que nous, dans notre seule discrétion, estimons appropriées à n'importe quelle personne qui ouvre ou essaye d'ouvrir un compte au Casino où les comptes en question proviennent d'environnements où les ordinateurs sont partagés.",
                "3.1. Le Casino s'engage à offrir des jeux équitables et honnêtes et confirme que le logiciel et tous les jeux proposés par Casino " + casino.name + " s'appuye sur un générateur de nombre aléatoire audité et validé (RNG) par un tiers veillant à ce que tous les jeux soient justes et honnêtes.",
                "3.2. Le Casino se réserve le droit de modifier et de réajuster la balance sur le compte d'un utilisateur des sommes dues au Casino.",
                "3.3. L'utilisateur s'engage à payer toutes les sommes dues au Casino " + casino.name + " et à ne jamais essayer de réattribuer, refuser, révoquer, contester, rétrofacturer, bloquer, maintenir ou annuler aucun de ces paiements.",
                "3.4. Si l'utilisateur a déposé ou retiré des fonds au moyen d'un porte-monnaie électronique tels que Moneybookers, Neteller ou EcoCard, le Casino peut demander des copies des autres transactions montrant que le porte-feuilles en ligne spécifique est en cours d'utilisation.",
                "3.5. Toute activité illégale ou criminelle faite par le joueur entraînera la résiliation immédiate du compte Casino et le Casino se réserve le droit d'informer les autorités compétentes et d'autres casinos en ligne ainsi que les commerçants de ces actions avec la divulgation complète.",
                "3.6. Le Casino se réserve le droit de modifier l'un de ses services offerts à tout moment, y compris une indisponibilité temporaire du support clientèle, du support technique ou d'autres services offerts au joueur.",
                "3.7. En cas de défaillance technique ou problème informatique, tous les paris en jeu peuvent être considérés comme nuls et non avenus. Le Casino ne peut être tenu responsable des pertes, victoires ou autres problèmes générés par une défaillance technique ou d'une panne de quelque manière que ce soit.",
                "3.8. Si un joueur s'abstient de se connecter à son compte Casino sur une période de 90 jours ou plus ou si le joueur se connecte sans faire un seul pari ou mise sur une période de 90 jours ou plus, le Casino aura droit à tout solde sur le compte dudit joueur et la somme forfaitaire. Si le joueur souhaite retirer cette somme après cette période, le Casino examinera chaque situation au cas par cas, mais se réserve le droit d'invoquer la règle des 3 mois ci-dessus."]
        }, {
            title: "Conditions des promotions & bonus",
            content: ["4.1. Aucun bonus gratuit et bonus sur dépôt ne peut être retiré du Casino, et sont destinés à améliorer le temps de jeu seulement. En cas de retrait, les montants de bonus seront déduits par le Casino avant l'effectuation et l'envoi du retrait.",
                "4.2. Tout l'argent des bonus devra être retiré au moment de l'encaissement, l'argent des bonus est de l'argent qui peut être utilisé afin d' aider à générer des gains, mais ne peut pas être retiré dans le cadre des gains. Le bonus sera considéré comme «non-retirable» et ne sera pas réédité à votre compte, mais déduit des gains par le gestionnaire des retraits au cours du processus de paiement. L'argent des Cashback est également «non retirable» et le casino se réserve le droit de déduire les cashback du/es retrait/s en attente du joueur.",
                "4.3. Les bonus, sauf spécifiquement indiqué par le Casino au préalable, sont contraints par une exigence de mises devant être remplie avant qu'un joueur ne puisse retirer.",
                "4.4. Toutes les promotions cashback sont contraintes par une exigence de mise d'1 fois. Les joueurs ne peuvent pas retirer un bonus cashback jusqu'à ce que la somme du cashback soit misée en entier. Les cashback ont une limite de retrait maximum de 10 fois la quantité du montant de cashback crédité. Cela comprend les cashback récompensés comme «cadeaux», les «points comps» ou les «jetons gratuits». Le Casino se réserve le droit de déduire le Cashback d'un retrait en attente.",
                "4.5. Tous les prix de tournoi / loterie / jeu ont une exigence de mise d'une fois (1x). Le Casino se réserve le droit de déduire le montant du bonus / cashback spécial d'un retrait en attente. Les joueurs ayant un retrait en attente ou ayant une balance supérieure ou égale a 100 $ / € / £ / 1000 ZAR ne sont pas éligibles aux prix d'un tournoi / loterie / jeu.",
                "4.5.1 Bonus spécial – tout bonus de 200% ou plus, tout bonus cashback de 100% ou plus sera considéré comme bonus spécial et sera limité à un maximum de retrait de 3 fois le montant du dépôt.",
                "4.6. Il est de la responsabilité du joueur de connaître les détails du bonus qu'il demande ou qui lui est donné, y compris l'exigence de mises, les jeux restreintes et d'autres détails, modalités et exigences associées avec ledit bonus.",
                "4.7. Les joueurs choisissant de jouer sans bonus, devront l'indiquer avant de commencer à jouer avec un bonus. Si un joueur ne veut pas du bonus qui a été déposé sur son compte par le casino, sera alors en droit de demander a un représentant du support clientèle du casino de supprimer le bonus de son compte de casino, via courriel, appel téléphonique ou chat en direct. Si un bonus est reçu et qu'un pari est fait (de tout montant), le bonus devra alors être joué dans son intégralité, jusqu'à ce que le solde soit nul ou que l'exigence de pari soit complète. Si le joueur n'a pas commencé à jouer, le support pourra alors supprimer le dit bonus.",
                "4.8. Les bonus gratuits (Points comps, jetons gratuits) sont des bonus jouables attribués a un joueur afin qu'il puisse jouer gratuitement. Les bonus gratuits ont une exigence de mises différentes des autres bonus, et ont une limite de retrait fixe de pas plus de 100 Crédits (100 $, €, £, AUD ou 1 000 ZAR). Tout solde restant ou extra est considéré comme «non retirable »et sera effacé de la balance du joueur. Chaque joueur est autorisé à retirer les gains d'un bonus gratuit qu'une seule fois au cours de son / sa vie de jeu sauf si spécifiquement approuvé par le directeur du casino.",
                "4.9. Un joueur qui n'a jamais fait un dépôt et souhaite retirer un gain issue d'un bonus gratuit, doit tout d'abord faire un dépôt minimum de 25,00 ($, €, £, AUD) ou de ZAR. Ce premier dépôt n'est pas autorisé à être retiré ou ajouté à un existant retrait en attente. S'il est ajouté à un prélèvement existant, le montant sera déduit du montant de retrait total. Un joueur ayant déjà effectué un dépôt et maintenant désireux de retirer Un gain issu d'un bonus gratuit, doit au préalable avoir déposé dans les 30 derniers jours pour que le retrait soit approuvé et le dépôt ne peut pas être ajouté à un existant retrait en attente. S'il est ajouté à un retrait en attente, le montant sera déduit du montant de retrait total. Les bonus gratuits (jetons gratuits) ont une exigence de mise de 20X à 60X, sauf indication contraire.",
                "4.10. Toutes les promotions offertes par le Casino sont valables uniquement durant la période indiquée sur l'offre promotionnelle elle-même affichée sur le site web du Casino et / ou toute autre communication prévue par le Casino lui-même."]
        }, {
            title: "Termes & conditions - retraits",
            content: ["5.1. Tous les dépôts de 249 USD / EURO / GBP / AUS ou moins ont un retrait maximum de 10X le dépôt de cette session spécifique de jeu. Une fois un retrait approuvé, le casino applique la règle du 10X et corrige le retrait pour refléter fidèlement la règle du 10X. Les fonds restants sont considérés comme «non-retirables» et sont effacés de la balance du joueur. Les bonus sur dépôt utilisés au cours d'une session de jeu et se traduisant par une demande de retrait, sont également «non retirables» et pourront être déduits de ce retrait en attente.",
                "5.2. Les joueurs peuvent soumettre leur demande de retrait à tout moment. Le délai d'approbation peut prendre de un à dix-huit (1-18) jours ouvrables à compter de la date d'autorisation correspondant au moment où le processus a été achevé avec succès. Le traitement d'un retrait en attente nécessite une autorisation du département des fraudes.",
                "5.3. Aucun bonus activé par le joueur ne peut être retiré du Casino ou échangé, et sera donc annulé des gains du joueur lors du traitement du retrait.",

                "5.4. Afin qu'un retrait soit approuvé, le joueur aura à soumettre certains documents et informations afin de vérifier son compte. Le non-respect ou omission de fournir lesdits documents et les informations requises sous 30 jours après que retrait ait été demandé, se traduira par l'annulation du retrait en cours. Il est de la responsabilité du joueur d'envoyer la documentation requise. L'approbation du retrait dépend ainsi de toutes les conditions décrites et la vérification de tous les documents requis.",
                "5.5. Un joueur ne peut pas retirer les gains tant qu'il est encore soumis à une exigence de mises attaché à sa balance. Il doit remplir les conditions de mises avant de pouvoir effectuer une demande de retrait, au risque de voir la demande de retrait refusée.",
                "5.6. Le casino se réserve le droit de refuser ou d'annuler un bonus ou des gains dans le cas où un dépôt est annulé, retourné ou refusé par un service de paiement ou un processeur à tout moment.",
                "5.7. Si le joueur a misé sur des jeux restreints avec un bonus n'autorisant un de ces jeux, le Casino se réserve le droit d'annuler tout retrait et de confisquer tous les gains. Avant de commencer à jouer avec un bonus sur dépôt ou un bonus gratuit, il est de la responsabilité du joueur de vérifier et de comprendre quelles sont les restrictions de ce bonus.",
                "5.8. Le montant minimum pour un retrait est de 100 € / $ / £ / AUD ou 1 000 ZAR.",
                "5.9. Tous les dépôts doivent être misés au moins une fois (1X) le montant du dépôt afin de se qualifier pour un retrait. Un retrait ne peut être approuvé que si le dépôt a été misé au moins 1 fois au préalable. Les dépôts effectués dans le cadre de promotions sont soumis aux Termes et Conditions de cette promotion spécifique et à l'exigence de mise y correspondant.",
                "5.10. Points de fidélité (comp points): ont une limite de retrait de 10 fois le montant converti avec une limite maximale de 300 € / $ / £ / AUD. Les fonds restants sont considérés comme «non-retirables» et sont effacés de la balance du joueur.",
                "5.11. Prix de tournoi / loterie / jeu: ont une limite de retrait de 2 fois maximum du montant reçu. Les fonds restants sont considérés comme «non-retirables» et seront supprimés de la balance du joueur."]
        }, {
            title: "Accord de confidentialité",
            content: [<>Décharge:<br/>6.1. Le Casino est tenu de garder tous les renseignements personnels donnés par le
                joueur de façon sûre et sécurisée. Tous les détails soumis par le joueur au Casino à tout moment, sont
                confidentiels et ne seront remis à aucun tiers ou organisme.</>,
                "6.2. Il est de la responsabilité du joueur seul d'assurer la sécurité de tous les noms d'utilisateur et mots de passe liés à leur compte casino. Le Casino ne portera aucune responsabilité pour toute utilisation des informations de compte envoyées par une personne n'étant pas le joueur à l'origine enregistré.",
                "6.3. Le Casino se réserve le droit d'utiliser le nom du joueur dans le cadre d'événements promotionnels (par exemple, la publication d'un gros gain par le joueur), sans aucune compensation ou paiement effectué au dit joueur et ne sera pas tenu de demander une approbation préalable par le joueur.",
                "6.4. Les termes et conditions stipulés sur ce document définissent un accord final et exclusif entre vous (le joueur) et le Casino et complètent tous les précédents accords, déclarations et ententes entre vous et Casino " + casino.name + " en rapport avec votre participation sur le Casino.",
                "6.5. Le Casino se réserve le droit de refuser un dépôt ou de retirer des crédits ou des bonus à notre discrétion. Le Casino se réserve le droit de retirer ou de modifier les termes de ce programme à un moment donné ou sans préavis.",
                "6.6. Cette offre ne est pas applicable pour les employés, partenaires, fournisseurs ou toute autre partie liée a l'entreprise et / ou relation professionnelle du Casino " + casino.name + ".",
                <>Propriété intellectuelle: <br/>6.7. Via ce contrat, Le Casino accorde à l'utilisateur un droit non
                    transférable, révocable et non exclusive d'utiliser son site Web, son logiciel et / ou ses services
                    ainsi que tous les services liés. Ce droit s'étend également aux royalties et tous les droits de
                    propriété intellectuelle étant attachés à elle.</>,
                "6.8. L'utilisateur reconnaît et accepte que le droit d'auteur, la marque et tous les autres droits de propriété intellectuelle exercés sur tout matériel et / ou contenus fournis et appartenant au site Web et / ou au logiciel du casino, reste notre propriété stricte. L'utilisateur peut utiliser le matériel mentionné ci-dessus qu'en accord avec ces termes et conditions.",
                "6.9. Casino " + casino.name + " est le nom commercial du Casino, et le joueur n'a pas le droit d'utiliser ou d'employer un tel terme, ou tout autre terme, graphique, texte, concept ou méthodologie.",
                "6.10. L'utilisateur reconnaît et accepte le fait que le matériel et le contenu reproduits sur le site du Casino sont mis à leur disposition à des titres non commerciaux uniquement. Ce matériel et ces contenus ne doivent être téléchargés sur le disque dur d'un ordinateur que dans le but de servir cet objectif. Si l'un des jeux du casino sont interférés, mutilés, contrefaits ou endommagés alors ils deviennent nuls et inutilisables. Indépendamment de la façon dont ils sont obtenus que ce soit physiquement ou par voie électronique. La responsabilité matérielle des jeux du casino comprenant des erreurs est seulement limitée à un remplacement du dit matériel.",
                "6.11. Toute autre utilisation, telle que la copie, la falsification, la reproduction, la publication, la transmission et la répartition du travail dérivé de ce matériel et de ces contenus, est strictement interdite.",
                "6.12. Des actions criminelles ou suspectes possibles peuvent être transmises aux autorités compétentes. Si le cas de figure se présente, le joueur aura alors à fournir certains documents de vérification lors des transactions faites sur le casino - retraits ou dépôts. Les documents d'identification requis pour un tel cas sont une photo d'identité claire, un justificatif de domicile, des copies des cartes de crédit utilisées sur le casino et les formulaires de déclaration d'achat. Cette exigence est nécessaire pour permettre la sécurité de nos joueurs et est conforme aux règles régies par les régulations de jeux.",
                "6.13. Ce contrat est considéré comme dûment signé et accepté par les deux parties: Le Casino et l'utilisateur.",
                "6.14. Les termes et conditions stipulés définissent une compréhension et un accord final et exclusif entre vous (le joueur) et Casino " + casino.name + " et complètent tous les précédents accords, déclarations et ententes entre vous et Casino " + casino.name + " en rapport avec votre participation au Casino."]
        }, {
            title: "Parrainer un ami termes et conditions",
            content: ["7.1. Les termes et conditions généraux sont appliqués au programme 'Parrainer un ami' et tous les autres programmes offerts par Casino " + casino.name + ". En soumettant toutes les informations requises dans le programme 'Parrainer un ami', vous et la partie parrainée (personne étant parrainée) consentent à tous les termes de ce programme et la partie parrainée consent a ce que ses données soient transmises par son 'parrain'. En soumettant ces informations, la partie parrainée consent à recevoir un courriel/mail envoyé à lui / elle par Casino " + casino.name + " lui demandant d'ouvrir un compte réel. En soumettant des informations dans le programme 'Parrainer un ami', la partie parrainée est pleinement consciente que son 'parrain' a effectivement soumis ses données en toute bonne foi.",
                "7.2. Les personnes utilisant le même ordinateur (enregistrement à partir de la même adresse IP) ne seront pas autorisées à prendre part au bonus de parrainage pour des raisons de sécurité. Les joueurs parrainés ne doivent pas être détenteur d'un compte sur Casino " + casino.name + " Casino. L'adresse e-mail fournie par la partie parrainant ne doit être associée à aucun joueur actuel sur Casino " + casino.name + " et ne doit pas être le même email que le membre parrainant.",
                "7.3. Les 'parrains' seront tenus de remplir des informations spécifiques à propos de leur filleul/e, cette information inclut mais ne se limite pas à leur nom, prénom et adresse e-mail donnée. Une fois que toutes les informations requises auront été fournies, soumises et vérifiées par Casino " + casino.name + ", alors seulement le bonus pourra être fourni à la fois au parrain et filleul.",
                "7.4. Afin de bénéficier du bonus de parrainage, le filleul doit d'abord ouvrir un compte en argent réel et effectuer un dépôt minimum de 25 $, €, £ sur ce compte. Le parrain et le filleul recevront leur bonus uniquement après que le filleul ait déposé 25 $, €, £ sur son compte joueur puis après un délai de 48 heures, chaque partie recevra le bonus sur son compte respectif.",
                "Merci de noter que la version française des conditions générales n'a aucune valeur juridique et est une simple traduction de la version anglaise officielle et originale des termes et conditions du casino produite par courtoisie pour nos joueurs francophones."]
        }, {
            title: "Politique de remboursement",
            content: ["Merci d'avoir ouvert un compte chez nous et d'y avoir fait un dépôt sur nos sites Web.",
                "Nous offrons la possibilité d'un remboursement sur tous les dépôts effectués sur nos sites Web. Cependant, certaines conditions doivent être remplies. Nous vous invitons à lire les conditions expliquées plus en détail ci-dessous, afin de voir la condition qui s'applique à votre situation, tout en suivant la bonne procédure.",
                "Notre politique de retour est simple, et sera traité immédiatement.",
                "Cependant, il y a un délai limité comme indiqué:",
                <ul>
                    <li>Dans les 24 heures suivant le dépôt initial.</li>
                    <li>Au besoin au moment de l'ouverture du compte, la conformité requise au moment de l'ouverture du
                        compte doit être complétée et approuvée par notre service de conformité.
                    </li>
                    <li>Dysfonctionnement des jeux - Capture d'écran requise.</li>
                    <li>Impossible de jouer les crédits - Solde non joué.</li>
                    <li>Frais bancaires ou prélèvement injustifié - relevé bancaire requis.</li>
                </ul>,
                <>Frais de traitement<br/>Il y a des frais impliqués dans le traitement et l'émission de votre remboursement. Les différents frais (dans votre devise) sont les suivantes:</>,
                <ul>
                    <li>Chèque papier - 55,00</li>
                    <li>Fil SEPA - 20,00</li>
                    <li>Fil SWIFT - 75,00</li>
                </ul>,
                "Remboursements tardifs ou manquants (le cas échéant):",
                <ul>
                    <li>Si vous n'avez pas encore reçu votre remboursement, vérifiez à nouveau votre compte bancaire.
                    </li>
                    <li>Ensuite, contactez votre société de carte de crédit, cela peut prendre un certain temps avant
                        que votre remboursement ne soit officiellement affiché.
                    </li>
                    <li>Si vous avez fait tout cela et que vous n'avez toujours pas reçu votre remboursement, veuillez
                        nous contacter à {casino.email}.
                    </li>
                </ul>]
        }]
    }, "de": {

            title: "Allgemeine Geschäftsbedingungen",
            "content": [],
        sections: [{
            title: "Deinitionn",
            content: [
                <>Der Spieler:<br/>Alle Nutzer der Software und/ oder Spiele und/ oder Promotionen, die von der
                    Spielbank angeboten werden.</>,
                <>Das Spielbank:<br/>{casino.name} eingeschlossen jeglichem Softwareinhalt sowie dem Service, der
                    auf www.truefortune.com geleistet wird und alle zugehörigen URLs.</>,
                <>Service:<br/>Service, Hilfe, Angebote und Assistenz, die die Spielbank den Nutzern anbietet.</>,
                <>Kredite:<br/>Menge an Geld im Kontostand des Nutzers; Menge an Spiel- Chips/ Geld, welches zur
                    Verfügung steht. 1 Kredit = 1€,1£,1$,1AUD,10ZAR (annähernd).</>,
                <>Wir/Uns/Unser:<br/>Die Operatoren der Spielbank Website und/ oder seine Konzernunternehmen;</>,
                <>Spieler:<br/>Spieler und/ oder ein Gastspieler und/ oder Ruhende Spieler und/ oder geschlossene
                    Spielerkonten;</>,
                <>Echter Spieler:<br/>Ein Spieler, der sein eigenes Geld für Wetten in der Spielbank verwendet;</>,
                <>Umsatzanforderung:<br/>Die Anzahl, die eine Summe von Krediten gewettet, eingesetzt oder umgesetzt
                    werden muss, bevor der Kontostand ausgezahlt werden kann. Dieser Begriff ist auch als
                    Umsatzbedingung bekannt.</>]
        }, {
            title: "Haftungsausschluss",
            content: ["Sobald Sie in der Spielbank entweder zum Spaß oder als echter Spieler spielen, besteht zwischen dem Spieler und der Spielbank eine Vereinbarung, an welche der Spieler mit folgenden AGB´s gebunden ist. Wenn Sie sich in unserer Spielbank registrieren, ein Konto eröffnen und/ oder bei " + casino.name + " spielen, stimmt der Spieler hiermit zu, dass er:",
                <ol>
                    <li> Die folgenden AGB´s vollständig gelesen und verstanden hat und</li>
                    <li>Die AGB´s vollkommen akzeptiert hat und diese bei allen Handlungen mit der Spielbank und/ oder
                        der Spielbank Software befolgen wird.
                    </li>
                </ol>,
                "Falls sich ein Absatz als nichtig, ungültig oder nicht durchsetzbar herausstellt, bleiben die restlichen Bestimmungen unverändert und voll anwendbar. Die nachstehenden Bedingungen ersetzen und überschreiben alle anderen Bedingungen, die von der Spielbank angeboten werden.",
                "Die Spielbank ist durch die Regierung von Curacao für den Betrieb einer Online Spielbank registriert, lizensiert und geregelt."]
        }, {
            title: "Allgemeine rahmenbedingungen",
            content: ["1.1. Das Spielen in " + casino.name + " ist Erwachsenen gestattet. Spieler müssen für das Spielen 25 Jahre oder alter sein. Die Altersbegrenzung kann sich je nach der Jurisdiktion Ihres Landes unterscheiden und es liegt in Ihrer Verantwortung das erlaubte Mindestalter für Ihr Land zu überprüfen. Die Spielbank behält sich vor, zu jeder Zeit einen Altersnachweis vom Spieler zu verlangen.",
                "1.2. DDer Spieler akzeptiert, dass die Spielbank die AGB´s jederzeit ohne vorherige Warnung ändern oder entfernen kann und es ist liegt in der Verantwortung des Spielers regelmäßig die hier geschriebenen AGB´s zu lesen und auf eventuell zutreffende Änderungen zu prüfen. Fehlende Kenntnisnahme der festgelegten AGB´s stellt keinen gültigen Grund für eine Verletzung der Regeln und Bedingungen, die hier ab dem Zeitpunkt der Eröffnung eines neuen Kontos hier festgelegt wurden, dar.",
                "1.3. Alle Entscheidungen, die die Spielbank trifft, sind endgültig und entsprechen den hier festgelegten AGB´s. Durch die Registrierung in der Spielbank stimmt der Spieler zu und akzeptiert, dass jegliche Entscheidungen, die " + casino.name + " trifft, endgültig und bestimmend sind.",
                "1.4. Die Spielbank übernimmt keine Haftung oder Verantwortung für das Recht des Spielers mit echtem Geld in der Online Spielbank zu spielen, weder direkt noch indirekt. Dies beinhaltet alle Angestellten, Lizenzgeber, Vertreiber, Großhändler, Konzerngesellschaften, Tochtergesellschaften, Werbung, Aktionen oder andere Agenturen, Medienpartner, Agenten, Einzelhändler, die eine Berechtigung über solch Darstellungen und Garantien reklamieren oder angeben. Es liegt in der alleinigen Verantwortung des Spielers sich über die örtlichen Gesetze zu informieren und diesen Folge zu leisten. Die Spielbank übernimmt keine Haftung, wenn ein Spieler das Gesetz verletzt.",
                "1.5. Alle Spiele, Dienstleistungen oder Werbeaktionen, die von der Spielbank angeboten werden sind ausschließlich zum Zwecke der Unterhaltung und nicht als Finanzsystem gedacht. Es besteht die Option mit unechten Krediten zu spielen, welches echtem Geld nicht gleichgestellt ist. Das Spielen um echtes Geld ist die einzige Entscheidung des Spielers und somit akzeptiert der Spieler das volle Risiko, das durch das Spielen echten Krediten entsteht.",
                "1.6. Jegliche Abgaben, Steuern, Überweisungsgebühren oder andere finanzielle Verpflichtungen oder Auswirkungen, die durch die Verwendung der von der Spielbank angebotenen Spiele und / oder Dienstleistungen entstehen, liegen in der alleinigen Verantwortung des Spielers.",
                "1.7. Der Spieler wird weder die Spielbank noch eine andere Agentur, Mitarbeiter, Partner oder mit der Spielbank verbundene Medien für Schäden, Kosten, Aufwendungen oder Verbindlichkeiten, die durch die Verwendung und / oder Annahme von Dienstleistungen der Spielbank oder für jede Aktivität des Spielers in der Spielbank verantwortlich halten.",
                "1.8. Die Spielbank trägt keine Verantwortung oder Haftung für technische Fehler in der Software, die im Zusammenhang mit der Internet-Verbindung des Spielers auftreten. Jegliche Unterbrechungen der Internet- Verbindung liegen in der Verantwortung des Spielers und die Spielbank ist hierfür nicht haftbar und kommt für keine Entschädigung auf. Alle anderen technischen Mängel müssen schriftlich eingereicht werden und ein Bildschirmabbild enthalten, sodass die Spielbank dessen Echtheit überprüfen kann.",
                "1.9. Der Spieler akzeptiert hiermit, dass die in der Spielbank- Konto registrierte Identität die einzige Person ist, der das Anmelden und die Verwendung der Spielbank -Software oder der Website gestattet ist. Ein Verstoß gegen die Vereinbarung (d.h. Nutzung des Kontos von einer anderen Person) wird als Verstoß gegen die AGB´s gewertet und berechtigt zur Kündigung der Mitgliedschaft und Erlöschen aller Gewinne. Das Konto ist in keiner Weise übertragbar, noch kann der Benutzer für das Konto geändert werden. Alle neuen Benutzer müssen ein neues Konto eröffnen oder verletzen die Geschäftsbedingungen.",
                "1.10. Die Spielbank behält sich das Recht auf Änderung, Addition oder Subtraktion der Spiele und/ oder Dienstleistungen, die es bietet, jederzeit ohne vorherige Ankündigung oder Aktualisierungen. Allgemeine Geschäftsbedingungen können geändert oder erneuert werden und der Spieler akzeptiert diese Befugnis im Rahmen der Website, falls erforderlich.",
                "1.11. Der Spieler erklärt sich weiterhin bereit, " + casino.name + ", seine Mitarbeiter, leitende Angestellte, Direktoren und sämtlichen damit verbundene oder angeschlossene Personen oder Organisationen, für alle Kosten, Ausgaben, Verbindlichkeiten und Schäden, die aus rechtlichen oder anderen Maßnahmen entweder durch oder gegen den Spieler vorgenommen wurden, zu entschädigen. Dieser Fall umfasst (ist aber nicht beschränkt auf) alle rechtlichen Auswirkungen, die als Folge der Kriminalisierung von Online / Internet -Wetten innerhalb der Jurisdiktion des Rechtsstandort, Gebiet oder Land des Spielers zutreffen können.",
                "1.12. Der Spieler akzeptiert jegliche und alle Spielergebnisse, die von der Spielbank- Software oder der Website als wahr dargestellt werden. Alle Streitigkeiten über die Ergebnisse der gespielten Spiele werden von dem Spiel-Server entschieden und diese werden die endgültige Entscheidung der Spielbank bestimmen. Wenn der Spieler aus irgendeinem Grund nicht mit den hier festgelegten Bedingungen vollständig einverstanden ist, so hat der Spieler das Recht, das Spielen zu unterbrechen und die Software von seinem / ihrem Computer zu entfernen. Sollte ein Spieler weiterhin mit seinem / ihrem persönlichen Spielerlebnis in der Spielbank unzufrieden sein und wünscht das Konto zu schließen, trägt der Spieler die Verantwortung für das manuelle Schließen oder Einfrieren des Kontos innerhalb der Spielbank-Software sowie für das Entfernen aller Software, die mit der Spielbank vom eigenen Computer oder mobilen Geräten verbunden ist. Gesetzliche Altersbeschränkungen und strenge Einhaltung der Altersbegrenzung, wie in der Gerichtsbarkeit der genannten Jurisdiktion zugestimmt, werden vollständig von der Spielbank durchgesetzt. Dies wird deutlich durch die Altersbegrenzung von 25 Jahren, welche Spielern erlaubt unabhängig vom Geschlecht in der Spielbank zu spielen.",
                "1.13. Im Falle einer Beschwerde oder Klage oder technischen Beanstandung oder Streitigkeiten in der Spielbank muss die Beschwerde schriftlich innerhalb von fünf (5) Tagen nach dem Ereignis eingereicht werden. Trifft die Beschwerde nach diesem Zeitpunkt ein, behält sich die Spielbank das Recht vor, sich mit der Angelegenheit zu befassen. Können jedoch mildernde Umstände einbezogen werden; Beispiel: Ein Spieler ist arbeitsunfähig oder sonst für eine Aussage nicht verfügbar, werden wir diese Angelegenheit als einen Einzelfall behandeln, aber das Recht vorbehalten, die oben genannte fünf (5) Tage-Regel abzurufen.",
                "1.14. Der Spieler akzeptiert, dass alle von der Spielbank getroffenen Entscheidungen endgültig und wirksam sind.",
                "1.15. Alle Mittel in der Bilanz eines Kontos zum Zeitpunkt der Schließung können nicht ausgezahlt werden. Dies beinhaltet alle ausstehenden Auszahlungen."]
        }, {
            title: "Verletzung der nutzungsbedingungen",
            content: ["2.1. Folgende Aktionen des Spielers werden als Verstoß gegen die allgemeinen Geschäftsbedingungen der Spielbank betrachtet, sind aber nicht auf diese Auflistung beschränkt. Mögliche Folgen können die Auflösung des Kontos des Spielers, die Beschlagnahme von Gewinnen und vorhandenen Guthaben, Verweigerung von Service, Bonussen und anderen Angeboten von der Spielbank sein.",
                <ul>
                    <li>Mehr als ein aktives Konto bei {casino.name} und/oder mehr als ein aktives Konto auf einer
                        verbundenen Schwesterseite, die von der Rival Plattform betrieben wird.
                    </li>
                    <li>Sollte die Spielbank an einem beliebigen Zeitpunkt eine Verbindung oder Anschluss zu einem
                        betrügerischen, gesperrten, ersetzten oder bereits geschlossenen Konto in jedem Markt innerhalb
                        des Rival- Netzwerk oder jedem anderen Online-Spielbank entdecken.
                    </li>
                    <li>Sollte die Spielbank zu jeder beliebigen Zeit eine aktive oder frühere Beschwerde / Streit /
                        Kritik auf einem öffentlichen Forum oder Blog oder jeder anderen Art von Website entdecken.
                    </li>
                    <li>Eine Nichtübereinstimmung des Kontos des Spielers mit dem Namen auf der verwendeten Kreditkarte
                        (n) oder Zahlungsmethode (n), die für eine Einzahlung verwendet wurde (n).
                    </li>
                    <li>Fehlerhafte oder falsche Registrierungsinformationen.</li>
                    <li>Der Spieler spielt von einem Gebiet oder einer Region, in welcher das Spielen nicht gestattet
                        ist.
                    </li>
                    <li>Der Spieler hat das Mindestalter, welches von der Spielbank in den Nutzungsbedingungen
                        vorausgesetzt wird, noch nicht erreicht.
                    </li>
                    <li>Falls der Spieler (absichtlich oder nicht absichtlich) einem anderen Spieler erlaubt hat mit dem
                        eigenen Konto zu spielen.
                    </li>
                    <li>Wenn eine Person in der Spielbank nicht im Sinne der reinen Unterhaltung gespielt hat und das
                        Spielen ausschließlich als berufliche Tätigkeit nutzt oder der Spieler in Verbindung mit einem
                        anderen Spieler von einem anderen Verein oder Gruppe oder Teil davon steht.
                    </li>
                    <li>Wenn der Spieler absichtlich ein \"Chargeback\" oder \"Streit\" über Mittel aus dessen
                        Kreditkarte oder jede andere Zahlungsmethode für das Konto vollzieht; oder es hat sich
                        herausgestellt, dass ein \"Chargeback\" in Belastbarkeit einer Schwesterspielbank auf der Rival-
                        Plattform oder jeder anderen Online Spielbank Website vollzogen oder angedroht wurde. Dazu
                        gehören mündliche und schriftliche Drohungen und das Anfechten eines Chargeback oder dem
                        Chargeback oder das Abbrechen von Transaktionen.
                    </li>
                    <li>Wenn entdeckt wurde, dass der Spieler betrügt, oder festgestellt wurde, dass unregelmäßige
                        Wettmuster oder verdächtige oder ungewöhnliche Wettstrategien eingesetzt wurden oder der Spieler
                        nach einem System spielt, (d.h. Martingal, Maschinen, Computer, Software oder jede andere Form
                        der Automatisierung) um einen Betrug zu begehen, der für den Verlust der Einnahmen der Spielbank
                        aufgrund dieses Vorgangs entworfen wurde.
                    </li>
                    <li>Der Spieler mit einem Mitarbeiter, Ex-Mitarbeiter, Unternehmen, Dritten oder der verbundenen
                        Spielbank- Agentur in Beziehung steht oder stand.
                    </li>
                    <li>Der Spieler jeglichen Versuch eines \"Hack\" der Spielbank -Software oder einer Website
                        unternommen, oder Änderungen vorgenommen hat, die nicht ausdrücklich von der Spielbank erlaubt
                        wurden.
                    </li>
                </ul>,
                "2.2. Sollte die Spielbank erfahren, dass eine der oben genannten Regelungen vom Spieler in jeder anderen Web- basierten Spielbank verletzt wurden, wird " + casino.name + " entsprechend handeln.",
                "2.3. In Übereinstimmung mit den genannten Regelungen und allen sonstigen hier beschriebenen Bedingungen erklärt sich der Spieler bereit, alle akzeptierten Werbe- oder Werbeveranstaltungen und Annahme von Preisen und/oder Gewinnen, die von " + casino.name + " übertragen worden sind zu genehmigen und stimmt dabei dem Gebrauch von Benutzername , Land und Vornamen für den benannten Nutzen ohne Entschädigung oder Zahlung an den Spieler zu. Zum Beispiel: Sollte ein Spieler einen großen Hauptgewinn gewinnen und die Spielbank möchte den Sieg bewerben, so behält sich die Spielbank das Recht vor den Benutzernamen, das Land des Spielers und/oder den Vornamen auf der Spielbank –Website, einer Zeitschrift oder einer anderen Website sowie jede andere Quelle nach Wahl der Spielbank zu verwenden."]
        }, {
            title: "Allgemeine geschäftsbedingungen",
            content: ["3.1. Die Spielbank Website gestattet den Benutzern das Herunterladen, Installieren und den Gebrauch der Spiel- Software, um online zu spielen.",
                "3.2. Die Spielbank ist dazu verpflichtet die Spiele fair und ehrlich anzubieten und bestätigt, dass die Software und alle angebotenen Spiele von " + casino.name + " auf einem geprüften und validierten Random Number Generator (RNG) basieren und von Dritten sichergestellt wird, dass alle Spiele fair und ehrlich sind.",
                "3.3. Die Spielbank behält sich das Recht vor, das Guthaben auf dem Konto eines Benutzers zu ändern, um Beträge, die an die Spielbank geschuldet werden, einzufordern.",
                "3.4. Der Nutzer verpflichtet sich, alle geschuldeten Beträge an " + casino.name + " zu zahlen und nicht zu versuchen, diese Beträge wiederzuerlangen, zu verweigern, widerrufen, abzustreiten, zurückzuziehen, zu blocken, einzuhalten oder zu kündigen.",
                "3.5. Wenn der Benutzer unter Verwendung eines E -Wallet wie Moneybookers, Neteller oder EcoCard ein- oder ausgezahlt hat, kann die Spielbank Kopien von anderen Transaktionen verlangen, welchen den Gebrauch des bestimmten E-Wallet aufzeigt.",
                "3.6. Jegliche illegale oder kriminelle Aktivitäten , die der Spieler begeht, führen zur sofortigen Kündigung des Spielbank-Kontos und die Spielbank behält sich das Recht vor, die zuständigen Behörden und andere Spielbanken und Kaufleute über diese Maßnahmen mit vollständigen zu informieren.",
                "3.7. Die Spielbank behält sich das Recht vor, jegliche angebotenen Dienstleistungen jederzeit zu ändern, einschließlich eines vorübergehenden Serverausfalls von Kundenservice, technische Unterstützung oder andere Dienstleistungen, die dem Spieler gegeben sind.",
                "3.8. Im Falle einer technischen Störung oder einem Computerfehler können alle Einsätze im Spiel als null und nichtig gewertet werden. Die Spielbank kann nicht für Verluste, Gewinne oder andere Probleme, die durch einen technischen Defekt oder Störung in irgendeiner Form erzeugt wurden, verantwortlich gemacht werden.",
                "3.9. Sollte ein Spieler sie sich nicht in sein Konto für einen Zeitraum von 90 Tagen oder mehr einloggen oder der Spieler tätigt innerhalb von 90 Tagen keine einzige Wette, ist die Spielbank berechtigt eine Verpfändung des Guthabens in der Kasse des Spielers vorzunehmen. Sollte der Spieler wünschen, diesen Betrag nach Ablauf dieser Frist auszuzahlen, werden wir diese Situation als Einzelfall überprüfen aber das Recht vorbehalten uns auf die 3 -Monats-Regel zu berufen."]
        }, {
            title: "Nutzungsbedingungen für werbeaktionen und bonusse",
            content: ["4.1. Alle kostenlosen Bonusse und Einzahlungsbonusse können nicht von der Spielbank ausgezahlt werden. Bei einer Auszahlung werden die Bonusbeträge von der Spielbank einbehalten bevor die Auszahlung bearbeitet wird.",
                "4.2. Jegliches Bonusgeld wird zum Zeitpunkt einer Auszahlung entfernt, denn dies dient allein zur Hilfe den Gewinn zu erhöhen, aber kann nicht als Teil des Gewinns ausgezahlt werden. Der Bonus wird als \"Nicht- Auszahlbar\" behandelt und kann während des Auszahlungsprozesses Ihrem Auszahlungsbetrag nicht wieder angerechnet werden, sondern wird von dem Auszahlungsmanager abgezogen. Cashback- Geld ist ebenso \"Nicht- Auszahlbar\" und die Spielbank behält sich das Recht vor das Cashback- Geld von einer bestehenden Auszahlung abzuziehen.",
                "4.3. Bonusse, sofern nicht ausdrücklich durch die Spielbank vorher angegeben, sind an eine Wettanforderung gebunden, welche erfüllt werden muss bevor der Spieler eine Auszahlung tätigen kann.",
                "4.4. Alle Cashback-Aktionen haben eine Umsatzbedingung von 1X. Spieler können einen Cashback- Bonus nicht auszahlen bevor sich nicht der Betrag des Cashbacks vollständig umgesetzt wurde. Jegliches Cashback- Geld ist an einen maximalen Auszahlungsbetrag von 10X dem Cashback- Betrag begrenzt. Dazu gehören Cashback als \"Geschenke\", \"Comps\" oder \"kostenlose Chips\". Die Spielbank behält sich das Recht vor, Cashback Geld von einer anstehenden Auszahlung abziehen.",
                "4.5. Alle Cashback- Gelder, die als Turnier- Preis, in einer Lotterie oder in einer anderen speziellen Aktion gewonnen wurden, haben ein maximales Auszahlungslimit von 5X dem Cashback- Bonus und sind an eine Wettanforderung von 1X gebunden. Alle Bonusse von 200% oder mehr, Cashback- Versicherung von 75% oder mehr werden als Sonderaktionen gewertet. Die Spielbank behält sich das Recht vor, Cashback- Geld von einer Auszahlung in Bearbeitung abzuziehen. Spieler, die eine anstehende Auszahlung oder ein Guthaben von über 100 $ / € / £ / haben, können keine Turnierpreise, Bargeld von einem Gewinnspiel oder Cashback von einer laufenden Cashback- Aktion, die von unserem Werbe-Team angeboten wird, erhalten.",
                "4.5.1 Sonderbonusse - alle Bonusse in Höhe von 200% oder mehr sowie Cashback-Bonusse von 100% oder mehr werden als Sonderbonus gewertet.",
                "4.6. Es liegt in der Verantwortung des Spielers, sich über die Details des Bonus, den sie anfordern oder der ihm gegeben wurde, zu informieren, sowie die unerlaubten Spiele oder jede andere Bedingung und Anforderung zu kennen.",
                "4.7. Spieler, die ohne Bonusse spielen möchten, müssen dies angeben, bevor Sie beginnen mit einem Bonus zu spielen. Wenn ein Spieler den Bonus, der durch die Spielbank in die Kasse eingezahlt wurde, nicht annehmen möchte, ist er dazu berechtigt, den Bonus per Mail, Telefon oder Live Chat von einem unserer Kundenservice- Mitarbeiter entfernen zu lassen. Wenn ein Bonus angenommen wurde und eine Wette abgeschlossen wurde (in beliebiger Höhe), muss der Bonus vollständig erfüllt werden bis entweder der Kontostand bei null ist, oder die Wettanforderung erfüllt wurde. Wenn der Spieler noch nicht begonnen hat zu spielen, kann der Kundenservice diesen auf Wunsch entfernen.",
                "4.8. Kostenlose Bonusse (Comps, kostenlose Chips) sind Bonusse, die vergeben werden, damit Sie kostenlos Spaß haben können. Kostenlose Bonusse unterscheiden sich in den Wettbedingungen von den anderen Bonussen und sind an ein festes Auszahlungslimit von nicht mehr als 100 Krediten (100 $, €, £, AUD oder 1000 ZAR). Verbleibendes oder zusätzliches Guthaben ist \"Nicht- Auszahlbar\" und wird vom Kontostand eines Spielers entfernt. Jeder Spieler darf einen Gewinn, der von einem Bonus stammt, nur einmalig während seiner/ihrer Spielelebenszeit auszahlen lassen, außer ausdrücklich vom Spielbank Manager genehmigt.",
                "4.9. Ein Spieler, der nie eine Anzahlung getätigt hat und einen kostenlosen Chip auszahlen lassen möchte, muss zuerst eine erfolgreiche Einzahlung von mindestens 25,00 ($, €, £, AUD, oder ZAR) tätigen. Diese erste Einzahlung kann nicht zu einer bestehenden Auszahlung hinzugefügt oder davon entfernt werden. Falls die Summe einer bestehenden Auszahlung hinzugefügt wurde, wird der Betrag vom gesamten Auszahlungsbetrag abgezogen. Ein Spieler, der bereits eine erfolgreiche Einzahlung getätigt hat und nun einen kostenlosen Chip auszahlen lassen möchte, muss in den vorigen 30 Tagen eingezahlt haben, damit die Auszahlung bestätigt werden kann. Die Einzahlung kann nicht einer bestehenden Auszahlung in Bearbeitung hinzugefügt werden. Wenn zu einer bestehenden Auszahlung hinzugefügt, wird der Betrag vom Gesamtauszahlungsbetrag abgezogen werden. Kostenlose Bonusse (Freie Chips) sind an eine Umsatzbedingung von 20x bis 60x, sofern nicht anders angegeben, gebunden.",
                "4.10. Alle von der Spielbank angebotenen Sonderangebote sind nur in dem Werbeangebot festgelegten Zeitraum gültig, welcher auf der Spielbank- Website und/oder einer anderen Veröffentlichung der Spielbank zur Verfügung gestellt wird.",
                "Ein Bonus von 200% oder höher, der mit einem beliebigen Einzahlungsbetrag aktiviert wurde, unterliegt einer maximalen Auszahlung von 10x des Einzahlungsbetrags.",
                "4.12. Ein Gratisbonusspieler (ein Spieler, der spielt, ohne eine Einzahlung zu tätigen) kann Gewinne abheben, die durch die ersten fünf gratis Boni erzielt wurden. Nachdem fünf Boni gespielt wurden, wird erhaltenes Geld von einem kostenlosen Bonus als nicht-auszahlbar gewertet und vom Guthaben oder einer bestehenden Auszahlung abgezogen."]
        }, {
            title: "Auszahlungsbedingungen",
            content: ["5.1. Alle Einzahlungen von 249 USD / EURO / GBP / AUS oder weniger haben eine maximale Auszahlungssumme von 10X der Einzahlung für die jeweilige Spielsitzung (alle anderen Währungen sind an den USD gekoppelt). Sobald eine Auszahlung genehmigt wird, wendet die Spielbank die 10X –Regel an und korrigiert die Auszahlung, bis die 10X Regel genau reflektiert wird. Die restlichen Mittel werden als \"Nicht- Auszahlbar\" gewertet und werden aus dem Kontostand des Spielers gelöscht. Einzahlungsbonusse, die während einer Spielsitzung verwendet wurden, sind \"Nicht- Auszahlbar\" und werden von der einzelnen ausstehenden Auszahlung abgezogen.",
                "5.2. Kunden können ihren Auszahlungsantrag jederzeit vorlegen. Die Bearbeitungszeit kann zwischen fünfzehn und achtzehn (15 – 18) Werktagen dauern, bis der Autorisationsprozess erfolgreich abgeschlossen wurde. Die Bearbeitung einer anstehenden Auszahlung erfordert ebenso die Genehmigung der Betrugsabteilung.",
                "5.3. Sämtliche Bonusse, die vom Spieler eingelöst werden, können nicht ausgezahlt oder von der Spielbank eingelöst werden und werden vom Gewinn des Spielers bei der Bearbeitung der Auszahlung entfernt.",

                "5.4. Für die Genehmigung einer Auszahlung wird der Spieler aufgefordert bestimmte Unterlagen und Informationen zur Überprüfung des Kontos einzureichen. Bei Nichtbeachtung oder Nichtzusenden dieser Dokumente und Informationen kann die Auszahlung ab 30 Tagen nach der Auszahlungsanfrage storniert werden. Es liegt in der Verantwortung der einzelnen Spieler die erforderlichen Dokumente einzusenden. Die Auszahlungsgenehmigung hängt von den beschriebenen Bedingungen und der Prüfung aller erforderlichen Dokumente ab.",
                "5.5. Ein Spieler kann keine Gewinne auszahlen, solange dieser noch eine Wettanforderung an den Kontostand gebunden ist. Die Wettanforderungen müssen erfüllt worden sein bevor eine Auszahlungsanfrage gestellt werden kann oder es besteht das Risiko einer Verweigerung der Auszahlung.",
                "5.6. Die Spielbank hat das Recht Gewinne oder Bonusse zu verweigern oder zurückzuhalten, wenn eine Einzahlung storniert, zurückgegeben oder von einem Zahlungsdienstleister oder Prozessor verweigert wurde.",
                "5.7. Wenn der Spieler auf ein eingeschränktes Spiel mit einem Bonus, der nicht für das Spiel erlaubt ist, gewettet hat, behält sich die Spielbank das Recht vor, jede Auszahlung zu stornieren und alle Gewinne zu konfiszieren. Bevor ein Einzahlungsbonus oder ein Chip genutzt wird, ist es in der Verantwortung des Spielers, zu überprüfen und zu verstehen, wie die Einschränkungen des jeweiligen Bonus oder Chip festgelegt sind.",
                "De8Mindestbetrag für eine Auszahlung beträgt 100 € / $ / £ / AUD oder 1000 ZAR.",
                "5.9. Sämtliche Einzahlungen müssen mindestens einmal (1X) in der Summe des eingezahlten Betrags umgesetzt worden sein, um für eine Auszahlung qualifiziert zu sein. Eine Auszahlung kann nicht genehmigt werden wenn ein Mindesteinsatz von 1X der Einzahlung nicht erfüllt wurde. Einzahlungen, die gemeinsam mit Aktionen getätigt wurden, unterliegen den Geschäfts- und Wettbedingungen der spezifischen Werbeaktion."]
        }, {
            title: "Datenschutzvereinbarung",
            content: [<>Haftungsausschluss:<br/>6.1. Die Spielbank ist verpflichtet alle persönlichen Daten, die der
                Spieler angegeben hat, zu bewahren. Alle Daten, die der Spieler in der Spielbank zu jedem beliebigen
                Zeitpunkt angibt, werden allein bei der Spielbank bleiben und nicht an Dritte oder Agenturen
                weitergegeben werden.</>,
                "6.2. Es ist die alleinige Verantwortung des Spielers, alle Benutzernamen und Kennwörter, die mit dem Konto verbunden sind, zu sichern und geschützt zu halten. Die Spielbank übernimmt keine Haftung für die Verwendung der Kontodaten von einer Person, die nicht der ursprünglich eingetragene Spieler ist.",
                "6.3. Die Spielbank behält sich das Recht vor, den Namen des Spielers als Teil einer Werbeveranstaltungen (z.B. Veröffentlichung eines großen Gewinns durch den Spieler) ohne jede Entschädigung oder Zahlungen oder voriges Einverständnis des Spielers zu verwenden.",
                "6.4. Die hier dargelegten Allgemeinen Geschäftsbedingungen definieren eine endgültige und exklusive Vereinbarung und Übereinstimmung zwischen Ihnen (dem Spieler) und der Spielbank und vervollständigen alle vorherigen Vereinbarungen, Zusicherungen und Übereinkünfte zwischen Ihnen und " + casino.name + " im Hinblick auf die Teilnahme in der Spielbank.",
                "6.5. Die Spielbank behält sich das Recht vor, jegliche Kredite oder Bonusse zu jedem beliebigen Zeitpunkt ohne vorige Benachrichtigung zu verweigern, zurückzuziehen oder die Bedingungen im Programm zu ändern.",
                "6.6. Dieses Angebot gilt nicht für Mitarbeiter, Partner, Lieferanten oder andere Personen, die in einer geschäftlichen und/oder beruflichen Verbindung zu " + casino.name + " stehen.",
                "6.7. Durch diesen Vertrag räumt die Spielbank dem Benutzer ein widerrufliches, nicht ausschließliches und nicht übertragbares Recht, ein, die Website, die Software und/oder seine Dienste sowie alle verknüpften Dienstleistungen zu nutzen. Dieses Recht erstreckt sich auch auf die Lizenzgebühren und alle Rechte des damit verbundenen geistigen Eigentums.",
                "6.8. Der Benutzer erkennt an und akzeptiert, dass die Urheberrechte, die Marke und alle anderen Rechte des geistigen Eigentums, die durch beliebige Materialien dargestellt und/oder die zur Verfügung gestellten Inhalte und Eigentümer der Website und/oder Software der Spielbank in unsrem strengen Eigentum bleiben. Der Benutzer kann die oben genannten Materialien nur in Übereinstimmung mit diesen hier erwähnten Allgemeinen Geschäftsbedingungen verwenden.",
                "6.9. " + casino.name + " ist der Handelsname der Spielbank und der Spieler hat keine Rechte diesen zu nutzen oder jegliche anderen Begriff, Grafiken, Texte, Konzepte oder Methodiken die in der Website enthalten sind zu verwenden.",
                "6.10. Die Benutzer erkennt an und akzeptiert die Tatsache, dass die Materialien und die Inhalte, die auf der Website der Spielbank reproduziert werden, nur als nicht-kommerzielle Titel platziert und zur Verfügung gestellt werden. Dieses Material und Inhalte in Original sollen auf der Festplatte eines Computers heruntergeladen werden und nur zu diesem Zweck dienen. Wenn eines der Materialien der Spielbank-Spiele gestört, gefälscht oder beschädigt werden, sind sie unbrauchbar und nichtig. Unabhängig davon, ob diese physisch oder elektronisch enthalten sind. Material-Haftung der Spielbank-Spiele, die einen Fehler enthalten, ist nur auf den Ersatz des gleichen Materials beschränkt.",
                "6.11. Jede andere Verwendung ist streng verboten, auch als die Kopierung, Verfälschung, Vervielfältigung, Veröffentlichung, die Übertragung und die Verteilung der Arbeiten, die in diesem Inhalt dargestellt werden.",
                "6.12. Mögliche kriminelle oder verdächtige Handlungen werden den zuständigen Behörden gemeldet werden. Wenn aufgefordert, ist es möglich, dass Spieler gewisse Nachweisdokumente einreichen müssen, wenn Transaktionen an die Spielbank oder Auszahlungen getätigt werden. Solche Nachweise können ein klarer Bildausweis, Nachweis der Adresse, Kreditkartenkopien und Rechnungsbestätigungen bilden. Diese Anforderung ist notwendig, um die Sicherheit unserer Spieler in Übereinstimmung mit Spiel-Regelungen zu überprüfen.",
                "6.13. Dieser Vertrag gilt als ordnungsgemäß von beiden Parteien unterzeichnet und akzeptiert: die Spielbank und der Benutzer.",
                "6.14. Die dargelegten Allgemeinen Geschäftsbedingungen definieren eine endgültige und exklusive Übereinstimmung und Vereinbarung zwischen Ihnen (dem Spieler) und " + casino.name + " und vervollständigen alle vorherigen Vereinbarungen, Zusicherungen und Übereinkünfte zwischen Ihnen und " + casino.name + " im Hinblick auf die Teilnahme an der Spielbank."]
        }, {
            title: "Refer-a-friend geschäftsbedingungen",
            content: ["7.1. Die Allgemeinen Geschäftsbedingungen gelten für das Refer-a-Friend-Programm und alle anderen Programme, die von " + casino.name + " angeboten werden. Mit dem Einreichen aller erforderlichen Informationen in das Refer-a-Friend-Programm, sind Sie und die angeworbene Partei (Person, auf die verwiesen wurde) mit allen Bedingungen dieses Programms einverstanden und die angeworbene Partei ist damit einverstanden und stimmt zu, dass Ihre Daten von Ihnen an die bezugnehmende Partei übermittelten werden. Durch das Einreichen dieser Information ist die verwiesene Partei damit einverstanden E-Mail an ihn / sie von " + casino.name + " zu erhalten, welche sie/ihn auffordert sich für ein echtes Spieler-Konto anzumelden. Während Sie Informationen in das Refer-a-Friend-Programm übergeben, ist der angeworbenen Partei völlig bewusst, dass ihre Daten in gutem Glauben übermittelt werden.",
                "7.2. Personen, die den gleichen Computer verwenden (Registrierung von derselben IP-Adresse) wird nicht gestattet am Refer-a-Friend-Bonus aus Sicherheitsgründen zu profitieren. Angeworbene Spieler müssen nicht Mitglied von " + casino.name + " sein. Die E-Mail-Adresse der bezugnehmenden Partei darf nicht mit einem aktuellen Spieler von " + casino.name + " assoziiert werden noch soll sie die gleiche wie die E-Mail des Bezug-Mitglieds sein.",
                "7.3. Das empfehlende Mitglied wird verpflichtet spezifische Informationen über sein/ihr empfohlenen Freund anzugeben; diese Informationen beinhalten, ist aber nicht beschränkt auf den Vornamen, Nachname und E-Mail-Adresse. Nachdem alle erforderlichen Informationen zur Verfügung gestellt wurden und eine anschließende Überprüfung von " + casino.name + " durchgeführt wurde, kann der Bonus dem werbenden Mitglied und dem angeworbenen Mitglied gewährt werden.",
                "7.4. Um sich für den Refer-a-Friend-Bonus zu qualifizieren, muss der geworbene Teilnehmer zuerst ein Echtgeld-Konto eröffnen und eine Einzahlung von mindestens 100 $,€, £ auf dieses Konto tätigen. Sowohl der einladende Freund und der geworbene Freund werden ihren Bonus nur dann erhalten, nachdem der geworbene Freund eine Einzahlung von 100 $,€, £ getätigt hat und ein Zeitlimit von 48 Stunden nach der Einzahlung in das reale Spielerkonto erreicht worden ist."]
        }]
    }, "es": {

            title: "Promociónes Generales",
            content: ["Jugar en un casino, ya sea por diversión o por juego real, constituye un acuerdo entre el jugador, el casino y su administración, esto quiere decir que el jugador está sujeto a los términos y condiciones de este documento. El jugador acepta que este es un acuerdo entre él y el casino y es el único acuerdo que rige su relación, con exclusión de todos los demás acuerdos, declaraciones y representaciones.",
                "Cada cláusula contenida en estos términos y condiciones será separada de las demás. Si se determina que alguna cláusula es nula, inválida o inaplicable por cualquier motivo, los términos y condiciones restantes seguirán siendo de plena vigencia y efecto."],
        sections: [{
            title: "Definiciones",
            content: [
                <>El jugador:<br/>Cualquier usuario del sitio web o los juegos y/o promociones que ofrece el Casino.</>,
                <>El Casino:<br/>Casino {casino.name}, incluyendo todos los contenidos y servicios prestados en
                    www.truefortune.com y URLs asociados.</>,
                <>Servicio:<br/>Servicios, apoyo, ofertas y asistencia ofrecida por el casino para sus usuarios.</>,
                <>Créditos:<br/>Cantidad de dinero en saldo de la cuenta del usuario; Cantidad de fichas de juego /
                    dinero disponible para su uso. 1 crédito = 1 €, £ 1, 1 $, 1AUD, 10ZAR (aproximadamente).</>,
                <>Nosotros / Nos / Nuestro:<br/>Los operadores del sitio web del Casino y/o compañías de su grupo;</>,
                <>Jugador / es:<br/>Jugador y/o un Jugador Huésped y/o un Jugador inactivo y/o un Jugador Cuenta
                    cerrada;</>,
                <>Real Player:<br/>Un jugador que utiliza su propio dinero para hacer apuestas en el Casino;</>,
                <>Requisitos de apuestas:<br/>El número de veces que una suma de créditos tiene que ser apostado,
                    estacado o jugado antes de que el balance sea elegible para retirar. También conocido como requisito
                    de sustitución.</>]
        }, {
            title: "Descargo de responsabilidad",
            content: ["Jugar en el casino, ya sea para el juego de la diversión o por dinero real constituye un acuerdo entre el jugador y el casino que el jugador será sometido a los siguientes términos y condiciones contenidos en el presente documento. Al registrarse, abrir una cuenta y/o jugar en el casino " + casino.name + ", el jugador acepta que tienen:",
                <ol>
                    <li>Totalmente leído y comprendido los términos y condiciones escritos a continuación y</li>
                    <li>Ha aceptado plenamente los términos y condiciones y se cumplen en todas las relaciones con el
                        Casino.
                    </li>
                </ol>,
                "Si cualquier cláusula resulta ser nula, inválida o inaplicable por cualquier razón, los restantes términos y condiciones permanecerán de plena vigencia y efecto. Los términos y condiciones descritos en este documento sustituyen y sobrescriben cualquier otras condiciones ofrecidas por el Casino.",
                "El Casino se ha registrado, autorizado y regulado por el gobierno de Curacao con el propósito de operar un casino en línea."]
        }, {
            title: "Promociónes generales",
            content: ["1.1. Jugar en el casino " + casino.name + " se limita a sólo los adultos. Los jugadores deben tener 25 años de edad o más para jugar. Restricción de edad puede cambiar de acuerdo a la legislación nacional y local, y es la responsabilidad del jugador para comprobar la edad mínima permitida en su país. El Casino se reserva el derecho de exigir la verificación de edad desde el jugador en cualquier momento.",
                "1.2. El jugador acepta que el casino puede cambiar, añadir o restar de los términos y condiciones en cualquier momento sin previo aviso, y es responsabilidad del jugador de revisar periódicamente los términos y condiciones escritas por posibles cambios que puedan afectarles. La falta de conocimiento de dicho términos y condiciones no constituye una razón válida para cualquier violación de las normas y condiciones establecidas aquí, desde el momento de la apertura de una nueva cuenta en el casino.",
                "1.3. Todas las decisiones tomadas por el casino son finales, y cumplirán con los términos y condiciones establecidos en este documento. Al registrarse en el Casino, el jugador se compromete a aceptar cualquier y todas las decisiones tomadas por el casino como final y decisivo.",
                "1.4. El Casino se hará cargo de ninguna responsabilidad, ya sea directa o indirectamente, como en relación con el derecho legal del jugador para jugar en un casino online con dinero real. Esto incluye cualquier empleados, concesionarios, distribuidores, mayoristas, filiales, subsidiarias, publicidad, promoción u otras agencias, socios de los medios, agentes o distribuidores afirman ni se ha visto a reclamar la autorización para efectuar tales declaraciones o garantías. Es la única responsabilidad del jugador de revisar la legislación local y acatar de manera responsable. El Casino llevará ninguna responsabilidad por cualquier violación de la ley por parte del jugador.",
                "1.5. Cualquier juegos, servicios o promociones que ofrece el Casino pretendían meramente para fines de entretenimiento y no como un sistema financiero. Existe una opción para jugar con créditos falsos que no hacen dinero real igual. Jugar por dinero real es la única decisión del jugador, y al hacerlo, el jugador acepta plenamente los riesgos involucrados por jugar por créditos reales.",
                "1.6. Las tasas, impuestos, tasas de transferencia u otras responsabilidades financieras o consecuencias relacionadas con el uso de los juegos y/o servicios ofrecidos por el Casino son la responsabilidad del jugador y solo del jugador.",
                "1.7. El jugador no tendrá el Casino ni cualquier agencia, empleado, socio o medios relacionados con el Casino responsable de los daños, costos, gastos o pasivos generados por la utilización y/o aceptar servicios del Casino ni por cualquier actividad del jugador en relación con el Casino.",
                "1.8. El Casino no asumirá ninguna responsabilidad por cualquier error técnico que se producen en el software que están relacionados con la conexión a internet del jugador, software de computadora personal o hardware de computadoras personales. Cualquier interrupción en el servicio de Internet son responsabilidad del jugador y el casino no compensarán ni guardarás responsabilidad por interrupciones causadas por un problema técnico en el lado del jugador, incluyendo pero no limitado a las desconexiones de Internet. Los otros fallos técnicos deberán presentarse por escrito e incluir una captura de pantalla para que el casino puede verificar su autenticidad.",
                "1.9. El jugador acepta que la identidad registrada en su cuenta de casino será la única persona que entra en dicha cuenta y usar y/o jugar con el sitio web del casino. Cualquier infracción de dicho acuerdo (es decir, el uso de la cuenta por una persona diferente) será considerado una violación de los términos y condiciones y motivos para la terminación de la cuenta y el decomiso de las ganancias. La cuenta no es transferible de ninguna manera ni se puede cambiar el usuario de la cuenta. Todos los nuevos usuarios deben abrir una nueva cuenta o estar en violación de los términos y condiciones.",
                "1.10. El Casino se reserva el derecho de modificar, añadir o restar de los juegos y/o servicios que ofrece, en cualquier momento, sin previo aviso o actualización. Términos y condiciones pueden ser modificados o alterados y el jugador acepta esto como parte de la prerrogativa de la página web para hacerlo cuando y si es necesario.",
                "1.11. El jugador más indiscutiblemente se compromete a indemnizar Casino " + casino.name + ", sus empleados, funcionarios, directores y todas y cada una asociada o personas u organizaciones en contra de cualquier y todos los costos, gastos, daños y perjuicios derivados de cualquier acción legal o de otra índole adoptadas por o en contra de los afiliados jugador que surja de cualquier y toda acción dentro del Casino y/o cualquiera de sus empleados, funcionarios, directores y cualquier y todas las personas u organizaciones asociadas o afiliadas. Este hecho deberá incluir (pero no limitado a) cualquier implicación legal que pueda surgir como consecuencia de la criminalización de las apuestas en línea / Internet dentro de la jurisdicción jugadores, territorio o país.",
                "1.12. El jugador acepta cualquier y todos los resultados de los juegos mostrados por el sitio web como verdadero. Cualquier disputa relacionada con el resultado de los partidos disputados serán decididos por los resultados del servidor del juego, y éstos determinarán la decisión final del Casino. Si por alguna razón el jugador no está totalmente satisfecho con los términos y condiciones establecidos antes que ellos, entonces el jugador está en su derecho de suspender el juego. Legales restricciones de edad y adhesiones a las políticas estrictas de edad, según lo acordado por la jurisdicción de dichos territorios a cumplir con dicha legislación de ese territorio se completa ejecutadas por el Casino. Esto está claramente definido por ningún jugador menor de 25 años de edad se le permite jugar en el casino sin importar el género.",
                "1.13. En el caso de una queja o reclamación o queja técnica o disputa en el Casino, la queja debe ser presentada por escrito dentro de los cinco (5) días del evento. Si se recibe la queja después de este tiempo, el casino se reserva el derecho de no atender el asunto. Sin embargo, si hay circunstancias atenuantes; por ejemplo: Un jugador está incapacitado o no está disponible para hacer comentarios, vamos a abordar estos problemas caso por caso pero nos reservamos el derecho a invocar la (5) regla de los cinco días anteriores.",
                "1.14. El jugador acepta todas las decisiones tomadas por el casino como definitivas.",
                "1.15. Todos los fondos restantes en el saldo de una cuenta en el momento de cierre no se pueden retirar. Esto incluye todas y todos los retiros pendientes."]
        }, {
            title: "Violaciónes de los términos y condiciones",
            content: ["2.1. Las siguientes acciones por el jugador será considerado una violación de los términos y condiciones del casino, pero no se limitan a esta lista. Las consecuencias pueden incluir la terminación de la cuenta de casino del jugador, la confiscación de las ganancias y los saldos existentes, negación de servicios, promociones y otras ofertas por el Casino.",
                <ul>
                    <li>Más de una cuenta en {casino.name} CASINO y/o más de una cuenta por hogar y/o más de una
                        cuenta por dispositivo, y/o más de una cuenta desde la misma dirección IP, y o más de una cuenta
                        en un casino hermano afiliado bajo la misma gerencia {casino.name} CASINO.
                    </li>
                    <li>En caso de que en cualquier momento, el casino descubrir un vínculo o conexión a una
                        fraudulenta, suspendido o reembolsado o previamente cerrados cuenta en cualquier marca dentro de
                        la red Rival o cualquier otro casino en línea.
                    </li>
                    <li>En caso de que en cualquier momento el casino descubrir una queja / disputa / crítica activa o
                        pasada en un foro público o blog o cualquier otro tipo de sitio web.
                    </li>
                    <li>En caso de que en cualquier momento el jugador abusar, acosar o hablar de manera inapropiada a
                        cualquiera de los empleados del casino, así como las amenazas o intimidaciones verbales y
                        escritas.
                    </li>
                    <li>Un desajuste en Casino del Jugador cuenta de que no coincida con el nombre de la tarjeta (s) de
                        crédito o método (s) de pago utilizado para hacer depósitos en la cuenta.
                    </li>
                    <li>Información de registro incorrecta o falsa.</li>
                    <li>El jugador está jugando de un territorio o región no plenamente autorizado para permitir que los
                        juegos en línea.
                    </li>
                    <li>El jugador no tiene la edad legal requerida de acuerdo con los Términos y Condiciones del
                        Casino.
                    </li>
                    <li>Si el jugador ha permitido (intencionalmente o no) a otro jugador para jugar en su propia cuenta
                        de Casino.
                    </li>
                    <li>Si una persona no ha jugado en el Casino puramente como un entretenimiento y sólo ha participado
                        en una capacidad profesional o en asociación con otro jugador de otro club o un grupo o parte
                        del mismo.
                    </li>
                    <li>Si se ha encontrado el jugador que hace deliberadamente una 'devolución de cargo 'o' diferencia
                        'de los fondos depositados de su tarjeta de crédito, o cualquier otro método de pago, en su
                        cuenta de Casino; o se ha encontrado que han hecho una 'devolución de cargo 'o amenazado con
                        hacerlo en cualquier otra calidad en un casino hermano en la plataforma Rival o cualquier otro
                        casino o sitio web en línea. Esto incluye amenazas verbales y escritas para disputar, de cargo o
                        detener transacción.
                    </li>
                    <li>Si el jugador es descubierto haciendo trampa o ha encontrado lo que los patrones de apuestas
                        irregulares, o estrategias de apuestas sospechosas o irregulares y ni el jugador ha instigado un
                        sistema (es decir, Martingala, máquinas, equipos, software o cualquier otra forma de
                        automatización) a proliferar una Cheat diseñado para el Casino de perder ingresos debido a esta
                        operación.
                    </li>
                    <li>El jugador está vinculado a ningún empleado, ex-empleado, empresa, un tercero o la agencia
                        conectada al Casino actualmente o en el pasado.
                    </li>
                    <li>El jugador ha hecho algún intento para hackear la página web del casino, o ha realizado algún
                        cambio en alguna forma no específicamente permitida por el Casino.
                    </li>
                </ul>,
                "2.2. En caso de que el casino aprender cualquiera de las resoluciones anteriores figuran verse afectados por el jugador en cualquier otro casino basado en la web, Casino " + casino.name + " actuará en consecuencia.",
                "2.3. En la aceptación de dichas resoluciones y demás condiciones señaladas aquí, el jugador está de acuerdo en ningún evento de promoción o publicidad a la aceptación de cualquier premio y/o ganancias que se han acumulado desde el Casino " + casino.name + " y con ello respalda el uso de que los jugadores nombre de usuario, país o reales primer nombre para dicha práctica sin ningún tipo de compensación o pago al jugador. Ejemplo: Si un jugador ganar un gran bote y el Casino desea anunciar la victoria, el Casino retiene el derecho de uso del reproductor nombre de usuario, país y/o nombre real y publicar la victoria en el sitio del casino, una revista, otro sitio web o cualquier otro medio elegido por el Casino."]
        }, {
            title: "Términos y condiciones generales",
            content: ["3.1. El Casino se compromete a ofrecer juegos justos y honestos y confirma que todos los juegos ofrecidos por Casino " + casino.name + " dependen de un generador de números aleatorios auditado y validado (RNG) por un tercero asegurar que todos los juegos son justos y honestos.",
                "3.2. El Casino se reserva el derecho de modificar el saldo de la cuenta de un usuario para redimir sumas adeudadas al Casino.",
                "3.3. El usuario se compromete a pagar todos los importes debidos a Casino " + casino.name + " y nunca tratar de re-atributo, negar, revocar, disputa, devolución de cargo, bloqueo, mantenga o cancelar cualquiera de estos pagos.",
                "3.4. Si el usuario ha depositado o retirado los fondos mediante un E-Wallet el Casino podrá solicitar copias de otras transacciones que muestran que la E-Wallet específica en uso.",
                "3.5. Cualquier actividad ilegal o criminal por el jugador que dará lugar a la terminación inmediata de la cuenta del Casino y el Casino se reserva el derecho a informar a las autoridades competentes y otros casinos en línea y mercaderes de estas acciones con la divulgación completa.",
                "3.6. El Casino se reserva el derecho de modificar cualquiera de sus servicios que se ofrecen en cualquier momento, incluyendo una indisponibilidad temporal de soporte al cliente, soporte técnico o cualquier otros servicios prestados al jugador.",
                "3.7. En caso de fallo técnico o error de computadora, todas las apuestas en juego pueden ser consideradas nulas y sin efecto. El Casino no se hace responsable de las pérdidas, ganancias u otros problemas generados por un fallo técnico o un fallo en cualquier manera o forma.",
                "3.8. Si un jugador se abstengan de iniciar sesión en su cuenta de Casino por un período de 90 días o más o el jugador 90 días sin hacer una apuesta simple o apuesta, el casino tendrá derecho a cualquier saldo en cuenta que los jugadores y el importe perdido. Si el jugador desea retirar esta cantidad después de este período, vamos a examinar cada situación caso por caso pero nos reservamos el derecho a invocar la regla de 3 meses anteriormente"]
        }, {
            title: "Promociones y bonos",
            content: ["4.1. Todo y cualquier bonos gratis y bonos de depósito no se puede retirar del casino, y tienen el propósito de mejorar sólo el tiempo de juego. Tras la retirada, los importes de las bonificaciones serán deducidas por el Casino antes de enviar el retiro.",
                "4.2. Todos los bonos de dinero será eliminado en el punto de dinero en efectivo, dinero de la prima es el dinero que se puede utilizar para ayudar a generar ganancias, pero no puede ser retirado como parte de las ganancias. El bono será tratado como 'no extraíble 'y no se volvió a publicar en su cuenta pero deducido de las ganancias por el gerente retirada casinos durante el proceso de pago. Dinero Cashback también es 'no extraíble 'y el casino se reserva el derecho a deducirse cashback dinero de retiro pendiente de jugadores.",
                "4.3. Bonos, salvo que se especifique expresamente por el Casino de antemano, llevan un requisito de apuesta que debe ser completado antes de que un jugador puede retirar.",
                "4.4. Todas las promociones cashback llevan un requisito de apuesta de 1X. Los jugadores no pueden retirar un bono de devolución de dinero hasta que la suma del cashback es apostado en su totalidad. Todo el dinero cashback está restringida a un límite máximo de retirada de 10 veces la cantidad de cashback. Esto incluye cashback galardonado como 'regalos', 'comps' o 'fichas gratis'. El Casino se reserva el derecho a deducir el dinero Cashback de un retiro pendiente.",
                "4.5. Todo cashback dinero ganado de un premio del torneo, el premio de la rifa o promoción especial tienen un límite máximo de retirada de 5 veces el bono de devolución de dinero y llevar a un requisito de apuesta de 1X. Todos los bonos de 200% o más, el seguro de reembolso de 75% o más se considera una Promociones Especiales. El Casino se reserva el derecho a deducir el dinero Cashback de un retiro pendiente. Los jugadores que tienen un retiro pendiente o saldo de más de 100 $ / € / £ / no califican para recibir los premios del torneo o para recibir dinero en efectivo de una rifa o retirar dinero de cualquier promoción a Cashback curso o cualquier otra promoción especial ofrecida por el equipo de Promociones.",
                "4.5.1 Bono Especial - todos los bonos de 200% o más, bonos cashback de 100% o más se considera Bonificaciones Especiales.",
                "4.6. Es la responsabilidad del jugador a tener en cuenta los detalles de la bonificación que solicitan o se mencione a ellos, incluyendo los requisitos de apuesta, juegos restringidos y otros detalles, términos y requisitos asociados con dicho bono.",
                "4.7. Los jugadores no eligen jugar con bonificaciones, debe indicar por lo que antes de comenzar a jugar con un bono. Si un jugador no quiere el bono que se ha depositado en su cuenta por el casino, entonces ellos tienen derecho a solicitar un representante de atención al cliente del casino invertir la prima de su cuenta del casino, a través de correo electrónico, llamada telefónica o chat en vivo. Si se recibe un bono y una apuesta que se haga (de cualquier cantidad), la bonificación tendrá que ser jugado hasta el final, hasta que el saldo es cero o los requisitos de apuesta es completa. Si el jugador no ha comenzado a jugar, el apoyo se eliminará el bono en caso de que pedirlo.",
                "4.8. Los bonos gratuitos (Regalos, Fichas Gratis, Puntos Comp convertidos o cualquier otro bono sin depósito) son bonos jugables que se otorgan al jugador para que pueda jugar gratis. Los bonos gratuitos tienen un requisito de apuesta diferente que otros bonos, y tienen un límite de retiro fijo de no más de 100 Créditos (100 $,€,£,AUD, or 1000 ZAR). Cualquier saldo restante o extra es 'No Retirable' y será eliminado del saldo del jugador. A cada jugador se le permite retirar ganancias de un bono gratis sólo una vez durante su vida de juego a menos que sea específicamente aprobado por el gerente del casino.",
                "4.9. Un jugador que nunca ha hecho un depósito y desea retirar un triunfo viruta libre, es necesario primero hacer un depósito mínimo de éxito de 25,00 ($, €, £, AUD, o EUR). No se permite esta primera cantidad del depósito que ser retirado o añadido a un retiro pendiente existente. Si se añade a una retirada existente, la cantidad se deducirá de la cantidad total retirada. A los jugadores que ha hecho previamente un depósito con éxito y ahora está deseen retirar un triunfo ficha gratis, se requiere que hayan depositado dentro de los últimos 30 días para que la retirada sea aprobado y el depósito no se puede añadir a un retiro pendiente existente. Si se añade a una retirada existente, la cantidad se deducirá de la cantidad total retirada. Bonos Gratis (Free chips) tienen un requisito de apuesta de 20X a 60X a menos que se especifique lo contrario.",
                "4.10. Todas las promociones ofrecidas por el Casino sólo son válidos para el período especificado en la propia oferta promocional que se publica en el sitio web del Casino y/o cualquier otro tipo de comunicación proporcionado por el propio Casino."]
        }, {
            title: "Retiro",
            content: ["5.1. Todos los depósitos de 249 USD / EURO / GBP / AUS o menos tienen un retiro máximo de 10 veces el depósito para esa sesión de juego específico (todas las demás monedas vinculadas a USD). Una vez aprobado un retiro, el casino se aplica la regla de 10X y corrige la retirada para reflejar con precisión la regla 10 veces. Los fondos restantes se consideran 'no extraíble' y se eliminan de las cuentas del jugador. Bonos de depósito utilizados durante una sesión de juego que da lugar a una solicitud de retiro pendiente, también son 'Non-extraíble'y se pueden deducir de que la retirada pendiente particular.",
                "5.2. Los clientes pueden enviar su solicitud de retiro en cualquier momento. El tiempo de aprobación puede tardar quince-dieciocho (15 a 18) días hábiles a partir del proceso de autorización de fecha se completó con éxito. El procesamiento de un retiro pendiente requiere la autorización del departamento de fraude también.",
                "5.3. Todas y todos los bonos canjeados por el jugador no pueden ser retirados o amortizadas del Casino, y se eliminarán de las ganancias del jugador en el procesamiento de la retirada.",

                "5.4. Para un retiro de ser aprobado, se le pedirá al jugador a presentar cierta documentación e información con el fin de verificar la cuenta. El incumplimiento o la no presentación de dichos documentos y de información 30 días a partir del momento en que se solicitó la retirada, darán lugar a la retirada de ser cancelada. Es responsabilidad del jugador individual para enviar la documentación requerida. Aprobación retirada depende de todas las condiciones señaladas y la verificación de todos los documentos requeridos.",
                "5.5. Un jugador no puede retirar las ganancias, siempre y cuando todavía hay un requisito de apuesta unido a su equilibrio. Deben completar los requisitos de apuestas antes de hacer una solicitud de retiro, o arriesgarse a que la retirada negó.",
                "5.6. El casino tiene el derecho de rechazar o retener bonos o ganancias en el caso de un depósito de ser cancelada, devueltos o rechazados de algún servicio de pago o procesador en cualquier momento.",
                "5.7. Si el jugador ha apostado en los juegos restringidos con un bono que no permite que los juegos que el Casino se reserva el derecho de cancelar cualquier retiro y confiscar todas las ganancias. Antes de empezar a jugar con un bono de depósito o chip libre, es la responsabilidad del jugador de revisar y entender cuáles son las restricciones de ese bono o chip particular.",
                "5.8. La cantidad mínima para retirar es de 100 € / $ / £ / AUD o 1.000 ZAR.",
                "5.9. Todas y todos los depósitos se debe apostar al menos una vez (1X) la suma del depósito con el fin de calificar para un retiro. El retiro no puede ser aprobado a menos que se reunió por primera vez una apuesta mínima de 1 vez el depósito. Los depósitos realizados como parte de las promociones están sujetas a los Términos y Condiciones de esa promoción específica y requisitos de apuesta.",
                "5.10. Un jugador que no haya realizado un depósito en su cuenta durante 3 meses o más",
                "(90 días o más), será considerado un jugador de bonificación gratis y no será elegible para retirar las ganancias que provienen de las bonificaciones / efectivo gratis que tiene en su saldo y/o que recibe durante ese periodo.",
                "La posibilidad de retirar ganancias de bonos gratis está reservada solo para nuestros jugadores activos."]
        }, {
            title: "Acuerdo de privacidad",
            content: [<>Descargo de responsabilidad:<br/> 6.1. El Casino está obligado a mantener toda la información personal suministrada por seguro el jugador. Cualquier detalle presentado por el jugador del casino en cualquier momento, permanecerán únicamente con el Casino y no serán proporcionados a terceros o agencia.</>,
                "6.2. Es de exclusiva responsabilidad del jugador para mantener todos los nombres de usuario y contraseñas relacionadas con su cuenta de casino seguro y protegido. El Casino no llevará responsabilidad por el uso de los datos de la cuenta por una persona que no es el jugador registrado originalmente.",
                "6.3. El Casino se reserva el derecho de utilizar el nombre del jugador como parte de eventos promocionales (por ejemplo, publicar una gran victoria por dicho jugador), sin ningún tipo de compensación o pago realizado a dicho jugador y no será necesario exigir la aprobación previa por parte del jugador.",
                "6.4. Los términos y condiciones descritos definen un entendimiento y un acuerdo final y exclusivo entre Usted (el jugador) y el Casino y completos todos los acuerdos anteriores, representaciones y entendimientos entre Usted y Casino " + casino.name + " con respecto a su participación en el Casino.",
                "6.5. El Casino se reserva el derecho de retener el depósito o retirar los créditos o bonos a nuestra discreción. El Casino se reserva el derecho a retirar o modificar los términos de este programa en cualquier momento y sin previo aviso.",
                "6.6. Esta oferta no es aplicable a los empleados, socios, proveedores o cualquier otra parte con un negocio y/o relación profesional para Casino " + casino.name + ". Propiedad intelectual",
                "6.7. A través de este contrato, el casino otorga al usuario el derecho intransferible y revocable, no exclusiva y no, a utilizar su sitio web y/o su servicio, así como todos los servicios vinculados. Este derecho se extiende también a los derechos de autor y todos los derechos de propiedad intelectual que se le atribuye.",
                "6.8. El usuario reconoce y acepta que el derecho de autor, la marca y todos los demás derechos de propiedad intelectual que se ejerce sobre cualquier material y/o condición de contenidos y de pertenencia a la página web del casino, siendo de nuestra propiedad estricta. El usuario puede utilizar el material mencionado anteriormente solamente de acuerdo con estos términos y condiciones.",
                "6.9. Casino " + casino.name + " es el nombre comercial del Casino, y el jugador no tiene derecho a usar o emplear un término tan, o cualquier otro término, gráfico, texto, concepto o metodología, al participar en el Sitio Web y los materiales contenidos en el mismo.",
                "6.10. El usuario reconoce y acepta el hecho de que el material y los contenidos que se reproducen en el sitio web del Casino se colocan a su disposición sólo como títulos no comerciales. Este material y estos contenidos deben ser descargados en el disco duro de un ordenador sólo para servir a este objetivo. Si cualquiera de los materiales de los juegos de casino se ha intervenido en mutilada, falsificada o dañado se convierten en vacío e inutilizable. Independientemente de la forma en que se obtienen ya sea física o electrónicamente. La responsabilidad material de los juegos de casino que contiene cualquier error sólo se limita a la sustitución del mismo material.",
                "6.11. Cualquier otro uso está estrictamente prohibido, así como la copia, la falsificación, la reproducción, la publicación, la transmisión y la distribución de las obras derivadas de este material y estos contenidos.",
                "6.12. Acciones criminales o sospechosos posibles pueden ser reportados a la autoridad correspondiente. Cuando deba hacerlo los jugadores pueden tener que presentar determinados documentos de verificación cuando realizan transacciones con el casino en retiros o depósitos. La prueba de este tipo podría ser una identificación con foto clara, comprobante de domicilio, copias de tarjetas de crédito y formularios de declaración de la compra. Este requisito es para validar la seguridad de nuestros jugadores y en cumplimiento de la normativa de juego.",
                "6.13. Este contrato es considerado como debidamente firmado y aceptado por las dos partes: El Casino y el Usuario.",
                "6.14. Los términos y condiciones descritos definen un entendimiento y un acuerdo final y exclusivo entre Usted (el jugador) y Casino " + casino.name + " y completos todos los acuerdos anteriores, representaciones y entendimientos entre Usted y Casino " + casino.name + " con respecto a su participación en el Casino."]
        }, {
            title: "Recomienda a un amigo",
            content: ["7.1. Los términos y condiciones generales aplicables al programa por referir a un amigo y todos los demás programas ofrecidos por Casino " + casino.name + ". Mediante la presentación de toda la información requerida en el programa por referir a un amigo, usted y el partido se hace referencia (persona que está siendo canalizado) someterse a los términos de este programa y la parte que se refiere ha consentido de sus datos suministrados por usted la parte remitente. Mediante el envío de esta información, la parte consiente que se refiere a tener un correo electrónico enviado a él / ella por Casino " + casino.name + " pidiéndole / ella para registrarse para obtener una cuenta de jugador real. Mediante la presentación de la información en el programa de referir a un amigo, el partido se hace referencia es plenamente consciente de que la parte remitente ha presentado sus datos de buena fe.",
                "7.2. Las personas que utilizan el mismo equipo (registro de la misma dirección IP) no se les permitirá participar en el bono por referir a un amigo por motivos de seguridad. Jugadores referidos no deben ser miembros de Casino " + casino.name + " existentes. La dirección de correo electrónico proporcionada por la parte remitente no debe estar asociado a cualquier jugador actual del Casino " + casino.name + " ni será el mismo correo electrónico que el miembro de referencia.",
                "7.3. El miembro de referencia se requerirá que llenar la información específica acerca de su / su amigo referido; esta información incluye, pero no se limita a su nombre, apellido y dirección de correo electrónico. Después de toda la información requerida ha sido proporcionado, presentado y luego verificada por Casino " + casino.name + ", entonces y sólo entonces vendrá el bono se suministra tanto a los miembros que se refieren y que se hace referencia.",
                "7.4. Para calificar para el bono por referir a un amigo, el partido se hace referencia primero debe inscribirse para una cuenta de dinero real y depositar un mínimo de 25 $, €, £ en esa cuenta. Tanto el amigo de referencia y el amigo referido recibirá su bono sólo después de que el amigo referido haya depositado 25 $, €, £ en su cuenta de jugador real y deben permitir durante 48 horas antes de recibir el bono en su cuenta de jugador real."]
        }, {
            title: "Política de reembolso",
            content: ["Gracias por abrir una cuenta con nosotros y depositar fondos en su cuenta en nuestros sitios web.",
                "Ofrecemos la opción de reembolso en todos los depósitos realizados en nuestros sitios web. Sin embargo, se deben cumplir ciertas condiciones. Lo invitamos a leer las condiciones explicadas más detalladamente a continuación, para que vea la condición que aplica a su situación, mientras sigue el procedimiento correcto.",
                "Nuestra política de devoluciones es simple y directa, y será procesada de manera inmediata.",
                "Sin embargo, hay un período de tiempo limitado como se indica a continuación:",
                <ul>
                    <li>Dentro de las 24 horas del depósito original.</li>
                    <li>Según requerido al momento de abrir la cuenta, nuestro departamento de cumplimiento debe
                        completar y aprobar el cumplimiento requerido al momento de abrir la cuenta.
                    </li>
                    <li>Mal funcionamiento de los juegos: se requiere captura de pantalla.</li>
                    <li>No se pueden reproducir los créditos: saldo no jugado.</li>
                    <li>Cargos incorrectos: se requiere declaración bancaria.</li>
                </ul>,
                <>Cuota de procesamiento <br/>Hay un cargo por procesar y emitir su reembolso. Las diferentes tarifas
                    (en su moneda) son los siguientes:</>,
                <ul>
                    <li>Cheque - 55.00</li>
                    <li>SEPA - 20.00</li>
                    <li>SWIFT - 75.00</li>
                </ul>,
                "Reembolsos tardíos o faltantes (si corresponde):",
                <ul>
                    <li>Si aún no ha recibido su reembolso, primero verifique nuevamente su cuenta bancaria.</li>
                    <li>Luego comuníquese con la compañía de su tarjeta de crédito, puede pasar algún tiempo antes de
                        que su reembolso se publique oficialmente.
                    </li>
                    <li>Si ha hecho todo esto y aún no ha recibido su reembolso, contáctenos en {casino.email}</li>
                </ul>]
        }]
    }, "it": {

            title: "Termini e condizioni",
            content: ["Giocare in un Casinò, sia per divertimento che per gioco reale, costituisce un accordo tra il giocatore e il Casinò online e la sua gestione, in quanto il giocatore è vincolato dai seguenti termini e condizioni contenuti nel presente documento. Il giocatore accetta che si tratta di un accordo vincolante tra il giocatore e il casinò ed è l'unico accordo che regola le relazioni del giocatore con il casinò, ad esclusione di tutti gli altri accordi, dichiarazioni e dichiarazioni.",
                "Ogni clausola contenuta in questi termini e condizioni deve essere separata e separabile da ciascuna delle altre. Se una qualsiasi clausola viene ritenuta nulla, non valida o inapplicabile per qualsiasi motivo, i restanti termini e condizioni rimarranno pienamente validi ed efficaci."],
        sections: [{
            title: "Definizioni",
            content: [<>Il Giocatore:<br/>Ogni utente del software che utilizzi giochi o promozioni offerte dal
                Casinò.</>,
                <>Il Casinò:<br/>Casinò {casino.name}, compreso l’intero software contenuti e servizi resi su
                    www.truefortune.com e relativi URLs.</>,
                <>Servizio:<br/>Servizi, supporto offerte e assistenza offerte dal casinò per i suoi utenti.</>,
                <>Crediti:<br/>Somma di denaro sul saldo del conto dell’utente; quantità delle monete di gioco
                    disponibili da utilizzare. 1 credito= 1€,1£,1$,1AUD,10ZAR (approssimativamente).</>,
                <>Noi:<br/>Gli operatori del sito web del casinò e/o il suo gruppo di società.</>,
                <>Giocatore/i:<br/>Un Giocatore attivo o un giocatore ospite o un giocatore inattivo o un giocatore con
                    conto chiuso.</>,
                <>Giocatori reali:<br/>Un giocatore che utilizza il proprio denaro per scommettere nel casinò.</>,
                <>Requisiti di gioco:<br/>Il numero di volte che una somma di crediti deve essere scommessa, puntata
                    prima che sia ammesso ritirare il saldo. Conosciuto anche come requisito di giro.</>]
        }, {
            title: "Disconoscimento",
            content: ["Giocando nel casinò, sia in modalità di divertimento sia in modo reale costituisce un accordo tra il giocatore e il casinò che vincola il giocatore con i seguenti termini e condizioni qui riportati. Con la registrazione, l'apertura di un conto/ giocando nel CASINO' " + casino.name + ", il giocatore accetta di avere:",
                <ol>
                    <li>Letto e compreso i termini e le condizioni scritte qui sotto</li>
                    <li>Accetta pienamente i termini e le condizioni al quale si atterrà nel corso di tutti i rapporti
                        con il casinò o con il software del casinò.
                    </li>
                </ol>,
                "Se una clausola risultasse nulla, non valida o inapplicabile per qualsiasi ragione, i restanti termini e condizioni rimarranno in rimarranno in vigore. I termini e le condizioni descritte nel presente documento sostituiscono e sovrascrivono tutti gli altri termini offerti dal casinò. Il casinò e' registrato, autorizzato e regolamentato dal governo di Curacao per l'esercizio di casinò online."]
        }, {
            title: "Promozioni e condizioni generali",
            content: ["1.1 Giocare nel Casinò " + casino.name + " e' limitato solo agli adulti. I giocatori devono avere almeno 25 anni per poter giocare. La limitazione dell'età' può cambiare in base alla legislazione nazionale locale, ed e' responsabilità del giocatore verificare l'età' minima consentita nel proprio paese. Il casinò si riserva comunque il diritto di richiedere una verifica dell'età' del giocatore in qualsiasi momento.",
                "1.2 Il giocatore accetta che il casinò possa cambiare, aggiungere o sottrarre i termini e le condizioni in qualsiasi momento senza preavviso, ed e' responsabilità del giocatore controllare periodicamente i termini e le condizioni qui scritte per modifiche che potrebbero riguardarlo. La mancanza di conoscenza di tali termini e condizioni, nel momento dell'apertura del conto nel casinò non costituisce un valido motivo per nessuna violazione delle norme e delle condizioni qui stabilite.",
                "1.3 Tutte le decisioni prese dal casinò sono definitive, e saranno conformi ai termini e alle condizioni stabilite in tale documento. Con l'iscrizione al casinò, il giocatore si impegna ad accettare eventuali e tutte le decisioni finali e decisive che verranno prese dal casinò.",
                "1.4 Il casinò non si assumerà alcuna responsabilità, diretta o indiretta, come per quanto riguarda il diritto legale del giocatore di giocare in un casinò online con soldi veri. Ciò include qualsiasi dipendente, concessionario, distributore, grossista, affiliato, filiali, pubblicità, promozione o altre agenzie, media partner, agenti o rivenditori che sostengono o sono stati visti rivendicare l'autorizzazione a effettuare tali dichiarazioni o garanzie. Questa e' esclusiva responsabilità' del giocatore verificare la legge locale da rispettare in modo responsabile. Il casinò non avrà alcuna responsabilità per eventuali violazioni della legge da parte del giocatore.",
                "1.5 Tutti i giochi, servizi o promozioni offerti dal casinò sono puramente pensati per scopi di intrattenimento, e non come sistema finanziario. Esiste la possibilità di giocare con crediti falsi che non e' come usare soldi veri. Giocare con soldi reali e' solo per decisione del giocare e cosi facendo il giocatore accetta eventuali rischi connessi per giocare con tali soldi reali.",
                "1.6 Eventuali tasse, imposte, tasse di trasferimento o di altre responsabilità finanziarie o conseguenze legate all'utilizzo dei giochi o dei servizi offerti dal casinò sono sotto responsabilità del giocatore e solo del giocatore.",
                "1.7 Il giocatore non terrà responsabile il casinò, ne alcuna agenzia, dipendente,socio o mezzi relativi al casinò per eventuali danni, costi, spese o passività dovute all'utilizzo per aver accettato i servizi del casinò, ne per qualsiasi attività del giocatore relativa al casinò.",
                "1.8 Il casinò non si assume alcuna responsabilità per eventuali errori tecnici verificatisi nel software collegati alla connessione internet, del software del computer o dell'hardware del computer del giocatore. Eventuali interruzioni del servizio internet sono a carico del giocatore e il casinò non compenserà ne si assumerà alcuna responsabilità' per interruzioni causate da un problema tecnico da parte del giocatore, incluso e non limitato a disconnessioni internet. Eventuali altre anomalie tecniche devono essere presentate per iscritto e dovranno comprendere uno screenshot in modo che il casinò possa verificarne l'autenticità'.",
                "1.9 Il giocatore riconosce che l'identità' registrata nel proprio conto del casinò sarà dell'unica persona che accede/utilizza/gioca con il software o con il sito del casinò. Qualsiasi violazione di tale accordo (cioè l'utilizzo del conto da una persona diversa) sarà considerata una violazione dei termini e delle condizioni motivo di chiusura del conto con confisca di tutte le vincite. Il conto non e' trasferibile in alcun modo ne potrà l'utente modificare il conto. Tutti i nuovi utenti devono aprire un nuovo conto o violerà i termini e le condizioni.",
                "1.10 Il casinò si riserva il diritto di cambiare, aggiungere, o sottrarre in qualsiasi momento giochi/servizi che offre senza alcun annuncio o aggiornamento. Termini e condizioni possono essere modificati o alterati quando sarà necessario e il giocatore deve accettare questo come parte prerogativa del sito web.",
                "1.11 Il giocatore accetta inoltre indiscutibilmente di indennizzare Casino " + casino.name + ", i suoi dipendenti, funzionari, amministratori e ogni e qualsiasi associato, persone o organizzazioni contro qualsiasi costo, spese, responsabilità e danni derivanti da qualsiasi azione legale o altra azione intrapresa da o contro un Giocatore derivante da qualsiasi azione all'interno del Casinò e / o dei suoi dipendenti, funzionari, amministratori e tutte le persone o organizzazioni associate o affiliate. Questo evento include (ma non solo) le implicazioni legali che potrebbero sorgere a seguito della criminalizzazione di scommesse online in internet da parte dei giocatori, nel giurisdizione del territorio o paese.",
                "1.12 Il giocatore accetta come vero qualsiasi dei risultati di gioco mostrati dal software del Casinò o nel sito web. Eventuali controversie riguardanti i risultati delle partite giocate saranno decisi dai risultati dal server di gioco, e di questi il casinò ne determinerà la decisione finale. Se per qualsiasi motivo il giocatore non è del tutto soddisfatto dei termini e le condizioni chiarite prima, il giocatore ha il diritto di interrompere la riproduzione e rimuovere il software dal suo computer. Inoltre, un giocatore che è insoddisfatto della propria esperienza di gioco nel Casinò e desidera chiudere il proprio conto, in modo responsabile chiede manualmente la chiusura o il blocco del conto all'interno del software del Casinò e chiede di rimuovere qualsiasi software associato con il casinò dal proprio computer o dispositivi mobili. Sono applicate dal casinò legali restrizioni di età e aderenze severe alle politiche di età, come concordato dalla giurisdizione del proprio territorio per conformarsi alla citata legge di quel territorio. Questo è chiaramente definito a nessun giocatore di età inferiore ai 25 anni di età è permesso giocare al casinò indipendentemente dal sesso.",
                "1.13 In caso di reclami, ricorsi, torti o tecnicismi o dispute nel casinò, il reclamo deve essere presentato per iscritto entro cinque giorni della manifestazione. Se il reclamo viene ricevuto dopo questo tempo, il casinò si riserva il diritto di non affrontare la questione. Tuttavia, se ci sono circostanze attenuanti; per esempio: un giocatore è impossibilitato o comunque disponibile per un commento, affronteremo questi problemi, caso per caso, ma si riserva il diritto di avvalersi della regola dei cinque giorni citata sopra.",
                "1.14 Il giocatore accetta le decisioni finali prese dal casinò.",
                "1.15 Eventuali fondi rimanenti del saldo di un conto, al momento della chiusura, non possono essere ritirate. Ciò include tutti i prelievi in sospeso."]
        }, {
            title: "Violazioni dei termini & condizioni",
            content: ["2.1. Le seguenti azioni da parte del giocatore sarà considerata una violazione dei termini e condizioni del Casinò, ma non limitato a questo elenco. Le conseguenze possono includere la cessazione del conto del casinò del giocatore, la confisca delle vincite e di equilibri esistenti, la negazione dei servizi, promozioni e altre offerte da parte del Casinò.",
                <ul>
                    <li>Più di un conto su {casino.name} CASINO e / o più di un conto per famiglia e / o più di un
                        conto per dispositivo e / o più di un conto dallo stesso indirizzo IP e / più di un conto su un
                        altro Casinò sotto la stessa gestione di {casino.name} CASINO.
                    </li>
                    <li>Qualora, in qualsiasi momento, il casinò scopre un collegamento o una connessione fraudolenta,
                        in sospeso, rimborsata o un precedentemente conto chiuso su qualsiasi sito marca all'interno
                        della rete Rival o di qualsiasi altro casino online.
                    </li>
                    <li>Qualora, in qualsiasi momento il casinò scopra una denuncia, una disputa, una critica attiva o
                        passata su un forum pubblico o un blog o qualsiasi altro tipo di sito web.
                    </li>
                    <li>Qualora, in qualsiasi momento un giocatore abusi, molesti o parli in modo inappropriato con i
                        membri del personale Casinò, tra cui minacce o intimidazioni verbali e scritte.
                    </li>
                    <li>Una mancata corrispondenza del Giocatore sul casinò con un account che non corrisponda al nome
                        sulla carta di credito, o al/ai metodo/i di pagamento/i utilizzato/i per effettuare depositi sul
                        conto.
                    </li>
                    <li>Informazioni di registrazione errata o false.</li>
                    <li>Il giocatore sta giocando da un territorio o da una regione che non sono pienamente autorizzati
                        a permettere il gioco online.
                    </li>
                    <li>Il giocatore non ha l'età legale necessaria secondo i Termini e Condizioni del casinò.</li>
                    <li>Se il giocatore ha consentito o permesso (intenzionalmente o meno) ad un altro giocatore di
                        giocare sul proprio conto del Casino.
                    </li>
                    <li>Se un individuo non ha giocato nel Casino puramente a scopo d’intrattenimento e ha partecipato
                        solo a titolo professionale o in associazione con un altro giocatore di un altro club, gruppo o
                        parte di esso.
                    </li>
                    <li>Se un giocatore ha richiesto deliberatamente di fare un 'chargeback' o 'disputa' di fondi
                        depositati con la propria carta di credito, o qualsiasi altro metodo di pagamento, nel loro
                        conto del Casinò; o sono stati scoperti di aver fatto un 'chargeback' o minacciato di farlo in
                        qualsiasi altro titolo su un casinò affiliato sulla piattaforma Rival o qualsiasi altro casinò
                        online o sito web. Questo include delle minacce verbali e scritte per contestare un chargeback o
                        interrompere un'operazione.
                    </li>
                    <li>Se il giocatore viene scoperto barare o è stato trovato ad applicare modelli di scommesse
                        irregolari, o strategie di scommesse sospette, irregolari, oppure ha avviato un sistema (cioè
                        Martingale, macchine, computer, software o qualsiasi altra forma di automazione) per proliferare
                        con l’imbroglio, il Casinò farà perdere i ricavi a causa di questa operazione.
                    </li>
                    <li>Il giocatore è legato a tutti i dipendenti, ex-dipendenti, società, terzi o agenzia collegata al
                        Casinò attualmente o in passato.
                    </li>
                    <li>Il giocatore ha provato ogni tentativo “hack” nel software del Casino, nel sito web, o ha
                        apportato modifiche ad esso in qualsiasi modo non specificamente consentite dal Casinò.
                    </li>
                </ul>,
                "2.2 Se il casinò apprende da tutte le sentenze sopra elencate di colpire un giocatore in qualsiasi altro casinò web, Casino " + casino.name + " agirà di conseguenza.",
                "2.3 In accettazione di dette sentenze e tutte le altre condizioni descritte in questo documento, il giocatore accetta eventi promozionali e pubblicitari con l'accettazione di qualsiasi premio e/o vincita che sono stati maturati da Casino " + casino.name + " e quindi approva l'uso per i giocatori dell’username, del paese o del nome reale, per tale pratica senza alcun compenso o pagamento al giocatore. Esempio: Se un giocatore vincere un grande jackpot e il Casinò vuole pubblicizzare la vittoria, il Casinò trattiene il diritto di utilizzare l’username, il paese e/o il vero nome e postare la vittoria sul sito del casinò, una rivista, un altro sito o qualsiasi altro supporto dei casinò a scelta."]
        }, {
            title: "Termini & condizioni generali",
            content: ["3.1. Il Casinò si impegna ad offrire un gioco corretto e onesto e conferma il software e tutti i giochi offerti da Casino " + casino.name + " a contare su un generatore di numeri Random controllato e validato (RNG) da un terzo per garantire che tutti i giochi siano giusti e onesti.",
                "3.2. Il Casinò si riserva il diritto di modificare il saldo del conto di un utente di riscattare somme dovute al Casinò.",
                "3.3. L'utente si impegna a pagare tutte le somme dovute al Casino " + casino.name + " e di non provare a re-attributo, negare, revocare, disputare, chargeback, bloccare, tenere o cancellare qualsiasi di questi pagamenti.",
                "3.4. Se l'utente ha depositato o ritirato fondi usando un portafogli elettronico (E-Wallet) come Moneybookers, Neteller o EcoCard, il Casinò può richiedere copie di altre operazioni che dimostrino specifici E-Wallet in uso.",
                "3.5. Qualsiasi attività illegali o criminali fatta dal giocatore comporterà l'immediata cessazione del conto Casinò e il Casinò si riserva il diritto di informare le autorità competenti e gli altri casinò online dei mercanti di queste azioni.",
                "3.6. Il Casinò si riserva in ogni momento il diritto di modificare qualsiasi dei suoi servizi offerti, anche una temporanea indisponibilità di assistenza clienti, supporto tecnico o altri servizi forniti al giocatore.",
                "3.7. In caso di guasto tecnico o un computer glitch, tutte le scommesse in gioco possono essere considerate nulle. Il Casinò non può essere ritenuto responsabile per le perdite, le vittorie o altri problemi generati da un guasto tecnico o problema tecnico in qualsiasi modo o forma.",
                "3.8. Se un giocatore decide di astenersi dall’accesso del proprio conto nel Casino per un periodo di 90 giorni o più, il giocatore che per 90 giorni non farà una singola scommessa singola, il Casinò avrà diritto a riequilibrare il conto dei giocatori e la somma incamerata. Se il giocatore desidererà ritirare l'importo dopo questo periodo, esamineremo ogni situazione caso per caso, ma si riserva il diritto di avvalersi della regola 3 mesi citata sopra."]
        }, {
            title: "Promozioni e bonus termini & condizioni",
            content: ["4.1. Tutti i bonus gratuiti e bonus di deposito non possono essere ritirati dal Casinò, e hanno lo scopo di migliorare solo il tempo di gioco. Al momento di ritiro, i bonus saranno dedotti dal Casino prima che il ritiro venga inviato.",
                "4.2. Tutto il denaro bonus verrà rimosso al momento del ritiro, i soldi bonus sono denaro che può essere utilizzato per contribuire a generare le vincite, ma non può essere ritirato come parte delle vincite. Il bonus sarà trattato come 'non-estraibile' e non può essere riassegnata nel tuo conto ma detratto dalle vincite da parte del gestore casinò di ritiro durante il processo di pagamento. Cashback in denaro è anche chiamato 'non estraibile' e il casinò si riserva il diritto di detrarre soldi in cashback durante l’attesa di ritiro di un giocatore.",
                "4.3. Bonus, se non espressamente specificato dal Casinò in anticipo, portano un requisito di scommessa che deve essere completata prima che un giocatore possa ritirare.",
                "4.4. Tutte le promozioni cashback portano ad un requisito di scommessa di 1X. I giocatori non possono prelevare un bonus cashback fino a che la somma del cashback è stata scommessa totalmente. Tutto il denaro cashback è limitato ad un limite massimo di ritiro del 10X dell'importo cashback. Questo include cashback premiato come 'regalo', 'composizioni' o 'chip gratuiti'. Il Casinò si riserva il diritto di detrarre Cashback denaro da un prelievo in sospeso.",
                "4.5. Tutti cashback sono soldi vinti da un premio del torneo, premio della lotteria o Promozione Speciale hanno un limite massimo di ritiro del 5X bonus per cashback e hanno un requisito di scommessa di 1X. Tutti i bonus del 200% o più, assicurazione cashback del 75% o più sarà considerato una promozione speciale. Il Casinò si riserva il diritto di detrarre Cashback denaro da un prelievo in sospeso. I giocatori che hanno un prelievo in attesa o il saldo di oltre 100 $/€/£/ non si qualificheranno per ricevere i premi del torneo o ricevere denaro da una lotteria o di prelievo dalla promozione Cashback in corso o qualsiasi altra promozione speciale offerta dalla squadra Promozioni.",
                "4.5.1 Bonus Speciale - tutti i bonus del 200% o più, bonus con cashback del 100% o più saranno considerati bonus speciali.",
                "4.6. E' responsabilità del giocatore di essere a conoscenza della richiesta dei dettagli del bonus o i loro requisiti di scommessa, vincoli di giochi e tutti gli altri dettagli,con i termini e requisiti associati a tale bonus..",
                "4.7. I giocatori non scelgono di giocare con i bonus, lo devono richiede prima di iniziare a giocare. Se un giocatore non vuole il bonus che è stato depositato nel loro conto dal casinò, ha diritto di richiedere ad un rappresentante del servizio clienti del casinò di invertire il bonus dal loro conto del casinò, a titolo di e-mail, telefonata o live chat. Se un bonus viene ricevuto e viene fatta una scommessa (di qualsiasi importo), il bonus dovrà essere giocato fino in fondo, fino a quando il saldo è pari a zero o il requisito di scommessa è completa. Se il giocatore non ha iniziato a giocare, il supporto rimuoverà il bonus su richiesta.",
                "4.8. Bonus gratuiti (Comps, Gettoni gratis, Punti comp convertiti in denaro o qualsiasi altro bonus senza deposito) sono dei bonus accreditati ai giocatori in modo che possano giocare gratuitamente. I bonus gratuiti hanno un requisito di scommessa diverso da gli altri bonus, e hanno un limite di prelievo fisso di non più di 100 crediti (100 $, €, £, AUD o 1000 ZAR). Qualsiasi saldo supplementare non è ritirabile e deve essere cancellato dal saldo del giocatore. Ogni giocatore ha la possibilità di ritirare le vincite da un bonus gratuito una volta solo durante la sua vita di gioco se non espressamente autorizzato dal gestore del casinò.",
                "4.9. Un giocatore che non ha mai effettuato un deposito e desidera ritirare un chip gratuito, è tenuto ad effettuare prima un deposito minimo di 25,00 ($, €, £, AUD, o EUR). Questo primo importo del deposito non può essere ritirato o aggiunto nel ritiro già in attesa. Se aggiunto al prelievo esistente, l'importo verrà detratto dal totale ritiro. Un giocatore che ha già effettuato un deposito ed è ora desidera ritirare un chip gratuito, è necessario che abbia depositato negli ultimi 30 giorni in modo che il ritiro sia approvato e il deposito non possa essere aggiunto ad un ritiro esistente in attesa. Se aggiunto al prelievo esistente, l'importo verrà detratto dal totale ritiro. Bonus gratuiti (Free Chips) hanno un requisito di scommessa da 20x a 60X se non diversamente specificato.",
                "4.10. Tutte le promozioni offerte dal Casinò sono valide solo per il periodo indicato nell’offerta promozionale in sé, che è pubblicato sul sito web del Casino e/o di qualsiasi altra comunicazione fornita dal Casinò stesso."]
        }, {
            title: "Ritiro termini & condizioni",
            content: ["5.1. Tutti i depositi di 249 USD / EURO / GBP / AUS o meno hanno un prelievo massimo di 10X il deposito per la sessione di gioco specifica (tutte le altre valute ancorate a USD). Una volta che un prelievo è stato approvato, il casinò applica la regola di 10X e corregge il ritiro per riflettere accuratamente la regola 10X. I fondi rimanenti sono considerati 'non estraibile' e vengono cancellati dal saldo del giocatore. Bonus di deposito utilizzati durante una sessione di gioco che si traduce in una richiesta di prelievo in sospeso, sono essi anche 'non estraibili' e possono essere dedotti da tale particolare ritiro in attesa.",
                "5.2. I clienti possono presentare la loro richiesta di prelievo in qualsiasi momento. Il tempo di approvazione potrebbe richiedere 15-18 giorni lavorativi per avviare il processo di autorizzazione completato con successo. L’elaborazione di un prelievo in sospeso richiede l'autorizzazione da parte del servizio antifrode, come ok.",
                "5.3. Tutti i bonus richiesti dal giocatore non possono essere ritirate o rimborsate dal Casinò, e saranno rimossi dalle vincite del giocatore sulla lavorazione del ritiro.",

                "5.4. Per l’approvato di un ritiro, verrà richiesto al giocatore di presentare alcuni documenti e informazioni al fine di verificare il conto. Il mancato rispetto o mancata presentazione di tali documenti ed informazioni entro 30 giorni dal momento del ritiro che è stato richiesto, comporterà la revoca per essere cancellato. È responsabilità del singolo giocatore di inviare la documentazione richiesta. Ritiro e approvazione dipendono da tutte le condizioni descritte e dalla verifica di tutti i documenti richiesti.",
                "5.5. Un giocatore non può ritirare le vincite fino a quando non ha ancora completato il requisito di scommessa attaccati al loro accordo. Essi devono completare i requisiti di scommessa prima di effettuare una richiesta di prelievo, o rischiano di avere il ritiro negato.",
                "5.6. Il casinò ha il diritto di rifiutare, trattenere un bonus o una vincita in caso di deposito di annullamento, restituiti, negati da qualsiasi servizio di pagamento o processore in qualsiasi momento.",
                "5.7. Se il giocatore ha scommesso sui giochi vincolati dal bonus a cui non è consentito di giocare, il casinò si riserva il diritto di cancellare qualsiasi ritiro e confiscare tutte le vincite. Prima di iniziare a giocare con un bonus di deposito o chip gratuito, è responsabilità del giocatore controllare e capire quali sono le limitazioni di quel particolare bonus o chip.",
                "5.8. L'importo minimo per un prelievo è di 100 €/$/£/AUD/o 1000 ZAR.",
                "5.9. Tutti i depositi devono essere giocati almeno una volta (1X) la somma del deposito, al fine di beneficiare di un ritiro. Un ritiro non può essere approvato a meno che non ci sia una scommessa minima di 1X del deposito. I depositi effettuati nell'ambito delle promozioni sono soggetti a Termini e Condizioni specifiche di promozione e requisiti di scommessa.",
                "5.10. Un giocatore che non ha depositato sul suo conto per tre mesi o più (90+ giorni), sarà considerato un giocatore di Bonus gratuito e non sarà idoneo a ritirare le vincite da Bonus gratuiti o denaro concessogli dal casinò. La possibilità di ritirare le vincite dai Bonus gratuiti è riservata ai nostri giocatori attivi."]
        }, {
            title: "Accordo sulla privacy",
            content: ["6.1. Il Casinò è obbligato a mantenere tutte le informazioni personali fornite dal giocatore sicure e protette. Qualsiasi dettaglio presentate del giocatore nel Casinò in qualsiasi momento, rimarrà solo nel Casinò e non sarà dato al di fuori a terzi o agenzie.",
                "6.2. E' esclusiva responsabilità del giocatore mantenere tutti i nomi utente e le password relative al loro conto nel casinò al sicuro e protette. Il Casinò non porterà la responsabilità per qualsiasi utilizzo dei dati del conto da una persona che non è il giocatore originariamente registrato.",
                "6.3. Il Casinò si riserva il diritto di usare il nome del giocatore, come parte di eventi promozionali (ad esempio, pubblicando una grande vittoria del giocatore), senza alcun compenso o pagamento effettuato per giocatore e il casinò non sarà tenuto a chiedere un'approvazione preventiva da parte del giocatore.",
                "6.4. Le condizioni descritte definiscono una comprensione e un accordo definitivo ed esclusivo tra Lei (il giocatore) e il Casinò e aderiscono a tutti gli accordi precedenti, dichiarazioni e intese tra Lei e il Casino " + casino.name + " per quanto riguarda la partecipazione nel Casinò.",
                "6.5. Il Casinò si riserva il diritto di trattenere il deposito o ritirare eventuali crediti o bonus a nostra discrezione. Il Casinò si riserva il diritto di ritirare o modificare i termini di questo programma in qualsiasi momento e senza alcun preavviso.",
                "6.6. Questa offerta non è applicabile per i dipendenti, partner, fornitori o chiunque altro abbia un business e /o un rapporto professionale con il Casino " + casino.name + ". Proprietà intellettuale",
                "6.7. Con tale contratto, il Casinò concede all'utente un diritto trasferibile e revocabile, non esclusivo di non utilizzare il suo sito web, il suo software e / o il suo servizio, se non per tutti i servizi connessi. Questo diritto si estende anche a tutti i diritti di proprietà intellettuale di essere collegato ad esso.",
                "6.8. L'utente riconosce e accetta che il diritto d'autore, il marchio e tutti gli altri diritti di proprietà intellettuale esercitata su qualsiasi materiale e fornito nei contenuti è appartenente al sito web e / o al software del casinò, rimane rigorosamente di nostra proprietà. L'utente può utilizzare il materiale citato sopra solo in accordo con questi termini e condizioni.",
                "6.9. Casino " + casino.name + " è il nome commerciale del Casinò, e il giocatore non ha diritto ad utilizzare o impiegare un tale termine, o altri termini, di grafica, in testi, in concetti o in metodologie, prendendo dal Sito Web il materiale ivi contenuto.",
                "6.10. L'utente riconosce e accetta il fatto che il materiale e i contenuti riprodotti sul sito web del Casinò sono messi a loro disposizione a titolo solamente non commerciale. Questo materiale e questi contenuti devono essere scaricati sul disco fisso di un computer solo per servire a questo obiettivo. Se uno qualsiasi dei materiali dei giochi del casinò verrà disturbato, danneggiato o forgiato diventerà nullo e inutilizzabile. Indipendentemente da come è stato ottenuto se fisicamente o elettronicamente. La responsabilità del materiale dei giochi del casinò che contengono errori è limitata solo ad una sostituzione dello stesso materiale.",
                "6.11. Ogni altro utilizzo è severamente proibito, così come la copia, la falsificazione, la riproduzione, la pubblicazione, la trasmissione e la distribuzione del lavoro derivante da questo materiale e da questi contenuti.",
                "6.12. Possibili azioni criminali o sospette possono essere segnalate alle autorità competenti. Quando saranno chiamati a farlo i giocatori potranno avere bisogno di produrre alcuni documenti di verifica durante le transazioni di prelievi o depositi con il casinò. Tale prova potrebbe essere d'identità con foto chiara, la prova di indirizzo, copie delle carte di credito e di moduli di dichiarazione di acquisto. Questo requisito è per rendere valida la sicurezza dei nostri giocatori e nel rispetto delle norme di gioco.",
                "6.13. Questo contratto è considerato debitamente firmato e accettato da entrambe le parti: Il Casinò e l'Utente.",
                "6.14. Le condizioni descritte definiscono una comprensione e un accordo definitivo ed esclusivo tra Lei (il lettore) e Casino " + casino.name + " per quanto riguarda la partecipazione al Casinò nel completare tutti gli accordi precedenti, le dichiarazioni e l’intesa tra Lei e Casino " + casino.name + "."]
        }, {
            title: "Invita un amico termini & condizioni",
            content: ["7.1. I termini e le condizioni generali applicabili al programma per chi porta un amico e tutti gli altri programmi offerti da Casino " + casino.name + ". Inviando tutte le informazioni necessarie nel programma che si riferiscono a un amico, sia lei (persona a cui si fa riferimento) dia il consenso a tutti i termini di questo programma e abbia acconsentito alla concessione dei suoi dati, forniti dalla persona a cui si fa riferimento. Inviando queste informazioni, la persona a cui si fa riferimento deve inviare una e-mail inviata a lui / lei nel Casino " + casino.name + " chiedendogli/le di firmare per un conto di un giocatore reale. Inviando le informazioni per il programma invita un amico, la persona di riferimento è pienamente consapevole del fatto che la persona interessata ha presentato i propri dati in buona fede.",
                "7.2. Le persone che utilizzano lo stesso computer (la registrazione dallo stesso indirizzo IP) non saranno autorizzate a partecipare al bonus di chi porta un amico per motivi di sicurezza. I giocatori non devono già essere membri esistenti del Casino " + casino.name + ". L'indirizzo e-mail fornito dalla persona interessata non deve essere associato a qualsiasi giocatore di turno di Casino " + casino.name + " né deve essere la stessa e-mail del membro di riferimento.",
                "7.3. Il membro del rinvio dovrà compilare informazioni specifiche sul suo amico; tali informazioni includono, ma non si limitano a nome, cognome e indirizzo e-mail. Dopo che tutte le informazioni richieste saranno state fornite, presentate e poi verificate dal Casino " + casino.name + ", allora e solo allora il bonus sarà fornito ad entrambi i soci.",
                "7.4. Al fine di qualificarsi per il bonus per chi porta un amico, la persona interessata deve prima registrarsi con un conto in denaro reale e depositare un minimo di 25 $, €, £ in quel conto. Entrambi riceveranno il bonus solo dopo che avranno depositato 25 $, €,£ nel proprio conto come giocatori reali e dovranno lasciar passare 48 ore prima di ricevere il bonus nei loro conti reali."]
        }, {
            title: "Politica di ritorno",
            content: ["Grazie per aver aperto un conto con noi e depositato sul tuo conto presso i nostri siti Web.",
                "Noi ti offriamo la possibilità di un rimborso su tutti i depositi effettuati sui nostri siti Web. Tuttavia, devono essere soddisfatte determinate condizioni. Ti invitiamo a leggere le condizioni spiegate più in dettaglio di seguito, al fine di vedere la condizione applicabile alla tua situazione, seguendo la procedura corretta.",
                "La nostra politica di restituzione è semplice, diretta e verrà' elaborata immediatamente.",
                "Tuttavia, c'è un lasso di tempo limitato come indicato:",
                <ul>
                    <li>Entro 24 ore dal deposito originale.</li>
                    <li>Come richiesto al momento dell'apertura del conto, la conformità richiesta al momento
                        dell'apertura del conto deve essere completata e approvata dal nostro dipartimento addetto.
                    </li>
                    <li>Malfunzionamento dei giochi - Screenshot richiesto.</li>
                    <li>Impossibile riprodurre i crediti - Saldo non giocato.</li>
                    <li>Addebito errato - dichiarazione richiesta.</li>
                </ul>,
                <>Tassa di elaborazione <br/> È prevista una commissione per l'elaborazione e l'emissione del rimborso. Le diverse tasse (nella tua valuta) sono i seguenti:</>,
                <ul>
                    <li>Verifica carta - 55.00</li>
                    <li>SEPA - 20.00</li>
                    <li>Cavo SWIFT - 75.00</li>
                </ul>,
                "Rimborsi mancati o in ritardo (se applicabile):",
                <ul>
                    <li>Se non hai ancora ricevuto il rimborso, controlla di nuovo il tuo conto bancario.</li>
                    <li>Quindi contattando direttamente il servizio clienti della carta di credito, potrebbe essere
                        necessario del tempo prima che il rimborso venga ufficialmente registrato.
                    </li>
                    <li>Se hai fatto tutto questo e non hai ancora ricevuto il rimborso, ti preghiamo di contattarci
                        all'indirizzo {casino.email}.
                    </li>
                </ul>]
        }]
    }
}
export default terms;