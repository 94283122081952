
const btn = (href, en, de, fr, it, es) => {
    return {href, en, de: de || en, fr: fr || en, it: it || en, es: es || en}
}


const pages = {
    chat: btn("chat", "Live Chat","Live Chat","CHAT EN DIRECT","CHAT DAL VIVO","CHAT EN VIVO"),
    join: btn('register', 'Join Now', "REGISTRIEREN", "S'INSCRIRE", "Iscriviti", "REGISTRARSE"),
    login: btn('instantplay', 'Log in', "ANMELDEN", "CONNEXION", "Accedi", "ENTRAR"),
    language: btn("","Language","Sprache","Langue","linguaggio","idioma"),
    leaderboard: btn("leader_board","Leaderboard","Bestenliste","classement","classifica","tabla de clasificación"),

    // games page
    all_providers: btn('', 'All providers', "Alle Anbieter", "Tous les fournisseurs", "Tutti i fornitori", "Todos los proveedores"),
    all_games: btn('', 'All Games', "All Spiele", "Tous les Jeux", "Tutti i Giochi", "All Games"),
    jackpot: btn('', 'Jackpot', "Jackpot", "Jackpot", "Jackpot", "Jackpot"),
    live_casino: btn('', 'Live Casino', "Live Casino", "Casino en direct", "Casinò Live", "Casino en vivo"),
    new_games: btn('', 'New Games', "Neue Spiele", "Nouveaux Jeux", "Giochi In Vetrina", "Nuevos Juegos"),
    popular: btn('', 'Popular', "Beliebt", "Popularies", "Popolare", "Popular"),
    slots: btn('', 'Slots', "Slots", "Machines à sous", "Slot", "Juegos"),
    table: btn('', 'Table', "Tisch-Spiele", "Jeux de table", "Giochi da tavolo", "Juegos de mesa"),
    top: btn('', 'Top', "Top", "Meilleurs", "Top", "Top"),
    games_1: btn('', 'Top Games For You', "Top-Spiele für Sie", "Les meilleurs jeux pour vous", "I migliori giochi per te", "Los mejores juegos para ti"),
    games_4: btn('', 'Scratch & Win', "Kratzen & Sieg", "Grattez et gagnez", "Graffio & Vincita", "Raspa y gana"),

    // bonus: {en: 'Bonus'},
    main:{
        "href": "/",
        "en": "Main",
        "de": "Startseite",
        "it": "Iniziale",
        "fr": "Accueil",
        "es": "Inicio",

    },
    about: {
        "href": "/about",
        "en": "About us",
        "de": "Über uns",
        "it": "Chi siamo",
        "fr": "À propos",
        "es": "Quiénes somos"
    },
    vip: {"href": "/vip", "en": "VIP", "de": "VIP", "it": "VIP", "fr": "VIP", "es": "VIP"},
    promotions: {
        "href": "/promotions",
        "en": "Promotions",
        "de": "Aktionen",
        "it": "Promozioni",
        "fr": "Promotions",
        "es": "Promociones",
        icon:"/brand/images/promotions.svg"
    },
    support: {
        "href": "/support",
        "en": "support",
        "de": "hilfe",
        "it": "supporto",
        "fr": "support",
        "es": "soporte"
    },
    games: {"href": "/games", "en": "Games", "de": "Spiele", "it": "Giochi", "fr": "Jeux", "es": "Juegos"},
    banking: {"href": "/banking", "en": "Banking", "de": "Kasse", "it": "Cassa", "fr": "Banque", "es": "Cajero"},
    terms: {
        "href": "/terms",
        "en": "Terms & Conditions",
        "de": "Allgemeine geschäftsbedingungen",
        "it": "Termini e condizioni",
        "fr": "Termes et conditions",
        "es": "Terminos y condiciones"
    },
    faq: {
        "href": "/faq",
        "en": "Faq",
        "de": "Häufig gestellte fragen",
        "it": "Faq",
        "fr": "Faq",
        "es": "Preguntas frequentes"
    },
    privacy: {
        "href": "/privacy",
        "en": "Privacy",
        "de": "Privatsphäre",
        "it": "Privacy",
        "fr": "Politique de confidentialité",
        "es": "Política de privacidad"
    },
    aml: {"href": "/aml", "en": "Aml", "de": "Geldwäschebekämpfung", "it": "Aml", "fr": "Aml", "es": "Ald"},
    responsible_gaming: {
        "href": "/responsible_gaming",
        "en": "Responsible Gaming",
        "de": "Verantwortliche spielpolitik ",
        "it": "Politica di gioco responsabile",
        "fr": "Jeu responsable",
        "es": "Juego responsable"
    },
    cookie_policy: {
        "href": "/cookie_policy",
        "en": "Cookie Policy",
        "de": "Cookie-politik",
        "it": "Gestione dei cookie",
        "fr": "Politique de cookie",
        "es": "Política de cookies"
    },
    security: {
        "href": "/security",
        "en": "Security",
        "de": "Sicherheit",
        "it": "Sicurezza",
        "fr": "Securité",
        "es": "Seguridad"
    },
    bitcoin: {
        "href": "/bitcoin",
        "en": "Bitcoin",
        "de": "Bitcoin",
        "it": "Bitcoin",
        "fr": "Bitcoin",
        "es": "Bitcoin"
    }
}


export  {pages, btn}