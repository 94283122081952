import React from 'react'
import Cookies from 'universal-cookie';
import { useContext } from 'react';
import { langContext } from '../helper/langcontext';
import casino from '../config';

export default function Instantplay(props) {
    const lang = useContext(langContext)

    function getQueryVariable() {
        const urlParams = new URLSearchParams(window.location.search)
        const cookies = new Cookies();
        const strictCheck = varName => urlParams.has(varName) && urlParams.get(varName).trim() !== ''
        if(strictCheck("t")) cookies.set('tra_tracker',urlParams.get("t"));
        if(strictCheck("btag")) cookies.set('tra_btag',urlParams.get("btag"));
        
      }
      getQueryVariable();
      
      let allCookies = document.cookie.split(';')
      let trackerId = [];
      let btag = [];
      for (let i = 0; i < allCookies.length; i++) {
          const pair = allCookies[i].split('=');
          if (pair[0].trim() ==="tra_tracker") {
            if(pair[1]!==''|| typeof pair[1]!== "undefined"){
               trackerId.splice(0,1,pair[1]);
            }
          }
          if (pair[0].trim() === "tra_btag") {
            if(pair[1]!==''|| typeof pair[1]!== "undefined"){
              btag.splice(0,1,pair[1]);
           }
          }
      }
      
      // console.log(trackerId)
      // console.log(btag)
      // console.log(props.extraUrlParams)
    const checkReg = (x) => {
          if(x!== undefined){
              return x
          }
          return ""
    }

    let casinocontroller = "//www.casinocontroller.com/"+ casino.rival.sortName+"/engine/EmbedGame/EmbedGame.php?";
    let mode = "mode=lobby&"
    let langUrl = "lang=" + lang.language+ '&';
    let bTag = btag[0] ? `btag=${btag[0]}&` : '';
    let T = trackerId[0] ? `t=${trackerId[0]}&` : '';
    let id = "id=" + casino.rival.id + '&';
    // let returnUrl ="returnUrl=//localhost:3000";
    let returnUrl ="returnUrl=//" + window.location.host;
    const URL = casinocontroller + mode + langUrl + bTag + T + id + returnUrl + checkReg(props.extraUrlParams)
 
    return (

        <iframe
            title={"casino"}
            src={URL}
            style={{ width: '100%', height: '100vh' }}
            frameBorder="0"
            scrolling="No"
            id="embedded_game"
            name="embedded_game">
        </iframe>
    )
}
 