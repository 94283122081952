import React from "react";
import casino from "../../config";
const cookie = {
    "en": {
        sections: [{title: "Cookie policy", content: []}, {
            title: "What are cookies?",
            content: ["Cookies are small text files with a unique identifier that are sent from a web server to a web browser of a device (computer, tablet or mobile phone). These files are stored on the device’s hard drive. Every website can send its own cookies to a web browser if the web browser’s settings allow it."]
        }, {
            title: "How do we use cookies?",
            content: ["When you access our website and engage with our content, we use cookies that are required for providing our services. In case cookies are not approved by the user, our services may not be available in full or easy to access. Some cookies provide security that is required in order to comply with some regulatory requirements or security requirements, including for online payments.", "Our cookies are used to adapt our services for the user by remembering the user’s preferences and settings. That way, interaction between users and the services is more efficient and smoother. In addition, cookies enable us to adjust the relevant advertisements to the user.", "In order to understand your use of our website, mobile applications and services, and in order to enhance and adjust their performance, we use first party and third-party cookies, web beacons, ad tags, device identifiers and other similar technologies."]
        }, {
            title: "We use functionality cookies",
            content: ["We use functionality cookies to remember your preferences on our websites, so you can receive personal features. If you choose to delete these cookies from your browser, we will not have information about your log-in details and preferences, and we might not be able to provide you with the content that you requested."]
        }, {
            title: "We use performance cookies",
            content: ["Performance cookies allow us to reduce the network bandwidth by not resending data that already exists on your device. The information that these cookies collect is anonymous. Not allowing us to use these cookies will make us request data in each of your visits to our website."]
        }, {
            title: "We use cookies for advertising purposes",
            content: ["Using third party cookies allow us to identify your interests and target advertisements that better suit your taste. The third-party companies that we work with are authorized to place third party cookies or use other technologies on our website upon your consent to us. If you choose not to allow these cookies, the advertising you will receive will be less adjusted to you."]
        }, {
            title: "Managing and tracking cookies settings",
            content: ["There are several ways to control cookies settings. Please note that by deleting or blocking cookies some of the features of the website may not work properly.", "Most web browsers provide general information about cookies, enable you to see what cookies are stored on your device, allow you to delete, block, or allow cookies for specific websites or for all websites. Note that your settings apply to the specific browser or device that you are using. You can control cookies from your browser, information is usually found under the “Help” section of the browser.", "In order to enjoy all the features on our website, we recommend our users to allow cookies."]
        }, {
            title: "Contact us",
            content: [<>For additional information about cookies, please contact us at:<a href={"mailto:"+casino.support_email} >{casino.support_email}</a> </>]
        }]
    }, "fr": {
        sections: [{title: "Politique de cookie", content: []}, {
            title: "Que sont les cookies?",
            content: ["Les cookies sont de petits fichiers texte avec un identifiant unique qui sont envoyés d'un serveur Web au navigateur Web d'un appareil (ordinateur, tablette ou téléphone mobile). Ces fichiers sont stockés sur le disque dur de l'appareil. Chaque site Web peut envoyer ses propres cookies à un navigateur Web si les paramètres du navigateur Web le permettent."]
        }, {
            title: "Comment utilisons-nous les cookies?",
            content: ["Lorsque vous accédez à notre site Web et que vous vous engagez avec notre contenu, nous utilisons des cookies qui sont nécessaires pour fournir nos services. Dans le cas où les cookies ne sont pas approuvés par l'utilisateur, nos services peuvent ne pas être disponibles dans leur intégralité ou facilement accessibles. Certains cookies offrent une sécurité qui est nécessaire pour se conformer à certaines exigences réglementaires ou exigences de sécurité, y compris pour les paiements en ligne.", "Nos cookies sont utilisés pour adapter nos services à l'utilisateur en se souvenant des préférences et des paramètres de l'utilisateur. De cette façon, l'interaction entre les utilisateurs et les services est plus efficace et plus fluide. De plus, les cookies nous permettent d'ajuster les publicités pertinentes à l'utilisateur.", "Afin de comprendre votre utilisation de notre site Web, de nos applications et services mobiles, et afin d'améliorer et d'ajuster leurs performances, nous utilisons des cookies propriétaires et tiers, des balises Web, des tags publicitaires, des identifiants d'appareil et d'autres technologies similaires."]
        }, {
            title: "Nous utilisons des cookies de fonctionnalité",
            content: ["Nous utilisons des cookies de fonctionnalité pour mémoriser vos préférences sur nos sites Web, afin que vous puissiez recevoir des fonctionnalités personnelles. Si vous choisissez de supprimer ces cookies de votre navigateur, nous ne disposerons pas d'informations sur vos informations de connexion et vos préférences, et nous ne serons peut-être pas en mesure de vous fournir le contenu que vous avez demandé."]
        }, {
            title: "Nous utilisons des cookies de performance",
            content: ["Les cookies de performance nous permettent de réduire la bande passante du réseau en ne renvoyant pas les données qui existent déjà sur votre appareil. Les informations que ces cookies collectent sont anonymes. Ne pas nous autoriser à utiliser ces cookies nous obligera à demander des données à chacune de vos visites sur notre site Web."]
        }, {
            title: "Nous utilisons des cookies à des fins publicitaires",
            content: ["L'utilisation de cookies tiers nous permet d'identifier vos centres d'intérêt et de cibler les publicités qui correspondent le mieux à vos goûts. Les sociétés tierces avec lesquelles nous travaillons sont autorisées à placer des cookies tiers ou à utiliser d'autres technologies sur notre site Web avec votre consentement. Si vous choisissez de ne pas autoriser ces cookies, la publicité que vous recevrez sera moins adaptée à vous."]
        }, {
            title: "Gestion et suivi des paramètres des cookies",
            content: ["Il existe plusieurs façons de contrôler les paramètres des cookies. Veuillez noter qu'en supprimant ou en bloquant les cookies, certaines fonctionnalités du site Web peuvent ne pas fonctionner correctement.", "La plupart des navigateurs Web fournissent des informations générales sur les cookies, vous permettent de voir quels cookies sont stockés sur votre appareil, vous permettent de supprimer, de bloquer ou d'autoriser les cookies pour des sites Web spécifiques ou pour tous les sites Web. Notez que vos paramètres s'appliquent au navigateur ou à l'appareil spécifique que vous utilisez. Vous pouvez contrôler les cookies de votre navigateur, les informations se trouvent généralement dans la section «Aide» du navigateur.", "Afin de profiter de toutes les fonctionnalités de notre site Web, nous recommandons à nos utilisateurs d'autoriser les cookies."]
        }, {
            title: "Nous contacter",
            content: [<>Pour plus d'informations sur les cookies, veuillez nous contacter à: <a href={"mailto:"+casino.support_email} >{casino.support_email}</a> </>]
        }]
    }, "de": {
        sections: [{title: "Cookie-politik", content: []}, {
            title: "Was sind cookies?",
            content: ["Cookies sind kleine Textdateien mit einer eindeutigen Kennung, die von einem Webserver an einen Webbrowser eines Geräts (Computer, Tablet oder Mobiltelefon) gesendet werden. Diese Dateien werden auf der Festplatte des Geräts gespeichert. Jede Website kann ihre eigenen Cookies an einen Webbrowser senden, wenn die Einstellungen des Webbrowsers dies zulassen."]
        }, {
            title: "Wie verwenden wir cookies?",
            content: ["Wenn Sie auf unsere Website zugreifen und sich mit unseren Inhalten beschäftigen, verwenden wir Cookies, die für die Bereitstellung unserer Dienste erforderlich sind. Falls Cookies vom Benutzer nicht genehmigt werden, sind unsere Dienste möglicherweise nicht vollständig oder nur erschwert zugänglich. Einige Cookies bieten Sicherheit, die erforderlich ist, um bestimmte gesetzliche Anforderungen oder Sicherheitsanforderungen zu erfüllen, einschließlich für Online-Zahlungen.", "Unsere Cookies werden verwendet, um unsere Dienste für den Benutzer anzupassen, indem die Einstellungen und Einstellungen des Benutzers gespeichert werden. Auf diese Weise ist die Interaktion zwischen Benutzern und den Diensten effizienter und reibungsloser. Darüber hinaus ermöglichen uns Cookies, relevante Anzeigen an den Benutzer anzupassen.", "Um Ihre Nutzung unserer Website, mobiler Anwendungen und Dienste zu verstehen und deren Leistung zu verbessern und anzupassen, verwenden wir Cookies von Erst- und Drittanbietern, Web Beacons, Anzeigen-Tags, Gerätekennungen und andere ähnliche Technologien."]
        }, {
            title: "Wir verwenden funktionalitäts-cookies",
            content: ["Wir verwenden Funktionscookies, um Ihre Einstellungen auf unseren Websites zu speichern, damit Sie persönliche Funktionen erhalten können. Wenn Sie diese Cookies aus Ihrem Browser löschen, verfügen wir nicht über Informationen zu Ihren Anmeldedaten und Einstellungen und können Ihnen möglicherweise nicht den von Ihnen angeforderten Inhalt zur Verfügung stellen."]
        }, {
            title: "Wir verwenden performance cookies",
            content: ["Mithilfe von Leistungscookies können wir die Netzwerkbandbreite reduzieren, indem wir keine Daten erneut senden, die bereits auf Ihrem Gerät vorhanden sind. Die Informationen, die diese Cookies sammeln, sind anonym. Wenn wir diese Cookies nicht verwenden dürfen, fordern wir bei jedem Ihrer Besuche auf unserer Website Daten an."]
        }, {
            title: "WIr verwenden cookies für werbezwecke",
            content: ["Durch die Verwendung von Cookies von Drittanbietern können wir Ihre Interessen identifizieren und Anzeigen schalten, die Ihrem Geschmack besser entsprechen. Die Drittunternehmen, mit denen wir zusammenarbeiten, sind berechtigt, mit Ihrer Zustimmung an uns, Cookies von Drittanbietern zu platzieren oder andere Technologien auf unserer Website zu verwenden. Wenn Sie diese Cookies nicht zulassen, wird die Werbung, die Sie erhalten, weniger an Sie angepasst."]
        }, {
            title: "Cookies-einstellungen verwalten und verfolgen",
            content: ["Es gibt verschiedene Möglichkeiten, um die Cookie-Einstellungen zu steuern. Bitte beachten Sie, dass durch das Löschen oder Blockieren von Cookies einige Funktionen der Website möglicherweise nicht ordnungsgemäß funktionieren.", "Die meisten Webbrowser bieten allgemeine Informationen zu Cookies, ermöglichen es Ihnen, zu sehen, welche Cookies auf Ihrem Gerät gespeichert sind, und ermöglichen es Ihnen, Cookies für bestimmte Websites oder für alle Websites zu löschen, zu blockieren oder zuzulassen. Beachten Sie, dass Ihre Einstellungen für den jeweiligen Browser oder das von Ihnen verwendete Gerät gelten. Sie können Cookies von Ihrem Browser aus steuern. Informationen finden Sie normalerweise im Abschnitt \"Hilfe\" Ihres Browsers.", "Um alle Funktionen unserer Website nutzen zu können, empfehlen wir unseren Benutzern, Cookies zuzulassen."]
        }, {
            title: "Kontakt",
            content: [<>Für weitere Informationen zu Cookies kontaktieren Sie uns bitte unter: <a href={"mailto:"+casino.support_email} >{casino.support_email}</a> </>]
        }]
    }, "es": {
        sections: [{title: "Política de cookies", content: []}, {
            title: "¿Qué son las cookies?",
            content: ["Las cookies son pequeños archivos de texto con un identificador único que se envían desde un servidor web a un navegador web de un dispositivo (computadora, tableta o teléfono móvil). Estos archivos se almacenan en el disco duro del dispositivo. Cada sitio web puede enviar sus propias cookies a un navegador web si la configuración del navegador lo permite."]
        }, {
            title: "¿Cómo usamos las cookies?",
            content: ["Cuando accede a nuestro sitio web y se involucra con nuestro contenido, utilizamos cookies que son necesarias para proporcionar nuestros servicios. En caso de que el usuario no apruebe las cookies, es posible que nuestros servicios no estén disponibles en su totalidad o de fácil acceso. Algunas cookies proporcionan la seguridad necesaria para cumplir con algunos requisitos reglamentarios o requisitos de seguridad, incluidos los pagos en línea.", "Nuestras cookies se utilizan para adaptar nuestros servicios para el usuario al recordar las preferencias y configuraciones del usuario. De esa forma, la interacción entre los usuarios y los servicios es más eficiente y fluida. Además, las cookies nos permiten ajustar los anuncios relevantes para el usuario.", "Para comprender su uso de nuestro sitio web, aplicaciones y servicios móviles, y para mejorar y ajustar su rendimiento, utilizamos cookies propias, de terceros, \"web beacons\" para conteo de visitas, etiquetas publicitarias, identificadores de dispositivos y otras tecnologías similares."]
        }, {
            title: "Utilizamos cookies de funcionalidad",
            content: ["Utilizamos cookies de funcionalidad para recordar sus preferencias en nuestros sitios web, para que pueda recibir características personales. Si elige eliminar estas cookies de su navegador, no tendremos información sobre sus detalles y preferencias de inicio de sesión, y es posible que no podamos proporcionarle el contenido que solicitó."]
        }, {
            title: "Utilizamos cookies de rendimiento",
            content: ["Las cookies de rendimiento nos permiten reducir el ancho de banda de la red al no reenviar datos que ya existen en su dispositivo. La información que recopilan estas cookies es anónima. No permitirnos usar estas cookies nos hará solicitar datos en cada una de sus visitas a nuestro sitio web."]
        }, {
            title: "Utilizamos cookies para fines publicitarios",
            content: ["El uso de cookies de terceros nos permite identificar sus intereses y publicidades específicas que mejor se adapten a sus gustos. Las empresas de terceros con las que trabajamos están autorizadas a colocar cookies de terceros o utilizar otras tecnologías en nuestro sitio web con su consentimiento. Si elige no permitir estas cookies, la publicidad que recibirá se ajustará menos a usted."]
        }, {
            title: "Gestión y seguimiento de la configuración de cookies",
            content: ["Hay varias formas de controlar la configuración de las cookies. Tenga en cuenta que al eliminar o bloquear las cookies, algunas de las características del sitio web pueden no funcionar correctamente.", "La mayoría de los navegadores web proporcionan información general sobre las cookies, le permiten ver qué cookies se almacenan en su dispositivo, le permiten eliminar, bloquear o permitir cookies para sitios web específicos o para todos los sitios web. Tenga en cuenta que su configuración se aplica al navegador o dispositivo específico que está utilizando. Puede controlar las cookies desde su navegador, la información generalmente se encuentra en la sección \"Ayuda\" del navegador.", "Para disfrutar de todas las funciones de nuestro sitio web, recomendamos a nuestros usuarios que permitan las cookies."]
        }, {
            title: "Contáctenos",
            content: [<>Para obtener información adicional sobre las cookies, contáctenos en: <a href={"mailto:"+casino.support_email} >{casino.support_email}</a> </>]
        }]
    }, "it": {
        sections: [{title: "Gestione dei cookie", content: []}, {
            title: "Cosa sono i cookie?",
            content: ["I cookie sono piccoli file di testo con un identificatore univoco che vengono inviati da un server Web a un browser Web di un dispositivo (computer, tablet o telefono cellulare). Questi file sono memorizzati sul disco rigido del dispositivo. Ogni sito Web può inviare i propri cookie a un browser Web se le impostazioni del browser Web lo consentono."]
        }, {
            title: "Come usiamo i cookie?",
            content: ["Quando accedi al nostro sito Web e interagisci con i nostri contenuti, utilizziamo i cookie necessari per fornire i nostri servizi. Nel caso in cui i cookie non vengano approvati dall'utente, i nostri servizi potrebbero non essere disponibili per intero o con facile accesso. Alcuni cookie forniscono la sicurezza necessaria per soddisfare alcuni requisiti normativi o di sicurezza, inclusi i pagamenti online.", "I nostri cookie vengono utilizzati per adattare i nostri servizi all'utente ricordando le preferenze e le impostazioni dell'utente. In questo modo, l'interazione tra utenti e servizi è più efficiente e fluida. Inoltre, i cookie ci consentono di adattare all'utente le pubblicità pertinenti.", "Al fine di comprendere l'utilizzo del nostro sito Web, delle applicazioni e dei servizi mobili e per migliorare e adattare le loro prestazioni, utilizziamo cookie proprietari e di terze parti, web beacon, tag annuncio, identificatori di dispositivi e altre tecnologie simili."]
        }, {
            title: "Usiamo cookie di funzionalità",
            content: ["Utilizziamo i cookie di funzionalità per ricordare le tue preferenze sui nostri siti Web, in modo da poter ricevere funzionalità personali. Se scegli di eliminare questi cookie dal tuo browser, non avremo informazioni sui dettagli e le preferenze di accesso e potremmo non essere in grado di fornirti il contenuto richiesto."]
        }, {
            title: "Usiamo cookie prestazionali",
            content: ["I cookie per le prestazioni ci consentono di ridurre la larghezza di banda della rete non rinviando i dati già esistenti sul dispositivo. Le informazioni raccolte da questi cookie sono anonime. Non consentirci di utilizzare questi cookie ci farà richiedere dati in ciascuna delle vostre visite al nostro sito Web."]
        }, {
            title: "Usiamo i cookie a fini di pubblicità",
            content: ["L'uso di cookie di terze parti ci consente di identificare i tuoi interessi e indirizzare gli annunci pubblicitari più adatti ai tuoi gusti. Le società di terze parti con cui collaboriamo sono autorizzate a inserire cookie di terze parti o utilizzare altre tecnologie sul nostro sito Web previo consenso dell'utente. Se scegli di non consentire questi cookie, la pubblicità che riceverai sarà meno adeguata a te."]
        }, {
            title: "Gestione e tracciamento delle impostazioni dei cookie",
            content: ["Esistono diversi modi per controllare le impostazioni dei cookie. Si noti che eliminando o bloccando i cookie alcune delle funzionalità del sito Web potrebbero non funzionare correttamente.", "La maggior parte dei browser Web fornisce informazioni generali sui cookie, consente di vedere quali cookie sono memorizzati sul dispositivo, consente di eliminare, bloccare o consentire i cookie per siti Web specifici o per tutti i siti Web. Si noti che le impostazioni si applicano al browser o dispositivo specifico che si sta utilizzando. Puoi controllare i cookie dal tuo browser, le informazioni di solito si trovano nella sezione \"Guida\" del browser.", "Per usufruire di tutte le funzionalità del nostro sito Web, consigliamo ai nostri utenti di consentire i cookie."]
        }, {
            title: "Contattaci",
            content: [<>Per ulteriori informazioni sui cookie, ti preghiamo di contattarci all'indirizzo <a href={"mailto:"+casino.support_email} >{casino.support_email}</a> </>]
        }]
    }
}
export default cookie