import React from "react";
import casino from "../../config";
let email_var = "mailto:"+casino.support_email
const privacy = {
    "en": {
        sections: [{
            title: "PRIVACY POLICY",
            content: ["Here at "+casino.name+" Casino we do not sell, trade or rent your personal information to other parties. While we may provide reputable parties with statistics about our player demographics, sales, internet traffic and other site information, this information does not include the personal details of any of our players."]
        }, {
            title: "Choice / Opt-Out",
            content: [<>{casino.name} Casino players have the option to opt out of receiving communications (via email, home mailing address, etc.) from our partners, as well as any promotional communication. To do so, simply send an email to <a href={email_var}>{casino.support_email}</a> to opt out of receiving such communications.</>]
        }, {
            title: "Amendments To Our Privacy Policy",
            content: ["If we change or amend our privacy policy in any way we will immediately post the changes here. We strive to keep our players informed with the most up-to date details about what information we collect and how we use it."]
        }, {
            title: "Conclusion",
            content: ["Here at "+casino.name+" casino we make your privacy our top priority. The information we collect from our players is used solely to facilitate the exchange of funds and to enhance your overall gaming experience. None of your personal information will be sold, traded or rented to third parties."]
        }, {
            title: "Contacting Us",
            content: [<>Do you have a question about the privacy policy, our practices, or anything else? Feel free to contact us at <a href={email_var}>{casino.support_email}</a>. We are more than happy to help!</>]
        }]
    }, "fr": {
        sections: [{
            title: "POLITIQUE DE CONFIDENTIALITÉ",
            content: ["Here at "+casino.name+" Casino we do not sell, trade or rent your personal information to other parties. While we may provide reputable parties with statistics about our player demographics, sales, internet traffic and other site information, this information does not include the personal details of any of our players."]
        }, {
            title: "Choix / Opt-Out",
            content: [<>{casino.name} Casino players have the option to opt out of receiving communications (via email, home mailing address, etc.) from our partners, as well as any promotional communication. To do so, simply send an email to <a href={email_var}>{casino.support_email}</a> to opt out of receiving such communications.</>]
        }, {
            title: "Conclusion:",
            content: ["Here at "+casino.name+" casino we make your privacy our top priority. The information we collect from our players is used solely to facilitate the exchange of funds and to enhance your overall gaming experience. None of your personal information will be sold, traded or rented to third parties."]
        }, {
            title: "Nous Contacter:",
            content: [<>Vous avez une question à propos de la politique de confidentialité, nos pratiques, ou autre chose? N'hésitez pas à nous contacter à <a href={email_var}>{casino.support_email}</a>. Nous sommes plus qu'heureux de vous aider!</>]
        }]
    }, "de": {
        sections: [{
            title: "PRIVATSPHÄRE",
            content: ["Hier bei "+casino.name+" verkaufen, handeln oder vermieten wir Ihre persönlichen Daten nicht an andere Parteien. Während wir seriösen Parteien möglicherweise Statistiken über unsere Spielerdemografie, Verkäufe, Internetverkehr und andere Site-Informationen zur Verfügung stellen, enthalten diese Informationen nicht die persönlichen Daten eines unserer Spieler."]
        }, {
            title: "Auswahl / Opt-Out",
            content: [<>{casino.name}-Spieler haben die Möglichkeit, den Empfang von Mitteilungen (per E-Mail, Postanschrift usw.) von unseren Partnern sowie von Werbemitteilungen abzulehnen. Senden Sie dazu einfach eine E-Mail an <a href={email_var}>{casino.support_email}</a>, um den Empfang solcher Mitteilungen abzulehnen.</>]
        }, {
            title: "Änderungen Unserer Datenschutzrichtlinie",
            content: ["Wenn wir unsere Datenschutzrichtlinie in irgendeiner Weise ändern oder ergänzen, werden wir die Änderungen sofort hier veröffentlichen. Wir bemühen uns, unsere Spieler auf dem neuesten Stand zu halten, welche Informationen wir sammeln und wie wir sie verwenden."]
        }, {
            title: "Fazit",
            content: ["Hier bei "+casino.name+" legen wir größten Wert auf Ihre Privatsphäre. Die Informationen, die wir von unseren Spielern sammeln, werden ausschließlich dazu verwendet, den Geldwechsel zu erleichtern und Ihr Gesamtspielerlebnis zu verbessern. Keine Ihrer persönlichen Daten wird an Dritte verkauft, gehandelt oder vermietet."]
        }, {
            title: "Kontact",
            content: [<>Haben Sie eine Frage zu den Datenschutzbestimmungen, unseren Praktiken oder zu anderen Themen? Kontaktieren Sie uns unter <a href={email_var}>{casino.support_email}</a>. Wir helfen Ihnen gerne weiter!</>]
        }]
    }, "es": {
        sections: [{
            title: "POLÍTICA DE PRIVACIDAD",
            content: ["Aquí en "+casino.name+" casino no vendemos, comerciamos o alquilamos su información personal a terceros. Si bien podemos proporcionar estadísticas a las partes acreditadas sobre los datos demográficos , las ventas, el tráfico de Internet de nuestros jugadores y otra información del sitio, esta información no incluye los datos personales de cualquiera de nuestros jugadores."]
        }, {
            title: "Elección / Opt-Out",
            content: [<>Los jugadores de {casino.name} casino tienen la opción de elegir no recibir comunicaciones (a través de correo electrónico, dirección de correo postal, etc.) de nuestros socios, así como cualquier notificación promocional. Para hacerlo, simplemente envíe un correo electrónico a <a href={email_var}>{casino.support_email}</a> y opte por no recibir dichas comunicaciones.</>]
        }, {
            title: "Modificaciones En Nuestra Política De Privacidad",
            content: ["Si cambiamos o modificamos nuestra política de privacidad, publicaremos los cambios de manera inmediata aquí mismo. Nos esforzamos por mantener informados a nuestros jugadores con los detalles más actualizados sobre qué información recopilamos y cómo la usamos.", "Aquí en el casino "+casino.name+" hacemos de su privacidad la máxima prioridad. La información que obtenemos de nuestros jugadores se utiliza únicamente para facilitar el intercambio de recursos y para mejorar su experiencia de juego en general. Ninguno de sus datos personales serán vendidos, comercializados o alquilados a terceros."]
        }, {
            title: "Contáctanos",
            content: [<>¿Tiene alguna pregunta sobre la política de privacidad, nuestras prácticas, o cualquier otra cosa? No dude en contactarse con nosotros en <a href={email_var}>{casino.support_email}</a>. ¡Con gusto le ayudaremos en lo que necesite!</>]
        }]
    }, "it": {
        sections: [{title: "PRIVACY", "content": [""]}, {
            title: "Informativa Sulla Privacy",
            content: ["Qui a "+casino.name+" Casinò non vendiamo, scambiamo o affittiamo le vostre informazioni personali a terzi. Mentre possiamo fornire a terzi riconosciuti statistiche sui dati demografici dei nostri giocatori, le vendite, il traffico Internet e altre informazioni del sito, queste informazioni non includono i dettagli personali di qualsiasi dei nostri giocatori."]
        }, {
            title: "Scelta / Opt-Out",
            content: [<>Giocatori {casino.name} Casinò hanno la possibilità di scegliere di non ricevere comunicazioni (via email, indirizzo postale di casa, ecc) dai nostri partner, così come qualsiasi comunicazione promozionale. Per farlo, è sufficiente inviare una mail a <a href={email_var}>{casino.support_email}</a> di scegliere di non ricevere tali comunicazioni.</>]
        }, {
            title: "Modifiche Alla Nostra Politica Sulla Privacy",
            content: ["Se cambieremo o modificheremo la nostra politica sulla privacy in qualsiasi modo pubblicheremo immediatamente le modifiche qui. Ci sforziamo di mantenere i nostri giocatori informati con i dettagli più aggiornati su quali informazioni raccogliamo e come le usiamo."]
        }, {
            title: "Conclusione",
            content: ["Qui al casinò "+casino.name+" mettiamo la privacy in priorità assoluta. Le informazioni che raccogliamo dai nostri giocatori vengono utilizzate esclusivamente per facilitare lo scambio di fondi e per migliorare la vostra esperienza di gioco in generale. Nessuno dei suoi dati personali saranno venduti, scambiati o affittati a terzi."]
        }, {
            title: "Per Contattarci",
            content: [<>Ha una domanda circa la tutela della privacy, le nostre pratiche, o qualsiasi altra cosa? Non esiti a contattarci al <a href={email_var}>{casino.support_email}</a>. Siamo più che felici di aiutarvi!</>]
        }]
    }
}
export default privacy