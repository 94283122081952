import React, {useState} from "react";
import {langContext} from "./helper/langcontext";
import Header from "./components/header/header";
import {BrowserRouter as Router, Routes, Route, Redirect, Switch} from "react-router-dom";
import Games from "./pages/games";
import AboutUs from "./pages/aboutUs";
import Support from "./pages/support";
import Instantplay from "./pages/instantplay";
import casino from "./config";
import Faq from "./pages/faq";
import Aml from "./pages/aml";
import Banking from "./pages/banking";
import Cookie from "./pages/cookie";
import Privacy from "./pages/privacy";
import Responsible from "./pages/responsible";
import Bitcoin from "./pages/bitcoin";
import Security from "./pages/security";
import Terms from "./pages/terms";
import Promotions from "./pages/promotions";
import Game_separator from "./components/game_separator/game_separator";
import Leader_board from "./pages/leader_board";
import Not_found from "./pages/not_found";
import AlwaysOne from "./helper/alwaysone";


function App() {
    let defaultLang = localStorage.getItem("language");
    if (defaultLang === null || undefined || "") {
        defaultLang = "en";
    }
    let [lang, setLang] = useState(defaultLang);
    const [load, setLoad] = useState(true)
    let gamesPaths = [
        "/games/all_games",
        "/games/jackpot",
        "/games/rival",
        "/games/betsoft",
        "/games/saucify",
        "/games/arrow_s_edge",
        "/games/dragon",
        "/games/fresh_deck",
        "/games/qora",
        "/games/live_casino",
        "/games/slots",
        "/games/new_games",
        "/games/popular",
        "/games/top",
        "/games/table"
    ]
    let path = window.location.pathname
    // console.log("--loading", load)
    return (
        <langContext.Provider
            value={{
                language: lang,
                langSetFunc: setLang,
            }}
        >
            <div onLoad={() => {
                setLoad(false)
            }} className="App" style={{display: "flex", flexDirection: "column"}}>
                <Router>
                    {gamesPaths.includes(path) ? <Header/> : null}
                    <Switch>
                        <Route path="/:lang([a-z]{2})">
                            {({match, location}) => {
                                const params = match ? match.params : {};
                                if (params.lang && (['en', 'it', 'fr', 'de', 'es']).includes(params.lang)) {
                                    setLang(params.lang)
                                    return <Redirect to={location.pathname.slice(3)}/>
                                }
                                return <Redirect to="/"/>
                            }}
                        </Route>
                        <Route path="/" exact component={Games}/>
                        <Route path="/games" exact component={Games}/>
                        {/*<Route path="/play" exact component={Games}/>*/}
                        <Route path="/play">
                             <Redirect to="/" />
                        </Route>
                        <Route path="/aml" exact component={Aml}/>
                        <Route path="/leader_board" exact component={Leader_board}/>
                        <Route path={gamesPaths} exact component={Game_separator}/>
                        <Route path="/banking" exact component={Banking}/>
                        <Route path="/cookie_policy" exact component={Cookie}/>
                        <Route path="/bitcoin" exact component={Bitcoin}/>
                        <Route path="/privacy" exact component={Privacy}/>
                        <Route path="/responsible_gaming" exact component={Responsible}/>
                        <Route path="/security" exact component={Security}/>
                        <Route path="/about" exact component={AboutUs}/>
                        <Route path="/promotions" exact component={Promotions}/>
                        <Route path="/terms" exact component={Terms}/>
                        <Route path="/support" exact component={Support}/>
                        <Route path="/faq" exact component={Faq}/>
                        <Route path="/instantplay" exact component={Instantplay}/>
                        <Route path="/alwaysone" exact component={AlwaysOne}/>
                        <Route path="/register"> <Instantplay
                            extraUrlParams={casino.instantPlayRegister ? '#registration' : ""}/></Route>
                        <Route component={Not_found}/>
                    </Switch>
                </Router>
            </div>
        </langContext.Provider>
    );
}

export default App;
