import React, {useState, useRef, useEffect} from "react";
import arrow from "./img.png"
import {games_data} from "../../games_data/games";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {pages} from "../../languages/buttons";

export default function Slider({id, number}) {
    const path = "./games_images/350x350/"

    let data_to_render = (string) => {
        switch (string) {
            case 'popular':
                return games_data.filter((item)=> item['pop_rank']<15);
                break;
            case 'new':
                return games_data.filter((item)=>item['release_date'] && item['release_date'].includes('2021'));
                break;
            default:
                return games_data.filter((item) => item.category_id === string)

    }};

    const [prevEl, setPrevEl] = useState(null)
    const [nextEl, setNextEl] = useState(null)
    const [btnActive, setBtnActive] = useState(false)
    const [uniq, setUniq] = useState(0)
    let [width, setWidth] = useState(window.innerWidth);

    /* A hook that is called when the component is mounted and unmounted. */
    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);

    /* Hiding the duplicated slides. */
    useEffect(() => {
        document.getElementsByClassName("swiper-slide swiper-slide-duplicate swiper-slide-prev")[number].style.visibility = "hidden"

    }, [prevEl, nextEl]);
    let classN = "swiper_item_carousel"+ " " + number

    return (
        <div className="swiper_carousel" onMouseEnter={() => {
            setBtnActive(true)
        }} onMouseLeave={() => {
            setBtnActive(false)
        }}>
            {/*{document.getElementsByClassName("swiper_carousel")}*/}
            <Swiper
                breakpoints={{
                    // when window width is >= 320px
                    300: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 5
                    },
                    501: {
                        slidesPerView: 3,
                        slidesPerGroup: 2,
                        spaceBetween: 5
                    },

                    1100: {
                        slidesPerView: 5,
                        slidesPerGroup: 2,
                        spaceBetween: 5
                    },
                    2500: {
                        slidesPerView: 6,
                        slidesPerGroup: 3,
                        spaceBetween: 5
                    },
                    3000: {
                        slidesPerView: 8,
                        slidesPerGroup: 3,
                        spaceBetween: 5
                    },
                }}


                loop={true}
                loopFillGroupWithBlank={false}
                // navigation={true}
                //pagination={pagination}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                navigation={{prevEl, nextEl}}
            >

                {data_to_render(id).map((game, key) => {
                    return (
                        <SwiperSlide>

                            <div className={classN}>
                                <img onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = pages.login.href;
                                }} alt={key} src={path + game.tile_filename}/>
                            </div>

                        </SwiperSlide>
                    )
                })}


            </Swiper>


            <div style={uniq === number ? {opacity: "flex"} : {display: "none"}}
                 className={btnActive ? "arrow basic prev active" : "arrow basic prev"} onClick={() => {
            }} ref={(node) => setPrevEl(node)}><img src={arrow}/></div>

            <div onClick={() => {
                setUniq(number);
                [...document.getElementsByClassName(classN)].forEach((x)=>{
                    x.parentNode.style.visibility = "visible"
                });

            }} className={btnActive ? "arrow basic next active" : "arrow basic next"} ref={(node) => setNextEl(node)}>
                <img src={arrow}/></div>


        </div>
    )
}
