import React, {useState, useEffect} from 'react'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {games_data} from "../../games_data/games";
import arrow from "../slider/img.png";
import {pages} from "../../languages/buttons";
import {promos} from "../../contents";

export default function Jackpot_slider() {

    const [showStep, setShowStep] = useState(1);

    useEffect(() => {
        const deleteNumb = promos.jackpot.length - 1
        const ShowStepper = setInterval(() => {
            if (showStep >= promos.jackpot.length) {
                setShowStep(showStep - deleteNumb );
            } else {
                setShowStep(showStep + 1);
            }

        }, 4000);

        return () => clearInterval(ShowStepper);
    });
    const path = "./games_images/350x350/"
    let data_to_render = (cat_id) => {
        return games_data.filter(
            (item) => item.category_id === cat_id
        )
    };

    const [prevEl, setPrevEl] = useState(null)
    const [nextEl, setNextEl] = useState(null)
    // console.log(prevEl);

    // console.log(data.length);
    return (
        <div className="jackpot_wrapper">
            <div className="jackpot_slider">
                <div className="jackpot_left">
                    {promos.jackpot.length !== ( 0 || undefined) && <div className="jackpot_inner">
                        {promos.jackpot.map((jackpot, key) => {
                            return showStep === key + 1 ?
                                <div className='jackpot_inner'>
                                    <span className='jackpot name'>{jackpot.name}</span>
                                    <span className='jackpot value'>{jackpot.value}</span>
                                    <span className='jackpot desc'>{jackpot.desc}</span>
                                </div> : null
                        })}

                    </div>}

                </div>

                <div className="jackpot_right">
                    <Swiper

                        breakpoints={{
                            // when window width is >= 320px
                            300: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                                spaceBetween: 5
                            },
                            501: {
                                slidesPerView: 3,
                                slidesPerGroup: 2,
                                spaceBetween: 5
                            },
                            800: {
                                slidesPerView: 4,
                                slidesPerGroup: 2,
                                spaceBetween: 5
                            },
                            1100: {
                                slidesPerView: 4,
                                slidesPerGroup: 2,
                                spaceBetween: 5
                            },
                            2500: {
                                slidesPerView: 6,
                                slidesPerGroup: 3,
                                spaceBetween: 5
                            },
                        }}


                        loop={true}
                        loopFillGroupWithBlank={true}
                        // navigation={true}
                        //pagination={pagination}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        navigation={{prevEl, nextEl}}
                    >
                        {data_to_render(34).reverse().map((game, key) => {
                            return (
                                <SwiperSlide>
                                    <div className="swiper_item_jackpot">
                                        <img onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = pages.login.href;
                                        }} alt="" src={path + game.tile_filename}/>
                                    </div>
                                </SwiperSlide>
                            )
                        })}


                    </Swiper>


                    <div className="arrow prev" ref={(node) => setPrevEl(node)}><img src={arrow}/></div>
                    <div className="arrow next" ref={(node) => setNextEl(node)}><img src={arrow}/></div>
                </div>
            </div>

        </div>
    )
}
