import React from "react";
import casino from "../../config";

const support = {
    "en": {
        title: "Support",
        content: ['At True Fortune Casino we ensure that all of our customers obtain the most out of their gaming experience. Our friendly support staff is standing by, round the clock, every day of the year. Don’t hesitate to get in touch if you have any questions regarding our games, promotions, welcome bonuses or any of our services. Our agents will be more than happy to respond to your inquiries and any concerns you may have'],
        sections: [{
            title: "Chat",
            content: ["With our live chat you can instantly get in touch with our support team. In order to contact a member of our support team, just click on the live chat button and you will immediately be connected to our support team.",
               ]
        }, {
            title: "Email",
            content: [<>Contact customer support at <a href={"mailto:"+casino.support_email} >{casino.support_email}</a>,<br/>For financial issues contact finance at <a href={"mailto:"+casino.support_documents} >{casino.support_documents}</a></>]
        }, {
            title: "Telephone",
            content: ["France (+33) (0)1 789 00368", "Spain (+34) 91 290 1420", "International (+1) 718 732 0154"]
        }]
    },
    "fr": {
        title: "Support",
        content: ['Sur le Casino True Fortune, nous nous assurons que tous nos clients obtiennent le meilleur parti de leur expérience de jeu. Notre amical support clientèle est prêt, à n\'importe quelle heure, tous les jours de l\'année. N\'hésitez pas à nous contacter si vous avez des questions concernant nos jeux, les promotions, les bonus de bienvenue ou un de nos services. Nos agents se feront un plaisir de répondre à vos demandes et/ou préoccupations que vous pourriez avoir .'],
        sections: [{
            title: "Chat",
            content: ["Avec notre chat en direct, vous pouvez instantanément entrer en contact avec notre équipe d'assistance. Pour contacter un membre de notre équipe de support, cliquez simplement sur le bouton de chat en direct et vous serez immédiatement connecté à notre équipe d'assistance.",
                ]
        }, {
            title: "Email",
            content: [<>Contactez le support clientèle à <a href={"mailto:"+casino.support_email} >{casino.support_email}</a>,<br/>Pour toutes questions financières contactez notre service financier à <a href={"mailto:"+casino.support_documents} >{casino.support_documents}</a></>]
        }, {
            title: "Telephone",
            content: ["France (+33) (0)1 789 00368", "Espagne(+34) 91 290 1420", "International (+1) 718 732 0154"]
        }]
    },
    "de": {
        title: "HILFE",
        content: ["Bei True Fortune sorgen wir dafür, dass all unsere Spieler stets das Beste vom Besten bekommen. Daher ist unser freundliches und kompetentes Kundenserviceteam das ganze Jahr für Sie da, rund um die Uhr. Kontaktieren Sie uns jederzeit, wenn Sie Fragen zu unseren Spielen, Promotionen, Willkommensangeboten oder unserer Spielbank haben. Unsere Kundenservicemitarbeiter stehen Ihnen bei allen Belangen jederzeit gerne zur Verfügung."],
        sections: [{
            title: "Chat",
            content: ["Durch unseren Live Chat können Sie sofort mit unserem Kundenservice-Team in Verbindung treten. Um einen unserer Kundenmitarbeiter zu kontaktieren, klicken Sie einfach auf “Live Chat“ und sofort werden Sie mit unserem Kundenservice verbunden.",
                ]
        }, {
            title: "Email",
            content: [<>Kontaktieren Sie unseren Kundenservice unter <a href={"mailto:"+casino.support_email} >{casino.support_email}</a>,<br/>Bei finanziellen Angelegenheiten kontaktieren Sie bitte <a href={"mailto:"+casino.support_documents} >{casino.support_documents}</a></>]
        }, {
            title: "Telefon",
            content: ["Frankreich (+33) (0)1 789 00368", "Spanien (+34) 91 290 1420", "International (+1) 718 732 0154"]
        }]
    },
    "es": {
        title: "SOPORTE",
        content: ['En el Casino True Fortune nos aseguramos de que todos nuestros clientes obtengan el máximo provecho de su experiencia de juego. Nuestro equipo de soporte está disponible y pendiente, durante todo el día, todos los días del año. No dude en contactarse si tiene alguna duda acerca de nuestros juegos, promociones, bonos de bienvenida o cualquiera de nuestros servicios. Nuestros agentes estarán más que felices de responder a sus preguntas y ante cualquier preocupación que pueda tener.'],
        sections: [{
            title: "Chat",
            content: ["Con nuestro chat en vivo puede contactarse instantáneamente con nuestro equipo de soporte.",
                ]
        }, {
            title: "Correo Electrónico",
            content: [<>Contactar con atención al cliente en <a href={"mailto:"+casino.support_email} >{casino.support_email}</a>,<br/>Por cuestiones financieras contacto finanzas en <a href={"mailto:"+casino.support_documents} >{casino.support_documents}</a></>]
        }, {
            title: "Teléfonos",
            content: ["Francia (+33) (0)1 789 00368", "España (+34) 91 290 1420", "Internacional (+1) 718 732 0154"]
        }]
    },
    "it": {
        title: "SUPPORTO",
        content: ['Nel Casino TrueFortune ci assiucuriamo che tutti i nostri giocatori ottengano il massimo dalla loro esperienza di gioco. Il nostro amichevole personale di supporto e\' in piedi 24 ore su 24, tutti i giorni dell\'anno. Non esitate a mettervi in contatto se avete domande sui nostri giochi, promozioni, bonus di benvenuto o qualsiasi dei nostri servizi. I nostri agenti saranno piu\' che felici di rispondere alle vostre richieste e preoccupazioni che potreste avere.'],
        sections: [{
            title: "Chat",
            content: ["Con la nostra chat dal vivo si puo' immediatamente mettersi in contatto con il nostro team di supporto. Per contattare un membro del nostro team di supporto e' sufficiente fare clic sul pulsante Chat Live e sarete subito collegati con il notro team di supporto.",
                ]
        }, {
            title: "Email",
            content: [<>Contatta l'assistenza clienti <a href={"mailto:"+casino.support_email} >{casino.support_email}</a>,<br/>Per problemi finanziari conttattare il dip. finanziario <a href={"mailto:"+casino.support_documents} >{casino.support_documents}</a></>]
        }, {
            title: "Telefono",
            content: ["Francia (+33) (0)1 789 00368", "Spagna (+34) 91 290 1420", "Internazionali (+1) 718 732 0154"]
        }]
    },
}
export default support